import React from 'react'
import PropTypes from 'prop-types'
import { Form, Row, Col, Input, Button, message } from 'antd'
import JSEncrypt from 'jsencrypt'
import PublickeyJson from '@/assets/json/publicKey.json'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

import styles from '../index.module.scss'

const FormItem = Form.Item
const { paywordReg } = filter

function EditPaywordForm (props) {
  const { activeKey } = props
  const { getFieldDecorator, resetFields, validateFields } = props.form

  // 更新支付密码
  const updatePayPassword = async (params) => {
    const res = await Api.updatePayPassword(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      clearInterval()
      handleCancel()
    }
  }

  const handleSubmit = () => {
    validateFields((err, values) => {
      if (err) { return false }
      const { oldPassword, newPassword } = values
      const jsencrypt = new JSEncrypt()
      jsencrypt.setPublicKey(PublickeyJson[PATH_ENV])

      values.newPassword = jsencrypt.encrypt(newPassword)
      if (activeKey === 'EDIT') {
        values.oldPassword = jsencrypt.encrypt(oldPassword)
      }

      updatePayPassword(values)
    })
  }

  const handleCancel = () => {
    resetFields()
  }

  return (
    <>
      <Form>
        <Row gutter={240}>
          <Col span={8}>
            <FormItem label='原支付密码'>
              {
                getFieldDecorator('oldPassword', {
                  getValueFromEvent: event => event.target.value.replace(/\s+/g, ''),
                  validate: [
                    {
                      trigger: 'onBlur',
                      rules: [
                        {
                          validator (rule, value, callback) {
                            if (value && !paywordReg.test(value)) {
                              /* eslint-disable */
                              callback('支付密码格式不正确')
                              /* eslint-enable */
                            } else {
                              callback()
                            }
                          }
                        }
                      ]
                    },
                    {
                      trigger: ['onChange', 'onBlur'],
                      rules: [{ required: true, message: '请您填写6位数字支付密码' }]
                    }
                  ]
                })(
                  <Input.Password placeholder='密码长度为6~20位，英文字母或数字'/>
                )
              }
            </FormItem>
          </Col>

          <Col span={8}>
            <div style={{ lineHeight: '39.9999px' }}><label className={'label required'}>新支付密码</label><span className={`${styles.formlabelExtra}`}>支付密码为6位数字</span></div>
            <FormItem>
              {
                getFieldDecorator('newPassword', {
                  getValueFromEvent: event => event.target.value.replace(/\s+/g, ''),
                  validate: [
                    {
                      trigger: 'onBlur',
                      rules: [
                        {
                          validator (rule, value, callback) {
                            if (value && !paywordReg.test(value)) {
                              /* eslint-disable */
                              callback('支付密码格式不正确')
                              /* eslint-enable */
                            } else {
                              callback()
                            }
                          }
                        }
                      ]
                    },
                    {
                      trigger: ['onChange', 'onBlur'],
                      rules: [{ required: true, message: '请您填写6位数字支付密码' }]
                    }
                  ]
                })(
                  <Input.Password />
                )
              }
            </FormItem>
          </Col>
        </Row>
        <Row gutter={240} style={{ marginTop: '56px' }}>
          <Col span={8}></Col>
          <Col span={8}>
            <FormItem>
              <Button type='primary' onClick={() => handleSubmit()}>保存</Button>
              <Button style={{ marginLeft: '24px' }} onClick={() => handleCancel()}>取消</Button>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </>
  )
}

EditPaywordForm.propTypes = {
  form: PropTypes.object,
  activeKey: PropTypes.string
}

const EditPayword = Form.create()(EditPaywordForm)

export default EditPayword
