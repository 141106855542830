import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Radio } from 'antd'

import Api from '@/common/api/index'
import LogisticsTrail from '@/components/logisticsTrail/index'

export default class StoreTrace extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    courierNumberList: PropTypes.array
  }

  state = {
    showTrace: false,
    selectedCourier: null, // 选中的运单号
    logisticsTraces: [] // 物流轨迹数据
  }

  componentDidMount () {
    const { courierNumberList = [] } = this.props

    if (courierNumberList.length > 0) {
      this.handleSelectedCourierNumber(courierNumberList[0])
    }
  }

  // 获取物流轨迹
  getLogisticsTraceByMailNo = () => {
    const { detailInfo = {} } = this.props
    const { selectedCourier } = this.state

    const params = {
      ShipperCode: detailInfo.courierCode,
      LogisticCode: selectedCourier,
      CustomerName: detailInfo.consigneePhoneNumber ? detailInfo.consigneePhoneNumber.substr(-4) : ''
    }

    Api.getLogisticsTraceByMailNo(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        const _list = data.traces || []

        this.setState({
          logisticsTraces: _list.reverse(),
          showTrace: true
        })
      }
    })
  }

  // 点击选择包裹
  handleSelectedCourierNumber = (key) => {
    this.setState({
      selectedCourier: key
    }, () => {
      this.getLogisticsTraceByMailNo()
    })
  }

  render () {
    const { detailInfo, courierNumberList = [] } = this.props
    const { selectedCourier, showTrace = false, logisticsTraces } = this.state

    return (
      <div>
        <Row>
          <Radio.Group buttonStyle='solid' value={selectedCourier}
            onChange={(e) => this.handleSelectedCourierNumber(e.target.value)}
          >
            {
              courierNumberList.map((cour, n) => (
                <Radio.Button value={cour} key={`cour-${n}`}>运单号{cour}</Radio.Button>
              ))
            }
          </Radio.Group>
        </Row>

        <Row>
          {
            showTrace
              ? <LogisticsTrail
                logisticsTraces={logisticsTraces}
                courierCode={detailInfo.courierCode}
              />
              : null
          }
        </Row>
      </div>
    )
  }
}
