import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal, Descriptions, Progress, Empty, Divider, Badge, Button } from 'antd'
import $lodash from 'lodash'
import moment from 'moment'

import Api from '@/common/api/index'

const taskDescMaps = {
  MODEL_LIBRARY: [
    { type: 'PUT_ON_SHELVES', label: '上架操作', desc: '产品上架' },
    { type: 'PULL_OFF_SHELVES', label: '下架操作', desc: '产品下架' },
    { type: 'PRODUCT_ADD', label: '添加到产品库操作', desc: '从型号库添加至我的产品库' },
    { type: 'PRODUCT_DELETE', label: '删除操作', desc: '产品删除' }
  ],
  PRODUCT_LIBRARY: [
    { type: 'PUT_ON_SHELVES', label: '上架操作', desc: '产品上架' },
    { type: 'PULL_OFF_SHELVES', label: '下架操作', desc: '产品下架' },
    { type: 'PRODUCT_DELETE', label: '删除操作', desc: '产品删除' }
  ],
  SPOTPRICE_COMMON_GROUP: [
    { type: 'EU_COMMON_PRICE_GROUP', label: '通用价格组设置', desc: 'EU用户现货通用价格组设置' }
  ],
  SPOTPRICE_GUEST_GROUP: [
    { type: 'EU_GUEST_PRICE_GROUP', label: '客户价格组设置', desc: 'EU用户现货客户价格组设置' }
  ],
  FUTURESPRICE_COMMON_GROUP: [
    { type: 'EU_FUTURE_COMMON_PRICE_GROUP', label: '通用价格组设置', desc: 'EU用户期货通用价格组设置' }
  ],
  FUTURESPRICE_GUEST_GROUP: [
    { type: 'EU_FUTURE_GUEST_PRICE_GROUP', label: '客户价格组设置', desc: 'EU用户期货客户价格组设置' }
  ],
  MERCHANT_PREDICT_QUOTATION_ORDER: [
    { type: 'MERCHANT_PREDICT_QUOTATION_ORDER', label: '商户预报价订单导入', desc: '商户预报价订单导入' }
  ]
}

const syncStatusMaps = [
  { id: 'SYNC_SUCCESS', name: '同步成功', badge: 'success' },
  { id: 'SYNC_FAIL', name: '同步失败', badge: 'error' },
  { id: 'SYNC_ACCEPTED', name: '待同步', badge: 'processing' }
]

export default function TaskProgressModal (props) {
  const { onCancel, taskKey } = props
  const [tableData, setTableData] = useState([])

  // 获取任务进度
  const getTaskProgressDetail = async () => {
    const arr = taskDescMaps[taskKey]
    const asyncTaskTypeList = $lodash.map(arr, (o) => { return o.type })

    const res = await Api.getTaskProgressDetail(asyncTaskTypeList)
    const { code, data } = res
    if (+code === 10000) {
      setTableData(data || [])
    }
  }

  // 渲染任务描述
  const renderTaskDescriptionText = (type) => {
    const arr = taskDescMaps[taskKey]
    const obj = arr.find((item) => { return item.type === type })

    const str = obj !== undefined ? obj.desc : null

    return str
  }

  const renderSyncStatusBadgeNode = (record) => {
    const obj = syncStatusMaps.find((o) => { return o.id === record.syncStatus })

    return obj !== undefined ? <Badge status={obj.badge} text={<><label className='label'>执行结果</label><span>{ obj.name }</span></>} /> : null
  }

  useEffect(() => {
    getTaskProgressDetail()
  }, [])

  return (
    <Modal title='任务进度' width={800} bodyStyle={{ padding: '16px 24px', height: '400px', overflow: 'auto' }}
      visible
      maskClosable={false}
      onCancel={() => onCancel()}
      footer={[
        <Button key={'progressBtn-1'} onClick={() => onCancel()}>关闭</Button>,
        <Button type='primary' key={'progressBtn-2'} onClick={() => getTaskProgressDetail()}>刷新</Button>
      ]}
    >
      {
        tableData.length > 0
          ? <>
          {
            tableData.map((item, index) => (
              <div key={`progressItem-${index}`}>
                <Descriptions>
                  <Descriptions.Item label='任务编号'>{ item.id }</Descriptions.Item>
                  <Descriptions.Item label='操作人'>{ item.createName }</Descriptions.Item>
                  <Descriptions.Item label='操作时间'>{ item.createTime ? moment(item.createTime).format('YYYY-MM-DD HH:mm:ss') : null }</Descriptions.Item>
                  <Descriptions.Item label='任务描述' span={3}>{ renderTaskDescriptionText(item.type) }</Descriptions.Item>
                </Descriptions>

                <div style={{ marginBottom: '10px' }}>
                  {
                    ['SPOTPRICE_COMMON_GROUP', 'SPOTPRICE_GUEST_GROUP', 'FUTURESPRICE_COMMON_GROUP', 'FUTURESPRICE_GUEST_GROUP'].includes(taskKey)
                      ? <>
                      <Badge status='warning' text={`设置总数：${item.taskNum}`} style={{ marginRight: '20px' }} />
                      { renderSyncStatusBadgeNode(item) }
                    </>
                      : <>
                      <Badge status='warning' text={`添加总数：${item.taskNum}`} style={{ marginRight: '20px' }} />
                      <Badge status='success' text={`已成功数：${item.syncNum}`} style={{ marginRight: '20px' }} />
                      <Badge status='processing' text={`待处理数：${item.taskNum - item.syncNum}`} />
                    </>

                  }
                </div>

                <Progress percent={+item.taskNum > 0 ? $lodash.round(+item.syncNum / +item.taskNum * 100, 2) : 0.00} />
                {
                  index === tableData.length - 1 ? null : <Divider />
                }
              </div>
            ))
          }
          </>
          : <Empty />
      }
    </Modal>
  )
}

TaskProgressModal.propTypes = {
  taskKey: PropTypes.string,
  onCancel: PropTypes.func
}
