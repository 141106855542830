import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Empty, Button } from 'antd'

import InvoiceAccount from '@/components/invoiceAccount/index'
import InvoiceProduct from '@/components/invoiceProduct/index'

import Api from '@/common/api/index'

function InvoiceModalForm (props) {
  const [invoiceInfo, setInvoiceInfo] = useState({})
  const [invoiceProducts, setInvoiceProducts] = useState({ totalAmount: 0, tableData: [] })

  const { ids = [], onCancel } = props

  // 获取云采购-开票中详情数据
  const getPurchaseOrderInvoiceOpeningDetails = async () => {
    const res = await Api.getPurchaseOrderInvoiceOpeningDetails({ ids: ids })
    const { code, data = {} } = res
    if (+code === 10000) {
      setInvoiceProducts({ totalAmount: data.invoiceAmount || 0, tableData: data.orderGoodsListMap || [] })
      setInvoiceInfo(data.invoiceInfo || {})
    }
  }

  useEffect(() => {
    getPurchaseOrderInvoiceOpeningDetails()
  }, [])

  return (
    <>
      <Modal title='发票信息' width={1000} bodyStyle={{ padding: '15px' }}
        visible
        maskClosable={false}
        onCancel={() => onCancel()}
        footer={<Button key={'upinvoiceBtns-0'} onClick={() => onCancel()}>关闭</Button>}
      >
        <div>
          <Empty description={<>开票中，请耐心等待...<br />有问题请拨打19532535667</>} />
        </div>
        <div style={{ marginTop: '16px' }}>
          <InvoiceAccount
            title={invoiceInfo.invoiceTitle}
            creditNo={invoiceInfo.creditNo}
            tel={invoiceInfo.tel}
            bankName={invoiceInfo.bankName}
            bankCard={invoiceInfo.bankCard}
            province={invoiceInfo.province}
            city={invoiceInfo.city}
            county={invoiceInfo.county}
            address={invoiceInfo.address}
          />
        </div>

        <div style={{ marginTop: '16px' }}>
          <InvoiceProduct
            totalAmount={+invoiceProducts.totalAmount}
            list={invoiceProducts.tableData}
          />
        </div>
      </Modal>
    </>
  )
}

InvoiceModalForm.propTypes = {
  ids: PropTypes.array,
  onCancel: PropTypes.func
}

const InvoiceModal = Form.create()(InvoiceModalForm)

export default InvoiceModal
