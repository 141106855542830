/* 询价模块 */
import CreateEnquireOrder from '@/pages/enquireManage/createEnquireOrder/index'
import EnquireProcess from '@/pages/enquireManage/enquireProcess/index'
import EnquireOrderList from '@/pages/enquireManage/enquireOrderList/index'
import PrepareQuotations from '@/pages/enquireManage/prepareQuotations/index'
import PrepareQuotedDetails from '@/pages/enquireManage/prepareQuotedDetails/index'

import EnquireMatchSheet from '@/pages/matchs/enquireMatchSheet/index'
import EnquireMatchReport from '@/pages/matchs/enquireMatchReport/index'
import EnquireMatchDetails from '@/pages/matchs/enquireMatchDetails/index'

export default [
  {
    path: '/enquire/createEnquireOrder',
    name: '询价提交',
    component: CreateEnquireOrder
  },
  {
    path: '/enquire/enquireProcess',
    name: '询价过程',
    component: EnquireProcess
  },
  {
    path: '/enquire/enquireOrderList',
    name: '我的询价',
    component: EnquireOrderList
  },
  {
    path: '/enquire/enquireMatchSheet',
    name: 'EXCEL确认',
    component: EnquireMatchSheet
  },
  {
    path: '/enquire/enquireMatchReport',
    name: '匹配结果简报',
    component: EnquireMatchReport
  },
  {
    path: '/enquire/enquireMatchDetails',
    name: '匹配结果详情',
    component: EnquireMatchDetails
  },
  {
    path: '/enquire/prepareQuotations',
    name: '我的预报价',
    component: PrepareQuotations
  },
  {
    path: '/enquire/preareQuotedDetails',
    name: '报价详情',
    component: PrepareQuotedDetails
  }
]
