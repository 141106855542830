import React, { useState, useEffect } from 'react'
import { Card, Button, Statistic, Modal, message, Pagination } from 'antd'
import moment from 'moment'

import CashoutModal from './cashoutModal/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

import styles from './index.module.scss'

const { pageSizeOptions } = filter

export default function Balance (props) {
  const [pages, setPages] = useState({ pageNo: 1, pageSize: 20, total: 0 })
  const [haspayword, setHaspayword] = useState(false)
  const [walletInfo, setWalletInfo] = useState({})
  const [tableData, setTableData] = useState([])
  const [cashoutModal, setCashoutModal] = useState(false)
  const { pageNo, pageSize } = pages

  // 查询用户是否设置支付密码
  const getHasPayPassword = async () => {
    const res = await Api.getHasPayPassword({})
    const { code, data } = res
    if (+code === 10000) {
      setHaspayword(data || false)
    }
  }

  // 获取企业账户余额
  const getEnterpriseBalance = async () => {
    const res = await Api.getEnterpriseBalance({})
    const { code, data = {} } = res
    if (+code === 10000) {
      setWalletInfo(data)
    }
  }

  // 获取余额流水
  const getEnterpriseBalanceFlow = async () => {
    const res = await Api.getEnterpriseBalanceFlow({ pageNo: pageNo, pageSize: pageSize })
    const { code, data } = res
    if (+code === 10000) {
      setTableData(data.list || [])
      setPages({ ...pages, total: +data.total })
    }
  }

  // 提现申请
  const saveCashoutApply = async (params) => {
    const res = await Api.saveCashoutApply(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      setCashoutModal(false)
      getEnterpriseBalance()
    }
  }

  // 点击申请提现按钮
  const handleCashout = () => {
    if (!haspayword) {
      Modal.warning({
        title: '温馨提示',
        content: (<>
          <p style={{ marginBottom: '0px' }}>企业账户没有设置支付密码</p>
          <p style={{ marginBottom: '0px' }}>请联系管理员前往&nbsp;财务管理&nbsp;-&nbsp;设置支付密码&nbsp;去设置</p>
        </>),
        onOk: () => {}
      })

      return false
    }

    setCashoutModal(true)
  }

  const pageChange = (page, pageSize) => {
    setPages({ ...pages, pageNo: page })
  }

  const pageSizeChange = (current, size) => {
    setPages({ ...pages, pageNo: 1, pageSize: size })
  }

  const pagination = {
    showSizeChanger: true,
    total: pages.total,
    showTotal: (total) => { return `共 ${total} 条` },
    current: pageNo,
    pageSize: pageSize,
    pageSizeOptions: pageSizeOptions,
    onChange: pageChange,
    onShowSizeChange: pageSizeChange
  }

  useEffect(() => {
    getHasPayPassword()
    getEnterpriseBalance()
    getEnterpriseBalanceFlow()
  }, [pageNo, pageSize])

  return (
    <Card bodyStyle={{ padding: '30px 50px' }} bordered={false}>
      <div className={`${styles.statisticbox}`}>
        <div className={`${styles.statisticContent}`}>
          <div className={`${styles.picBox}`} />
          <Statistic
            title={<span style={{ fontSize: '16px', color: '#333333', fontWeight: 500 }}>账户余额</span>}
            prefix={<>&yen;</>}
            value={+walletInfo.totalBalance || 0}
            precision={2}
          />
        </div>

        {
          +walletInfo.totalBalance > 0
            ? <Button style={{ marginTop: '16px' }} type='primary' onClick={() => handleCashout()}>申请提现</Button>
            : null
        }
      </div>

      <Card title='余额流水' headStyle={{ paddingLeft: '0px', paddingRight: '0px', color: '#333333' }} bodyStyle={{ padding: '0px' }} bordered={false}>
        <ul>
          {
            tableData.map((record, index) => (
              <li key={record.id} className={`${styles.listItem}`}>
                <div className={`${styles.listItemMeta}`}>
                  <h4 className={`${styles.metaTitle}`}>{ record.remarks }</h4>
                  <div className={`${styles.description}`}>{ record.createTime ? <>{moment(record.createTime).format('YYYY-MM-DD HH:mm:ss')}</> : null }</div>
                </div>
                <div style={{ color: '#F5222D' }}>{record.amountType === 'ADD' ? '+' : '-'}&yen;{(+record.amount * 100 / 100).toFixed(2)}</div>
              </li>
            ))
          }
        </ul>

        <div style={{ marginBottom: '24px', textAlign: 'center' }}>
          <Pagination {...pagination} />
        </div>
      </Card>

      {
        cashoutModal
          ? <CashoutModal
              totalBalance={+walletInfo.totalBalance}
              onConfirm={(params) => saveCashoutApply(params)}
              onCancel={() => setCashoutModal(false)}
          />
          : null
      }
    </Card>
  )
}
