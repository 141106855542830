import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row } from 'antd'

import EmptyLogistics from '../EmptyTraces/index'
import { getCourierName } from '@/common/utils/mUtils'
import styles from './index.module.scss'

export default class LogisticsTrail extends Component {
  static propTypes = {
    logisticsTraces: PropTypes.array, // 物流轨迹数据
    courierCode: PropTypes.string // 快递公司编码
  }

  state = {}

  render () {
    const { courierCode, logisticsTraces = [] } = this.props

    return (
      <Row className={`${styles.trailFloor}`}>
        <div className={`${styles.queryOutline} clearfix`}>
          <div className={`${styles.upTitle}`}>
            <ul>
              <li className={`${styles.titleItem}`}>
                <span>{ getCourierName(courierCode) }</span>
              </li>
            </ul>

            <div className={`${styles.tipsTop}`}>
              <span style={{ marginRight: '5px' }}>IIASaaS</span>提供服务
            </div>
          </div>

          <div className={`${styles.storeContent}`}>
            {
              logisticsTraces.length > 0
                ? <div className={`${styles.scShow}`}>
                  <ul>
                    {
                      logisticsTraces.map((item, i) => (
                        <li className={`${styles.trailItem}`} key={`tracesItem-${i}`}>
                          <span className={`${styles.sub1}`}>{item.acceptTime}</span>
                          <span className={`${styles.sub2} ${i === 0 ? styles.subHead : ''} ${i === logisticsTraces.length - 1 ? styles.subFooter : ''}`} />
                          <span className={`${styles.sub3}`}>{item.acceptStation}</span>
                        </li>
                      ))
                    }
                  </ul>
                </div>
                : <EmptyLogistics />
            }
          </div>
        </div>
      </Row>
    )
  }
}
