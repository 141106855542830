import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Form, Row, Col, Radio, Select, InputNumber, Input, Icon, Checkbox, Alert, DatePicker } from 'antd'
import $lodash from 'lodash'

import SendHelp from '../sendHelp/index'
import InsuraexplanModal from '../insuraExplanModal/index'
import CourierJson from '@/assets/json/courier.json'
// import SaasCourierJson from '@/assets/json/saasCourier.json'
import LogisticsExplainJson from '@/assets/json/logisticsExplain.json'
import filter from '@/common/utils/filter'

import styles from './index.module.scss'

const { saasCouierCodeMaps, expressTypeMaps, expressReceiptFlags, expressReceiptReturnRequirements, expressPackWoodenFrames } = filter
const FormItem = Form.Item
const Option = Select.Option
const { TextArea } = Input
const { RangePicker } = DatePicker

const MIN_INSURE_AMOUNT = 300 // 保险最小免赔额

class SendServiceForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    info: PropTypes.object,
    insureConfig: PropTypes.object,
    defaultLogisticsPayType: PropTypes.string,
    insurePay: PropTypes.number,
    productAmount: PropTypes.number // 此次发货货物金额
  }

  state = {
    tips: '',
    explanModal: false
  }

  // 选择快递服务
  handleChangeServerType = (value) => {
    this.setState({
      tips: ''
    }, () => {
      this.props.form.setFieldsValue({
        transportType: null
      })
    })
  }

  // 选择快递方式
  handleChangeTransport = (value, courierCode) => {
    this.setState({
      tips: LogisticsExplainJson[courierCode][value]
    })
  }

  // 渲染快递方式选项
  renderTransportTypeOptions = (courierCode) => {
    const newArr = expressTypeMaps[courierCode] || []

    return newArr.length > 0
      ? <Select onChange={(value) => this.handleChangeTransport(value, courierCode)}>
        {
          newArr.map((item) => (
            <Option value={item.id} key={item.id}>{ item.name }</Option>
          ))
        }
      </Select>
      : null
  }

  // 渲染快递付费类型
  renderLogisticsPayTypeOptions = (free) => {
    return (
      <Radio.Group buttonStyle='solid'>
        <Radio.Button value='MONTHLY_STATEMENT'>包邮</Radio.Button>
        { !free ? <Radio.Button value='FREIGHT_COLLECT'>到付</Radio.Button> : null }
      </Radio.Group>
    )
  }

  // 渲染SAAS服务内容
  renderSAASServerNodes = ({ getFieldDecorator }) => {
    const { form, info = {}, insureConfig = {}, defaultLogisticsPayType = '', insurePay = 0, productAmount = 0 } = this.props
    const wayEnums = form.getFieldValue('logisticsWayEnum').split('-')

    const tailFormItemLayout = {
      wrapperCol: {
        sm: { span: 24, offset: 2 }
      }
    }

    return (
      <>
        <Row className={`${styles.formitemRow}`}>
          <FormItem label={<label className={`${styles.required}`}>快递公司</label>}>
            <span>{this.findCourierNameByCode(wayEnums[1])}</span>
          </FormItem>
        </Row>

        <Row className={`${styles.formitemRow}`}>
          <FormItem label='快递方式'>
            {
              getFieldDecorator('transportType', {
                rules: [
                  { required: true, message: '请选择快递方式' }
                ]
              })(this.renderTransportTypeOptions(form.getFieldValue('logisticsWayEnum')))
            }
          </FormItem>
        </Row>

        <Row className={`${styles.formitemRow}`}>
          <FormItem label='包裹数量'>
            {
              getFieldDecorator('totalNumber', {
                rules: [
                  { required: true, message: '请填写包裹数量' }
                ]
              })(
                <InputNumber min={1} />
              )
            }
          </FormItem>
        </Row>

        <Row className={`${styles.formitemRow}`}>
          <FormItem label='付费类型'>
            {
              getFieldDecorator('logisticsPayType', {
                initialValue: form.getFieldValue('logisticsWayEnum') === 'SAAS-JD' ? 'MONTHLY_STATEMENT' : (defaultLogisticsPayType || 'MONTHLY_STATEMENT'),
                rules: [
                  { required: true, message: '请选择付费类型' }
                ]
              })(
                this.renderLogisticsPayTypeOptions(form.getFieldValue('logisticsWayEnum') === 'SAAS-JD')
              )
            }
          </FormItem>
        </Row>

        {
          ['SAAS-DBL', 'SAAS-KYSY'].includes(form.getFieldValue('logisticsWayEnum'))
            ? <div className={`${styles.formitemRow}`}>
                <FormItem label='保价金额'>
                  {
                    getFieldDecorator('insuranceValue', {
                      rules: [
                        { required: false, message: '请填写保价金额' }
                      ]
                    })(
                      <InputNumber formatter={value => `￥ ${value}`} style={{ width: '120px' }} />
                    )
                  }
                </FormItem>
              </div>
            : null
        }

        {
          ['SAAS-DBL', 'SAAS-SF', 'SAAS-JD', 'SAAS-KYSY'].includes(form.getFieldValue('logisticsWayEnum'))
            ? <div className={`${styles.formitemRow}`}>
                <FormItem label='签单返还'>
                  {
                    getFieldDecorator('receiptType', {
                      initialValue: expressReceiptFlags[form.getFieldValue('logisticsWayEnum')][0].id,
                      rules: [
                        { required: true, message: '请选择签单返还' }
                      ]
                    })(
                      <Select>
                        {
                          expressReceiptFlags[form.getFieldValue('logisticsWayEnum')].map((receiOpt, i) => (
                            <Option value={receiOpt.id} key={`JDRECEIPT-${i}`}>{ receiOpt.name }</Option>
                          ))
                        }
                      </Select>
                    )
                  }
                </FormItem>
              </div>
            : null
        }

        {
          ['SAAS-DBL'].includes(form.getFieldValue('logisticsWayEnum')) && ['DEPPON_1', 'DEPPON_2'].includes(form.getFieldValue('receiptType'))
            ? <div className={`${styles.formitemRow}`}>
                <FormItem label='签收回单返单类型'>
                  {
                    getFieldDecorator('returnRequirement', {
                      rules: [
                        { required: true, message: '请选择签收回单返单类型' }
                      ]
                    })(
                        <Select>
                          {
                            expressReceiptReturnRequirements[form.getFieldValue('logisticsWayEnum')].map((receiOpt, i) => (
                              <Option value={receiOpt.id} key={`returnRequirement-${i}`}>{ receiOpt.name }</Option>
                            ))
                          }
                      </Select>
                    )
                  }
                </FormItem>
              </div>
            : null
        }

        { // 木架方式
          ['SAAS-KYSY'].includes(form.getFieldValue('logisticsWayEnum'))
            ? <div className={`${styles.formitemRow}`}>
              <FormItem label='木架方式'>
                {
                  getFieldDecorator('woodenFrame', {
                    initialValue: '0',
                    rules: [
                      { required: true, message: '请选择木架方式' }
                    ]
                  })(
                    <Select>
                      {
                        expressPackWoodenFrames[form.getFieldValue('logisticsWayEnum')].map((woodOpt, i) => (
                          <Option value={woodOpt.id} key={`woodenFrameOpt-${i}`}>{ woodOpt.name }</Option>
                        ))
                      }
                    </Select>
                  )
                }
              </FormItem>
            </div>
            : null
        }

        {
          ['SAAS-DBL'].includes(form.getFieldValue('logisticsWayEnum'))
            ? <div className={`${styles.formitemRow}`}>
                <FormItem label='上门接货期限'>
                  {
                    getFieldDecorator('pickUpTimes', {

                    })(
                      <RangePicker showTime />
                    )
                  }
                </FormItem>
              </div>
            : null
        }

        {
          ['SAAS-KYSY'].includes(form.getFieldValue('logisticsWayEnum'))
            ? <div className={`${styles.formitemRow}`}>
                <FormItem label='预计重量'>
                    {
                      getFieldDecorator('actualWeight', {

                      })(
                        <InputNumber style={{ width: '120px' }} />
                      )
                    }
                    <span style={{ marginLeft: '8px' }}>kg</span>
                    <span className={'redColor'} style={{ marginLeft: '8px' }}>此项会影响调配车辆，请认真估计</span>
                </FormItem>
              </div>
            : null
        }

        {
          ['SAAS-DBL', 'SAAS-SF', 'SAAS-KYSY'].includes(form.getFieldValue('logisticsWayEnum'))
            ? <div className={`${styles.formitemRow}`}>
                <FormItem label='备注'>
                  {
                    getFieldDecorator('waybillRemark', {

                    })(
                      <TextArea placeholder={`请输入内容，不超过${['SAAS-DBL', 'SAAS-SF'].includes(form.getFieldValue('logisticsWayEnum')) ? 100 : 200}字...`}
                        rows={4}
                        maxLength={['SAAS-DBL', 'SAAS-SF'].includes(form.getFieldValue('logisticsWayEnum')) ? 100 : 200}
                      />
                    )
                  }
                </FormItem>
              </div>
            : null
        }

        {
          insureConfig && insureConfig.status === 'ENABLE'
            ? <>
              <Row className={`${styles.formitemRow}`}>
                <FormItem {...tailFormItemLayout} style={{ marginBottom: '0px' }}>
                  {
                    getFieldDecorator('isPinganInsure', {
                      valuePropName: 'checked',
                      initialValue: insureConfig.type === 'YEAR' || (info && info.orderType === 'ORDER_CLOUD_STOCK' && productAmount > MIN_INSURE_AMOUNT)
                    })(<Checkbox>为订单添加平安保险</Checkbox>)
                  }

                  <a style={{ marginLeft: '30px' }} onClick={() => this.showInsuraExplanModal()}>平安保险说明&nbsp;<Icon type='question-circle' /></a>
                </FormItem>
              </Row>

              {
                form.getFieldValue('isPinganInsure') === true
                  ? <Row className={`${styles.formitemRow}`}>
                    <FormItem {...tailFormItemLayout}>
                      <Alert type='error' message={<span>本次保险金额:&nbsp;&yen;{(+insurePay * 100 / 100).toFixed(2)}</span>} />
                    </FormItem>
                  </Row>
                  : null
              }

            </>
            : null
        }

        <Row className={`${styles.formitemRow}`}>
          <FormItem label='温馨提示'>
            <div style={{ color: '#F5222D', fontSize: '16px' }}>
              为保证您的发货速度，确认发货后，请提前联系快递小哥来取件&nbsp;<Icon type='smile' style={{ fontSize: '20px' }} />
            </div>
          </FormItem>
        </Row>
      </>
    )
  }

  findCourierNameByCode = (code) => {
    const obj = CourierJson.find((item) => { return item.courierCode === code })
    const name = obj !== undefined ? obj.courierName : ''

    return name
  }

  // 展示保险说明弹窗
  showInsuraExplanModal = () => {
    this.setState({
      explanModal: true
    })
  }

  closeModal = () => {
    this.setState({
      explanModal: false
    })
  }

  render () {
    const { getFieldDecorator, getFieldValue } = this.props.form
    const { tips = '', explanModal = false } = this.state

    return (
      <div style={{ marginTop: '15px' }}>
        <Card title='快递信息' bodyStyle={{ padding: '15px' }}>
          <Row gutter={24}>
            <Col span={18} className={`${styles.bodyContainer}`}>
              <Row className={`${styles.serverWrap}`}>
                <Form>
                  <Row className={`${styles.formitemRow}`}>
                    <FormItem label='快递服务'>
                      {
                        getFieldDecorator('logisticsWayEnum', {
                          initialValue: 'SAAS-DBL',
                          rules: [
                            { required: true, message: '请选择快递服务' }
                          ]
                        })(
                          <Radio.Group onChange={this.handleChangeServerType}>
                            {
                              saasCouierCodeMaps.map((item, i) => (
                                <Radio value={item.courierCode} key={item.courierCode}>{ item.courierName }</Radio>
                              ))
                            }
                          </Radio.Group>
                        )
                      }
                    </FormItem>
                  </Row>

                  {
                    $lodash.includes(getFieldValue('logisticsWayEnum'), 'SAAS-')
                      ? this.renderSAASServerNodes({ getFieldDecorator })
                      : null
                  }
                </Form>
              </Row>

              {/* <Row className={`${styles.freightWrap}`}>
                {
                  IIAServers.includes(this.props.form.getFieldValue('logisticsWayEnum'))
                    ? <FreightCalculator info={info}
                      sendAddressId={this.props.form.getFieldValue('addressId')}
                      serverTypes={this.props.form.getFieldValue('logisticsWayEnum')}
                      addressList={this.props.sendAddressList}
                    />
                    : null
                }
              </Row> */}
            </Col>

            <Col span={6}>
              <SendHelp iscloudServer={$lodash.includes(getFieldValue('logisticsWayEnum'), 'SAAS-')}
                tips={tips} />
            </Col>
          </Row>
        </Card>

        {
          explanModal
            ? <InsuraexplanModal
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </div>
    )
  }
}

const SendService = Form.create()(SendServiceForm)

export default SendService
