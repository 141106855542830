import React, { Component } from 'react'
import { Card, Table, Badge, message, Button } from 'antd'
import $lodash from 'lodash'

import SearchForm from '@/components/tool-form/index'
import ApplyLogModal from './applyLogModal/index'
import ApplyModal from './applyModal/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import consts from '@/common/utils/consts'

import layoutStyles from '@/components/basicLayout/index.module.scss'

const { pageSizeOptions, auditStatusMaps } = filter
const { SERIALWIDTH } = consts

export default class SupplyBrand extends Component {
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'brandId',
        formType: 'Select',
        itemParams: {
          label: '品牌'
        },
        cptParams: {
          placeholder: '请选择...',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['brandId', 'showLabel']
      },
      {
        name: 'auditStatus',
        formType: 'Select',
        itemParams: {
          label: '审核状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...auditStatusMaps
        ]
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    applylogModal: false,
    applyModal: false,
    rowDetail: {},
    tableData: []
  }

  columns = [
    {
      title: '序号',
      key: 'supplyBrand-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'supplyBrand-1',
      render: (text, record, index) => (
        <div>{record.brandName}{record.englishBrandName ? `/${record.englishBrandName}` : null}</div>
      )
    },
    {
      title: '审核状态',
      key: 'supplyBrand-2',
      render: (text, record, index) => (
        <div>
          {this.renderAuditStatus(record.auditStatus)}
        </div>
      )
    },
    {
      title: '操作',
      key: 'supplyBrand-3',
      render: (text, record, index) => (
        <>
          <Button type='link' size='small' disabled={['AUDIT_ACCEPTED', 'AUDIT_SUCCESS'].includes(record.auditStatus)}
            onClick={() => this.showCloudApplyModal(record)}
          >
            云供应申请
          </Button>

          {
            !record.auditStatus || record.auditStatus === 'AUDIT_UNKNOWN'
              ? null
              : <Button type='link' size='small' onClick={() => this.showApplyLogModal(record)}>
                申请日志
              </Button>
          }

        </>
      )
    }
  ]

  componentDidMount () {
    this.getBrandList()
    this.getSupplyBrandList()
  }

  // 获取检索品牌
  getBrandList = async () => {
    const res = await Api.getBrandList({ brandListType: 'ALL_BRAND' })
    const { code, data = [] } = res
    if (+code === 10000) {
      const { searchData } = this.state

      if (data && data.length > 0) {
        data.forEach(item => {
          item.showLabel = item.englishBrandName && item.englishBrandName !== '' ? `${item.brandName}/${item.englishBrandName}` : item.brandName
        })

        searchData[0].options = $lodash.concat([{ brandId: 0, showLabel: '全部' }], data)
      }

      this.setState({
        searchData: searchData
      })
    } else {
      message.warning(res.message)
    }
  }

  // 获取云供应品牌列表
  getSupplyBrandList = async () => {
    const { pages, query = {} } = this.state
    const res = await Api.getSupplyBrandList({ ...query, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    } else {
      message.warning(res.message)
    }
  }

  // 云供应品牌申请
  supplyBrandAuthApply = (params) => {
    Api.supplyBrandAuthApply(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功！')
        this.closeModal()
        this.getSupplyBrandList()
      }
    })
  }

  // 获取品牌授权申请日志
  getSupplyBrandAuthFlow = (params) => {
    return Api.getSupplyBrandAuthFlow(params)
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search
    }, () => {
      this.getSupplyBrandList()
    })
  }

  // 点击发起云供应
  showCloudApplyModal = (record) => {
    this.setState({
      applyModal: true,
      rowDetail: record
    })
  }

  // 点击查看申请日志
  showApplyLogModal = (record = {}) => {
    this.setState({
      applylogModal: true,
      rowDetail: record || {}
    })
  }

  // 渲染申请状态
  renderAuditStatus = (status) => {
    const obj = auditStatusMaps.find(item => { return item.id === status })

    return obj !== undefined ? <Badge text={obj.name} status={obj.badge} /> : null
  }

  closeModal = () => {
    this.setState({
      applylogModal: false,
      applyModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getSupplyBrandList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getSupplyBrandList()
    })
  }

  render () {
    const { searchData, pages, tableData, applylogModal = false, applyModal = false, rowDetail = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div className={`${layoutStyles.themeSearch}`} style={{ marginBottom: '20px', paddingLeft: '50px', paddingRight: '50px' }}>
          <SearchForm {...this.props}
            hasBackground
            dataSource={searchData}
            handleSubmit={(obj) => this.handleSearch(obj)}
          />
        </div>

        <Card bodyStyle={{ padding: '30px 50px' }}>
          <Table rowKey='brandId'
            dataSource={tableData}
            columns={this.columns}
            pagination={pagination}
          />
        </Card>

        {
          applyModal
            ? <ApplyModal
              detailInfo={rowDetail}
              onConfirm={(params) => this.supplyBrandAuthApply(params)}
              onCancel={() => this.closeModal()}
            />
            : null
        }

        {
          applylogModal
            ? <ApplyLogModal
              detailInfo={rowDetail}
              getDetail={(params) => this.getSupplyBrandAuthFlow(params)}
              onCancel={this.closeModal}
            />
            : null
        }
      </>
    )
  }
}
