import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Statistic } from 'antd'

import styles from './index.module.scss'

export default function SupplyStatistic (props) {
  const { totalAmount = 0, paidAmount = 0, unPaidAmount = 0 } = props

  return (
    <div className={`${styles.satisticBox}`}>
      <Row gutter={24}>
        <Col span={8}>
          <Statistic title={<span style={{ color: '#5F4AF6', fontSize: '20px', fontWeight: '400' }}>订单总金额</span>} value={totalAmount}
            precision={2}
            prefix={<>&yen;</>}
            valueStyle={{ color: '#1C1C28', fontSize: '18px', fontWeight: 'bold' }}
          />
        </Col>

        <Col span={8}>
          <Statistic title={<span style={{ color: '#5F4AF6', fontSize: '20px', fontWeight: '400' }}>已付总金额</span>} value={paidAmount}
            precision={2}
            prefix={<>&yen;</>}
            valueStyle={{ color: '#1C1C28', fontSize: '18px', fontWeight: 'bold' }}
          />
        </Col>

        <Col span={8}>
          <Statistic title={<span style={{ color: '#5F4AF6', fontSize: '20px', fontWeight: '400' }}>未付总金额</span>} value={unPaidAmount}
            precision={2}
            prefix={<>&yen;</>}
            valueStyle={{ color: '#1C1C28', fontSize: '18px', fontWeight: 'bold' }}
          />
        </Col>
      </Row>
    </div>
  )
}

SupplyStatistic.propTypes = {
  totalAmount: PropTypes.number,
  paidAmount: PropTypes.number,
  unPaidAmount: PropTypes.number
}
