import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'

import CorrectModal from '@/pages/enquireManage/createEnquireOrder/correctModal'

import styles from './index.module.scss'

const errorDescribeMaps = {
  ERROR: {
    type: 'danger',
    btnText: '纠错',
    btnClass: styles.correctBtn,
    desc: '您输入的产品订货号/型号可能有误，可点击下方按钮修正~~'
  },
  CHANGE_CHANNEL: {
    type: 'primary',
    btnText: '更换渠道',
    btnClass: styles.primaryBtn,
    desc: '您输入的产品其他渠道有货，需要更换么？可点击下方按钮更换~~'
  }
}

function GoodsErrorRow (props) {
  const { itemNumberModel, searchResult = 'ERROR', onConfirm } = props
  const [overModal, setOverModal] = useState(false)

  // 确定产品
  const handleConfirm = (sku) => {
    onConfirm(sku)
    setOverModal(false)
  }

  return (
    <>
      <div className={styles.errorTitle}>{itemNumberModel}</div>
      <div className={styles.errorDesc}>{ errorDescribeMaps[searchResult].desc }</div>
      <div style={{ marginTop: '10px' }}>
        <Button type={errorDescribeMaps[searchResult].type} size='small' onClick={() => setOverModal(true)}>
          {errorDescribeMaps[searchResult].btnText}
        </Button>
      </div>

      {
        overModal
          ? <CorrectModal
              correctSource='ORDER_CORRECT'
              searchResultEnum={searchResult}
              keyword={itemNumberModel}
              onConfirm={(sku) => handleConfirm(sku)}
              onCancel={() => setOverModal(false)}
            />
          : null
      }
    </>
  )
}

GoodsErrorRow.propTypes = {
  itemNumberModel: PropTypes.string,
  searchResult: PropTypes.string,
  onConfirm: PropTypes.func
}

export default GoodsErrorRow
