import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Input } from 'antd'
import $lodash from 'lodash'

import { repLineBreak } from '@/common/utils/mUtils'

const FormItem = Form.Item
const { TextArea } = Input
const menuMaps = [
  { id: 'itemNumberList', name: '订货号' },
  { id: 'modelList', name: '型号' }
]

class BatchsearchForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    batchKey: PropTypes.string,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }

  state = {}

  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { batchKey = 'itemNumberList' } = this.props
        const _values = repLineBreak(values.batchValues, '<br>').split('<br>')
        this.props.onConfirm({ [batchKey]: $lodash.filter(_values, (item) => { return item !== '' }) })
      }
    })
  }

  renderTitleName = (key) => {
    const obj = menuMaps.find((item) => { return item.id === key })
    const name = obj !== undefined ? obj.name : ''

    return name
  }

  render () {
    const { batchKey = 'itemNumberList' } = this.props
    const { getFieldDecorator } = this.props.form

    return (
      <Modal title={`${this.renderTitleName(batchKey)}批量`} bodyStyle={{ paddingBottom: '0px', maxHeight: '500px', overflowY: 'auto' }}
        visible
        maskClosable={false}
        onCancel={this.props.onCancel}
        onOk={this.handleConfirm}
      >
        <Form>
          <FormItem>
            {
              getFieldDecorator('batchValues', {
                rules: [
                  { required: true, message: '该项不能为空' }
                ]
              })(
                <TextArea placeholder={`请输入${this.renderTitleName(batchKey)}...`}
                  rows={6}
                  autoComplete='off'
                />
              )
            }
          </FormItem>
        </Form>
      </Modal>
    )
  }
}

const BatchsearchModal = Form.create()(BatchsearchForm)

export default BatchsearchModal
