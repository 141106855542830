import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'
import $lodash from 'lodash'

import styles from './index.module.scss'

export default class InvoiceProduct extends Component {
  static propTypes = {
    list: PropTypes.array,
    totalAmount: PropTypes.number
  }

  state = {}
  columns = [
    {
      title: '货物或应税劳务、服务名称',
      key: 'invoiceProductCol-0',
      width: 190,
      ellipsis: true,
      dataIndex: 'name'
    },
    {
      title: '规格型号',
      key: 'invoiceProductCol-1',
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'invoiceProductCol-2',
      dataIndex: 'itemNumber'
    },
    {
      title: '单位',
      key: 'invoiceProductCol-3',
      width: 80,
      dataIndex: 'unit'
    },
    {
      title: '数量',
      key: 'invoiceProductCol-4',
      dataIndex: 'quantity'
    },
    {
      title: '含税单价',
      key: 'invoiceProductCol-5',
      dataIndex: 'price',
      render: (text, record, index) => (
        <>&yen;{+record.price}</>
      )
    },
    {
      title: '含税金额',
      key: 'invoiceProductCol-6',
      dataIndex: 'totalPrice',
      render: (text, record, index) => (
        <>&yen;{ ($lodash.round(record.totalPrice, 2) * 100 / 100).toFixed(2) }</>
      )
    },
    {
      title: '订单编号',
      key: 'invoiceProductCol-7',
      dataIndex: 'orderId'
    }
  ]

  componentDidMount () {}

  render () {
    const { list = [], totalAmount = 0 } = this.props

    return (
      <Table rowKey={(record, index) => `${record.skuId}-${index}`} bordered
        columns={this.columns}
        dataSource={list}
        rowClassName={(record, index) => (+record.quantity < 0) ? 'redColor' : null}
        pagination={false}
        footer={() => <div style={{ textAlign: 'right' }}><label className={`${styles.label}`}>价税合计（小写）</label>&yen;{ ($lodash.round(+totalAmount, 2) * 100 / 100).toFixed(2)}</div>}
      />
    )
  }
}
