import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Form, Button, Modal, message } from 'antd'
import $lodash from 'lodash'
import moment from 'moment'
import { getLodop } from '@/assets/plugin/LodopFuncs'

import SalesUserInfo from './salesUserInfo/index'
import SendProductTable from './sendProductTable/index'
import FreightCalculator from './freightCalculator/index'
import SendService from './sendService/index'
import SendProductPrintHtm from '../component/sendProductPrintHtm/index'

import Api from '@/common/api/index'
import { renderAddressLabel } from '@/common/utils/mUtils'

class GoodsSendForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    history: PropTypes.object
  }

  state = {
    detailInfo: {},
    info: {},
    sendAddressList: [], // 发货地址列表
    tableData: [],
    insureConfig: {}, // 保险服务配置信息
    defaultLogisticsPayType: '', // 默认快递付费类型
    remarkModal: false
  }

  componentDidMount () {
    const { state = {} } = this.props.history.location

    this.setState({
      detailInfo: state
    }, () => {
      this.getSendAddressList()
      this.getEuOrderReceivingAddress()
      this.getPinganInsureConfig()
      this.getFreightPayType()

      if (this.state.detailInfo.type && this.state.detailInfo.type === 'redispatched') {
        this.getRedispatchedSendOrderInfo()
      } else {
        this.getSendOrderInfo()
      }
    })
  }

  // 获取发货地址列表
  getSendAddressList = async () => {
    const res = await Api.getSendAddressList({})
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        sendAddressList: data || [],
        selectedAddressKey: data && data.length > 0 ? data[0].id : 0
      })
    }
  }

  // 获取EU订单收货信息
  getEuOrderReceivingAddress = async () => {
    const { detailInfo = {} } = this.state
    const res = await Api.getEuOrderReceivingAddress({ pickingId: detailInfo.pickingId })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        info: data || {}
      })
    }
  }

  // 获取出库信息详细
  getSendOrderInfo = async () => {
    const { detailInfo = {} } = this.state
    const res = await Api.getSendOrderInfo({ pickingId: detailInfo.pickingId, pageNo: 1, pageSize: 999 })
    const { code, data } = res

    if (+code === 10000) {
      if (data && data.length > 0) {
        data.forEach(item => {
          item.sendNumber = item.quantity - item.deliveryQuantity
        })
      }

      this.setState({
        tableData: data || []
      })
    }
  }

  // 获取重新发货出库产品信息详细
  getRedispatchedSendOrderInfo = () => {
    const { detailInfo = {} } = this.state
    const params = {
      pickingId: detailInfo.pickingId,
      batchNum: detailInfo.batchNum,
      pageNo: 1,
      pageSize: 999
    }

    axios.all([Api.getSendOrderInfo(params), Api.getStoreOutPickingDetail(params)]).then(axios.spread((res, qres) => {
      if (+res.code === 10000 && +qres.code === 10000 && res.data && qres.data && qres.data.list) {
        res.data.forEach((item) => {
          const obj = qres.data.list.find((o) => { return o.commoditySkuId === item.commoditySkuId })
          item.sendNumber = obj !== undefined ? obj.batchQuantity : item.quantity - item.deliveryQuantity
        })

        this.setState({
          tableData: res.data || []
        })
      }
    }))
  }

  // 获取平安保险服务配置信息
  getPinganInsureConfig = () => {
    Api.getPinganInsureConfig({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          insureConfig: data || {}
        })
      }
    })
  }

  // 获取快递付费类型
  getFreightPayType = () => {
    const { detailInfo = {} } = this.state
    Api.getFreightPayType({ pickingId: detailInfo.pickingId }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          defaultLogisticsPayType: data
        })
      }
    })
  }

  // 云产品&SaaS服务发货
  saveSaasServerSend = async (params) => {
    const res = await Api.saveSend(params)
    const { code } = res
    if (+code === 10000) {
      this.setState({
        sendSuccess: true
      }, () => {
        this.countDown()
      })
    }
  }

  // 重新发货
  saveRedispatchedSend = async (params, type) => {
    const res = await Api.saveRedispatchedSend(params)
    const { code } = res
    if (+code === 10000) {
      this.setState({
        sendSuccess: true
      }, () => {
        this.countDown()
      })
    }
  }

  // 点击确认发货
  handleSend = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { detailInfo = {}, sendAddressList = [], tableData = [] } = this.state
        const { logisticsWayEnum, pickUpTimes } = values
        const serverTypes = logisticsWayEnum.split('-')

        values.pickingId = detailInfo.pickingId
        values.confirmDeliverGoodsSkuQuantityList = this.setConfirmDeliverGoodsSkuQuantityList(tableData)
        values.logisticsWayEnum = serverTypes[0]
        values.goodsAmount = $lodash.sumBy(tableData, (record) => { return +record.sendNumber * 100 * +record.sellingPrice / 100 })
        values.sendStartTime = pickUpTimes && pickUpTimes.length === 2 ? moment(pickUpTimes[0]).format('YYYY-MM-DD HH:mm:ss') : undefined
        values.sendEndTime = pickUpTimes && pickUpTimes.length === 2 ? moment(pickUpTimes[1]).format('YYYY-MM-DD HH:mm:ss') : undefined

        if (serverTypes[0] === 'SAAS') {
          if (sendAddressList.length === 0) {
            Modal.warning({
              title: '暂无发货地址',
              content: <>请在仓库 - 发货地址 管理您的发货地址</>
            })

            return false
          }

          Modal.confirm({
            title: '请确认发货地址',
            content: (<>{this.findSendAddressLabel(values.addressId)}</>),
            onOk: () => {
              values.courierCode = serverTypes[1]

              if (detailInfo.type && detailInfo.type === 'redispatched') {
                values.batchNum = detailInfo.batchNum
                this.saveRedispatchedSend(values, 'SAAS')
              } else {
                this.saveSaasServerSend(values)
              }
            }
          })
        }
      }
    })
  }

  // 点击打印拣货单按钮
  handlePrintSalesBill = () => {
    const { form } = this.props
    const { tableData = [], info } = this.state

    if (tableData.length === 0) {
      return message.warning('暂无打印信息')
    }

    const remarks = form.getFieldValue('remarks')

    this.setState({
      info: {
        ...info,
        remarks: remarks
      }
    }, () => {
      this.lodopPrintSalesBill()
    })
  }

  // 打印拣货单
  lodopPrintSalesBill = () => {
    const htmlTemplateObj = document.getElementById('salesTable').innerHTML
    const LODOP = getLodop()
    if (!LODOP) {
      return message.error('请下载打印插件')
    }

    LODOP.PRINT_INIT('初始化打印') // 打印初始化
    LODOP.ADD_PRINT_HTM('8mm', '3mm', '195mm', '290mm', htmlTemplateObj)
    LODOP.PREVIEW()
  }

  // 计算保险金额
  calcPinganInsurePay = () => {
    const { insureConfig = {}, tableData = [] } = this.state

    const totalAmount = $lodash.sumBy(tableData, (record) => { return +record.sendNumber * (+record.sellingPrice * 100) })
    const pay = +insureConfig.rate > 0 ? totalAmount * (+insureConfig.rate) / 100 : 0

    return pay
  }

  // 计算发货总金额
  calcSendProductsPayAmount = () => {
    const { tableData = [] } = this.state
    const total = $lodash.sumBy(tableData, (record) => { return +record.sendNumber * (+record.sellingPrice * 100) / 100 })

    return total
  }

  // 监听发货数量输入
  handleChangeSendingNumber = (params) => {
    const { tableData = [] } = this.state
    tableData[params.index].sendNumber = params.value

    this.setState({
      tableData
    })
  }

  findSendAddressLabel = (key) => {
    const { sendAddressList = [] } = this.state
    if (sendAddressList.length === 0) { return false }

    const obj = sendAddressList.find(item => { return item.id === key })
    const str = obj !== undefined ? `${renderAddressLabel(obj.province, obj.city, obj.county)} ${obj.address}` : ''

    return str
  }

  // 设置保存发货产品数据格式
  setConfirmDeliverGoodsSkuQuantityList = (tableData) => {
    const newArr = $lodash.filter(tableData, (o) => { return +o.sendNumber > 0 })

    if (newArr.length === 0) { return message.warning('请设置发货数量') }

    const arr = $lodash.map(newArr, (item) => {
      return {
        commoditySkuId: item.commoditySkuId,
        quantity: item.sendNumber
      }
    })

    return arr
  }

  // 倒计时定时器
  countDown = () => {
    let secondsToGo = 5
    const modal = Modal.success({
      title: '操作成功',
      content: `页面将在${secondsToGo}s后自动返回...`,
      okText: '立即返回',
      onOk: () => {
        clearInterval(timer)
        clearTimeout(outTimer)
        modal.destroy()
        this.goBack()
      }

    })

    const timer = setInterval(() => {
      secondsToGo -= 1
      modal.update({
        content: `页面将在${secondsToGo}s后自动返回...`
      })
    }, 1000)

    const outTimer = setTimeout(() => {
      if (secondsToGo === 0) {
        this.goBack()
      }
      clearInterval(timer)
      modal.destroy()
    }, secondsToGo * 1000)
  }

  // 返回列表
  goBack = () => {
    this.props.history.goBack()
  }

  render () {
    const { form } = this.props
    const { detailInfo = {}, info = {}, sendAddressList = [], tableData = [], insureConfig = {}, defaultLogisticsPayType = '' } = this.state

    return (
      <>
        <SalesUserInfo form={form}
          info={info}
          sendAddressList={sendAddressList}
        />
        <SendProductTable
          form={form}
          remarks={info.remarks || ''}
          futures={detailInfo.orderType && detailInfo.orderType === 'ORDER_CLOUD_FUTURES'}
          isRedispatch={detailInfo.type && detailInfo.type === 'redispatched'}
          tableData={tableData}
          onChangeSendingNumber={(params) => this.handleChangeSendingNumber(params)}
        />

        <div style={{ marginTop: '15px' }}>
          <FreightCalculator
            info={info}
            sendAddressId={form.getFieldValue('addressId')}
            addressList={sendAddressList}
          />
        </div>

        <SendService {...this.props}
          info={info}
          insureConfig={insureConfig}
          defaultLogisticsPayType={defaultLogisticsPayType}
          insurePay={this.calcPinganInsurePay()}
          productAmount={this.calcSendProductsPayAmount()}
        />

        <div style={{ marginTop: '15px', textAlign: 'center' }}>
          <Button type='primary' onClick={() => this.handlePrintSalesBill()}>打印拣货单</Button>
          <Button type='primary' style={{ marginLeft: '30px' }} onClick={() => this.handleSend()}>确认发货</Button>
          <Button style={{ marginLeft: '30px' }} onClick={() => this.goBack()}>关闭</Button>
        </div>

        {/* 拣货单 */}
        <SendProductPrintHtm
          orderInfo={info}
          orderDetail={tableData}
        />
      </>
    )
  }
}

const GoodsSend = Form.create()(GoodsSendForm)

export default GoodsSend
