
import Home from '@/pages/home/index' // 首页

export default [

  {
    path: '/home',
    name: '每日交易信息',
    component: Home
  }
]
