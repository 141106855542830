import React, { useState } from 'react'
import { Card } from 'antd'

import UnopenTable from './unopenTable/index'
import OpendTable from './opendTable/index' // 开票历史

import styles from './index.module.scss'

const menuMaps = [
  { id: 'INVOICE_OPENING', name: '开票中' },
  { id: 'INVOICE_OPENED', name: '开票完成' }
]

export default function PurchaseInvoice () {
  const [activeKey, setActiveKey] = useState('INVOICE_OPENING')

  return (
    <Card bodyStyle={{ padding: '30px' }}>
      <div className={`${styles.topMenus}`} style={{ marginBottom: '30px' }}>
        {
          menuMaps.map((item) => (
            <div className={`${styles.menuItem} ${item.id === activeKey ? styles.active : ''}`} key={item.id} onClick={() => setActiveKey(item.id)}>{ item.name }</div>
          ))
        }
      </div>

      {
        activeKey === 'INVOICE_OPENING'
          ? <UnopenTable />
          : null
      }

      {
        activeKey === 'INVOICE_OPENED'
          ? <OpendTable />
          : null
      }
    </Card>
  )
}
