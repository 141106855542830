import CustomerList from '@/pages/customer/customerList/index' // 客户列表
import CustomerAddress from '@/pages/customer/customerAddress/index' // 收货地址详情
import CustomerUploadMatch from '@/pages/customer/customerUploadMatch/index' // 导入客户
import CustomerUploadReport from '@/pages/customer/customerUploadReport/index' // 成功客户导入信息展示
import EnduserDetail from '@/pages/customer/customerInfo/index' // 添加/修改客户

export default [
  {
    path: '/customer/customerList',
    name: '客户列表',
    component: CustomerList
  },
  {
    path: '/customer/customerAddress',
    name: '客户地址',
    component: CustomerAddress
  },
  {
    path: '/customer/customerUploadMatch',
    name: '导入详情',
    component: CustomerUploadMatch
  },
  {
    path: '/customer/customerUploadReport',
    name: '导入详情',
    component: CustomerUploadReport
  },
  {
    path: '/customer/enduserDetail',
    name: '客户信息',
    component: EnduserDetail
  }
]
