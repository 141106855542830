import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Descriptions, Modal, Button, Icon, Empty } from 'antd'
import $lodash from 'lodash'

import AddressModal from '../addressModal/index'
import { renderAddressLabel } from '@/common/utils/mUtils'
import consts from '@/common/utils/consts'
import { saveEuKeyMap } from './datas'
import styles from './index.module.scss'

const { confirm } = Modal
const { ADDRESS_MAX_NUM } = consts

const changeEnterParamsKey = (params) => {
  const obj = {}

  $lodash.forEach(params, (value, key) => {
    obj[saveEuKeyMap[key]] = value
  })

  return obj
}

function AddressList (props) {
  const { componentType, ident, endUserId = 0, getList, onSaveAddress, onEditAddress, onDeleteAddress } = props
  const [list, setList] = useState([])
  const [addressObj, setAddressObject] = useState({ detailInfo: {}, type: 1, addressModal: false })

  const getAddressList = (params) => {
    getList(params).then((res) => {
      const { code, data = [] } = res
      if (+code === 10000) {
        if (data && data.length > 0 && ident && ident === 'EU') {
          data.forEach((item) => {
            item.name = item.receiver
            item.mobile = item.phoneNumber
            item.status = +item.category === 2 ? 'ENABLE' : 'DISABLE'
          })
        }

        setList(data || [])
      }
    })
  }

  const saveAddress = (params) => {
    onSaveAddress(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        setAddressObject({ detailInfo: {}, type: 1, addressModal: false })
        getAddressList({ endUserId: endUserId })
      }
    })
  }

  const editAddress = (params) => {
    onEditAddress(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        setAddressObject({ detailInfo: {}, type: 1, addressModal: false })
        getAddressList({ endUserId: endUserId })
      }
    })
  }

  const deleteAddress = (params) => {
    onDeleteAddress(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        getAddressList({ endUserId: endUserId })
      }
    })
  }

  const handleSubmit = (values, type) => {
    let params = Object.assign({}, values)
    params.endUserId = endUserId

    if (ident && ident === 'EU') {
      params = changeEnterParamsKey(values)
      params.category = values.status === 'ENABLE' ? 2 : 0
    }

    if (type === 2) {
      editAddress(params)
    } else {
      saveAddress(params)
    }
  }

  const handleDelete = (record) => {
    confirm({
      title: '确定删除?',
      onOk: () => {
        deleteAddress({ id: record.id, deleteStatus: 'ENABLE', endUserId: endUserId })
      }
    })
  }

  useEffect(() => {
    getAddressList({ endUserId: endUserId })
  }, [])

  return (
    <>
      <div className={`${styles.addLand}`}>
        <Button type='primary' disabled={list.length === 10} onClick={() => setAddressObject({ detailInfo: {}, type: 1, addressModal: true })}>
          <Icon type="plus" />新增地址
        </Button>
        <span style={{ marginLeft: '24px' }}>已创建{list.length}个地址，最多可创建{ADDRESS_MAX_NUM}个</span>
      </div>

      {
        list.length > 0
          ? <ul className={`${styles.list}`}>
            {
              list.map((item, i) => (
                <li className={`${styles.listCard} ${item.status === 'ENABLE' ? styles.background : ''}`} key={item.id}>
                  <Descriptions title={<><span>{item.name}</span>{ item.status === 'ENABLE' ? <span className={`${styles.defaultTag}`}>默认地址</span> : null }</>}>
                    <Descriptions.Item label='姓名'>{ item.name }</Descriptions.Item>
                    <Descriptions.Item label='联系方式'>{ item.mobile }</Descriptions.Item>
                    <Descriptions.Item label='所在地区'>{ renderAddressLabel(item.province, item.city, item.county) }</Descriptions.Item>
                    <Descriptions.Item label='详细地址'>{ item.address }</Descriptions.Item>
                  </Descriptions>
                  <div className={`${styles.extraLand}`}>
                    <Button type='link' onClick={() => setAddressObject({ detailInfo: item, type: 2, addressModal: true })}>编辑</Button>
                    <Button type='link' onClick={() => handleDelete(item)}>删除</Button>
                  </div>
                </li>
              ))
            }
          </ul>
          : <Empty />
      }

      {
        addressObj.addressModal
          ? <AddressModal
              componentType={componentType}
              type={addressObj.type}
              endUserId={+endUserId}
              detailInfo={addressObj.detailInfo}
              onConfirm={(values, type) => handleSubmit(values, type)}
              onCancel={() => setAddressObject({ detailInfo: {}, type: 1, addressModal: false })}
          />
          : null
      }
    </>
  )
}

AddressList.propTypes = {
  componentType: PropTypes.string, // 组件应用场景: SEND-发货地址 其他为收货地址
  ident: PropTypes.string, // 特殊标识-EU:需要转换入参字段key
  endUserId: PropTypes.number,
  getList: PropTypes.func,
  onSaveAddress: PropTypes.func,
  onEditAddress: PropTypes.func,
  onDeleteAddress: PropTypes.func
}

export default AddressList
