import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { Result, Button } from 'antd'

export default function OrderSuccess (props) {
  const location = useLocation()
  const { history } = props
  const { orderId, backLinkUrl = '/purchase/quickOrder' } = location.state

  // 点击继续下单
  const handleJumpToLink = () => {
    history.push({ pathname: backLinkUrl })
  }

  // 点击查看订单
  const handleLinkToOrder = () => {
    history.push({ pathname: '/order/orderList' })
  }

  return (
    <>
      <Result status="success"
        title="下单成功"
        subTitle={<><label className='label'>订单编号</label><span>{ orderId }</span></>}
        extra={[
          <Button type="primary" key="buy" style={{ marginRight: '24px' }} onClick={() => handleJumpToLink()}>继续下单</Button>,
          <Button key="console" onClick={() => handleLinkToOrder()}>查看订单</Button>
        ]}
       />
    </>
  )
}

OrderSuccess.propTypes = {
  history: PropTypes.object
}
