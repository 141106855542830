import OrderList from '@/pages/order/orderList/index'
import SaledOrder from '@/pages/product/saledOrder/index'

export default [
  {
    path: '/order/orderList',
    name: '订单管理',
    component: OrderList
  },
  {
    path: '/order/saledOrder',
    name: '售后管理',
    component: SaledOrder
  }
]
