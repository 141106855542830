import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Row, Col, Card, Button, Table, message, Radio } from 'antd'
import $lodash from 'lodash'

import MatchStatistic from './matchStatistic'
import HandleModal from '@/components/handleModal/index'

import DownloadApi from '@/common/api/downloadApi'
import Api from '@/common/api/index'
import consts from '@/common/utils/consts'

const { downloadfailList } = DownloadApi
const { SERIALWIDTH } = consts

class CustomerUploadMatch extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  state = {
    alldata: [],
    successdata: [],
    faildata: [],
    tableData: [],
    handleModal: false
  }

  columns = [
    {
      title: '序号',
      key: 'customer-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '真实姓名',
      width: 150,
      key: 'customer-3',
      dataIndex: 'adminName'
    },
    {
      title: '手机号码',
      key: 'customer-4',
      width: 150,
      dataIndex: 'adminPhoneNumber'
    },
    {
      title: '公司名称',
      width: 240,
      key: 'customer-5',
      dataIndex: 'name'
    },
    {
      title: '法人姓名',
      width: 150,
      key: 'customer-6',
      dataIndex: 'accountName'
    },
    {
      title: '注册地址',
      width: 240,
      key: 'customer-7',
      dataIndex: 'address'
    },
    {
      title: '统一社会信用代码',
      width: 240,
      key: 'customer-8',
      dataIndex: 'taxId'
    },
    {
      title: '开户银行',
      width: 240,
      key: 'customer-9',
      dataIndex: 'bank'
    },
    {
      title: '开票电话',
      width: 240,
      key: 'customer-10',
      dataIndex: 'telephone'
    },
    {
      title: '银行账号',
      width: 240,
      key: 'customer-11',
      dataIndex: 'bankAccount'
    },
    {
      title: '是否成功',
      width: 150,
      key: 'customer-12',
      render: (text, record, index) => {
        return (
          <span style={{ color: record.isSuccess ? '#52C41A' : '#F5222D' }}>{ record.isSuccess === true ? '是' : '否'}</span>
        )
      }
    },
    {
      title: '原因',
      width: 300,
      key: 'customer-13',
      dataIndex: 'failReason',
      render: (text, record, index) => {
        return (
          <span style={{ color: '#F5222D' }}>{ record.failReason}</span>
        )
      }
    }
  ]

  componentDidMount () {
    const { state = {} } = this.props.history.location
    const { data = [] } = state

    if (!data || data.length === 0) {
      return message.warning('页面参数错误')
    }

    this.setState({
      alldata: data,
      successdata: $lodash.filter(data, (item) => { return item.isSuccess === true }),
      faildata: $lodash.filter(data, (item) => { return item.isSuccess === false }),
      tableData: data
    })
  }

  // 返回上一级
  goback = () => {
    this.props.history.push({ pathname: '/customer/customerList' })
  }

  // 点击导出失败详情
  handleDownloadFailDetails = () => {
    const { faildata = [] } = this.state
    if (faildata.length === 0) {
      return message.warning('暂无失败数据')
    }

    downloadfailList({ templates: faildata })
  }

  // 点击导入数据
  handleUploadInfoOk = () => {
    this.setState({
      handleModal: true
    }, () => {
      this.uploadinfoOk()
    })
  }

  // 导入
  uploadinfoOk = () => {
    const { alldata = [] } = this.state
    const _arr = []

    alldata.forEach((item, index) => {
      _arr.push(Object.assign({}, item, { password: '111111' }))
    })

    Api.downloadList({ euEnterprises: _arr }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        setTimeout(() => {
          this.props.history.push({ pathname: '/customer/customerUploadReport', state: { total: alldata.length, success: data } })
        }, 300)
      } else {
        message.error(res.data.message)
        this.setState({
          handleModal: false
        })
      }
    })
  }

  // 点击切换按钮
  handleChangeTabPane = (key) => {
    const { alldata = [], successdata = [], faildata = [] } = this.state

    let data = []
    if (key === 'ALL') { data = alldata }
    if (key === 'SUCCESS') { data = successdata }
    if (key === 'FAIL') { data = faildata }

    this.setState({
      tableData: data
    })
  }

  render () {
    const { tableData = [], alldata = [], successdata = [], faildata = [], handleModal = false } = this.state

    return (
      <>
        <MatchStatistic totalNum={alldata.length} successNum={successdata.length} failNum={faildata.length} />

        <Card bodyStyle={{ padding: '16px' }}>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={12}>
              <Radio.Group buttonStyle='solid' defaultValue="ALL" onChange={(e) => this.handleChangeTabPane(e.target.value)}>
                <Radio.Button value="ALL">全部数据</Radio.Button>
                <Radio.Button value="SUCCESS">匹配成功</Radio.Button>
                <Radio.Button value="FAIL">需要修改</Radio.Button>
              </Radio.Group>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Button onClick={() => this.goback()}>取消匹配</Button>
              <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => this.handleDownloadFailDetails()} >导出失败详情</Button>
              <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => this.handleUploadInfoOk()}>导入数据</Button>
            </Col>
          </Row>

          <Table rowKey={(record, index) => `uploadMatchCol-${index}`}
            columns={this.columns}
            dataSource={tableData}
            scroll={{ x: 1500 }}
            pagination={false}
          />
        </Card>

        {
          handleModal
            ? <HandleModal />
            : null
        }
      </>
    )
  }
}

export default connect()(CustomerUploadMatch)
