import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Button, message, Divider } from 'antd'

import JSEncrypt from 'jsencrypt'
import PublickeyJson from '@/assets/json/publicKey.json'

import Api from '@/common/api/index'
import { setStore } from '@/common/utils/mUtils'

import LoginLogoImage from '@/assets/images/login_logo.png'
import styles from './index.module.scss'

const FormItem = Form.Item

class LoginForm extends Component {
  static propTypes={
    form: PropTypes.object,
    history: PropTypes.object
  }

  componentDidMount () {
    window.addEventListener('keydown', this.handleEnterKey)
  }

  // 组件卸载时 关闭定时器
  componentWillUnmount () {
    window.removeEventListener('keydown', this.handleEnterKey)
  }

  handleEnterKey = (e) => {
    if (e.keyCode === 13) {
      this.handleLogin()
    }
  }

  handleLogin = (e) => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const jsencrypt = new JSEncrypt()
        const { password } = values
        jsencrypt.setPublicKey(PublickeyJson[PATH_ENV])
        values.password = jsencrypt.encrypt(password)

        this.login(values)
      }
    })
  }

  // 登录
  login = async (params) => {
    const res = await Api.login(params)
    const { code, data } = res
    if (+code === 10000) {
      setStore('token', data)
      this.props.history.push({ pathname: '/home' })
    } else {
      message.error(res.message)
    }
  }

  render () {
    const { getFieldDecorator } = this.props.form

    return (
      <div className={styles.main}>
        <section className={styles.section}>
          <div className={`${styles.loginHeader}`}>
            <div className={`${styles.logoBox}`}>
              <img src={LoginLogoImage} width='123' height='32' />
            </div>
            <Divider type='vertical' className={`${styles.vertical}`} />
            <div className={`${styles.headerDescription}`}>
              <div className={`${styles.description}`}>欢迎回来</div>
              <p className={`${styles.descriptionEn}`}>Welcome back!</p>
            </div>
          </div>

          <div className={styles.loginContainer}>
            <Form>
              <div className={`${styles.formRow}`}>
                <FormItem label='用户名'>
                  {
                    getFieldDecorator('username', {
                      rules: [
                        { required: true, message: '请输入用户名!' }
                      ]
                    })(
                      <Input placeholder='请输入用户名' />
                    )
                  }
                </FormItem>
              </div>

              <div className={`${styles.formRow}`}>
                <FormItem label='密码'>
                  {
                    getFieldDecorator('password', {
                      rules: [
                        { required: true, message: '请输入密码!' }
                      ]
                    })(
                      <Input.Password placeholder='请输入密码' />
                    )
                  }
                </FormItem>
              </div>

              <div className={`${styles.btnsWrap}`}>
                <Button type='primary' onClick={(e) => { this.handleLogin(e) }} className={styles.btn}>登录</Button>
              </div>
            </Form>
          </div>
        </section>
     </div>
    )
  }
}
const Login = Form.create()(LoginForm)
export default (Login)
