import React, { Component } from 'react'
import { Modal } from 'antd'

import uploadImg from '@/assets/images/uploading_3.gif'

import styles from './index.module.scss'

export default class HandleModal extends Component {
  static propTypes = {

  }

  state = {}

  componentDidMount () { }

  render () {
    return (
      <>
        <Modal bodyStyle={{ padding: '0px' }} width={600} closable={false}
          visible
          maskClosable={false}
          footer={null}
        >
          <div className={`${styles.container}`}>
            <div className={`${styles.bounceLand}`}>
              <div className={`${styles.bounce}`}>
                {
                  ['数', '据', '导', '入', '中'].map((item, i) => (
                    <span className={`${styles.letter}`} key={`letter-${i}`}>{item}</span>
                  ))
                }
              </div>
            </div>

            <img className={`${styles.pic}`} src={uploadImg} />
            <div className={`${styles.copyRight}`}>
              IIASAAS助力工业的未来
            </div>
          </div>
        </Modal>
      </>
    )
  }
}
