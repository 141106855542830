import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Form, Row, Col, Input, Button, message, Upload, Icon, Cascader, AutoComplete } from 'antd'
import JSEncrypt from 'jsencrypt'
import PublickeyJson from '@/assets/json/publicKey.json'
import $lodash from 'lodash'

import UploadThumbnail from '@/components/uploadThumbnail/index'

import Api from '@/common/api/index'
import UploadApi from '@/common/api/uploadApi'
import { getStore, addressToTree } from '@/common/utils/mUtils'
import styles from './index.module.scss'

const { uploadOrdinaryImageUrl } = UploadApi
const FormItem = Form.Item
const AutoCompleteOption = AutoComplete.Option
const cityOptions = addressToTree()

class PersonalUser extends Component {
  constructor (props) {
    super(props)
    this.getFuzzySearchCompanyList = $lodash.debounce(this.getFuzzySearchCompanyList, 300)
  }

  static propTypes = {
    form: PropTypes.object,
    history: PropTypes.object,
    menuKey: PropTypes.string // PERSONAL是个人,ENTERPRISE 是企业
  }

  state={
    type: 1, // 1是新增客户，2是修改客户,
    endUser: {}, // 个人信息
    euEnterprise: {}, // 企业信息
    endUserType: 1, // 1-个人用户 2-企业用户,
    uncompanyList: [] // 可选的公司列表
  }

  componentDidMount () {
    const { location } = this.props.history
    const { state } = location

    if (state) {
      this.setState({
        endUser: state.endUser || {},
        euEnterprise: state.euEnterprise || {},
        type: state.type,
        endUserType: +state.enterpriseId > 0 ? 2 : 1
      })
    }
  }

  // 模糊搜索企业列表
  getFuzzySearchCompanyList = (key) => {
    Api.getFuzzySearchCompanyList({ keyword: key, pageNo: 1, pageSize: 10 }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          uncompanyList: data || []
        })
      }
    })
  }

  // 选择企业-查询企业详情
  getCompanyDetailInfo = async (params) => {
    const res = await Api.getCompanyDetailInfo(params)
    const { code, data } = res
    if (+code === 10000) {
      if (data && data.econKindCode) {
        setTimeout(() => {
          this.props.form.setFieldsValue({
            taxId: data.creditNo || '',
            accountName: data.operName || '',
            address: data.address || '',
            citys: [`${data.province}`, `${data.city}`, `${data.county}`]
          })
        }, 10)
      }
    }
  }

  // 新增客户
  saveAddEnduser = async (params) => {
    const res = await Api.saveAddEnduser(params)
    const { code } = res
    if (+code === 10000) {
      message.success('保存成功')
    } else {
      message.warning(res.message)
    }
  }

  // 保存客户编辑
  saveEditEnduser = (params) => {
    Api.saveEditEnduser(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('保存成功')
      }
    })
  }

  // 点击保存
  handleSumit = () => {
    const { form, menuKey } = this.props

    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { type = 1, endUser = {}, euEnterprise = {} } = this.state

        const password = 111111
        const jsencrypt = new JSEncrypt()
        jsencrypt.setPublicKey(PublickeyJson[PATH_ENV])
        values.password = jsencrypt.encrypt(password)

        if (type === 2) {
          if (menuKey === 'ENTERPRISE') {
            values.id = +euEnterprise.id > 0 ? euEnterprise.id : ''
          }
          values.euId = endUser.id || ''
        } else {
          values.userType = menuKey
        }

        if (values.citys && values.citys.length > 0) {
          const { citys } = values
          values.province = citys[0]
          values.city = citys[1]
          values.county = citys[2]
        }

        delete values.citys
        if (type === 1) {
          this.saveAddEnduser(values)
        } else {
          this.saveEditEnduser(values)
        }
      }
    })
  }

  // 上传营业执照
  beforeUploadLicense = (file, fileList) => {
    const isLt1M = file.size / 1024 / 1024 < 1
    if (!isLt1M) {
      message.warning('图片大小不可超过 1MB！')
    }
    return isLt1M
  }

  // 上传营业执照
  onChangeBusinessLicenseImg = (info) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response

      if (+code === 10000) {
        setTimeout(() => {
          this.props.form.setFieldsValue({
            businessLicenseImg: data.imageUrl || ''
          })
        }, 20)
      } else {
        message.error('上传图片失败')
      }
    }
  }

  // 删除营业执照
  removeUploadLicense = () => {
    setTimeout(() => {
      this.props.form.setFieldsValue({
        businessLicenseImg: ''
      })
    }, 20)
  }

  // 点击选择公司
  handleSelectedCompany = (value) => {
    setTimeout(() => {
      this.props.form.setFieldsValue({
        name: value
      }, () => {
        this.getCompanyDetailInfo({ keyword: value })
      })
    }, 20)
  }

  // 设置省市区级联回显
  renderCascaderDefaultValue = () => {
    const { euEnterprise = {} } = this.state
    const arr = []

    if (+euEnterprise.province > 0 && +euEnterprise.city > 0) {
      arr.push(`${euEnterprise.province}`, `${euEnterprise.city}`, `${euEnterprise.county}`)
    }

    return arr
  }

  render () {
    const { menuKey } = this.props
    const { getFieldDecorator, getFieldValue } = this.props.form
    const { endUser = {}, euEnterprise = {}, uncompanyList = [] } = this.state

    return (
      <>
        <h2 className={styles.h2txt}>基本信息</h2>
        <Form>
          <Row gutter={180}>
            <Col span={10}>
              <FormItem label={<><span className={styles.labeltxt}>真实姓名</span><span className={styles.dectxt}>真实姓名只支持中文</span></>} colon={false} >
                {
                  getFieldDecorator('realName', {
                    initialValue: endUser && endUser.realname ? endUser.realname : '',
                    rules: [
                      { required: true, message: '姓名必填' },
                      {
                        validator (rule, value, callback) {
                          if (value && !/^[\u4E00-\u9FA5]{2,4}$/.test(value)) {
                            /* eslint-disable */
                                callback('真实姓名必须为中文')
                                /* eslint-enable */
                          } else {
                            callback()
                          }
                        }
                      }
                    ]
                  })(<Input autoComplete='off' />)
                }
              </FormItem>
            </Col>

            <Col span={10}>
              <FormItem label={<><span className={styles.labeltxt}>手机号码</span><span className={styles.dectxt}>请输入一个可用于收取验证码的电话号码</span></>} colon={false} >
                {
                  getFieldDecorator('adminPhoneNumber', {
                    initialValue: endUser && endUser.phoneNumber ? endUser.phoneNumber : '',
                    rules: [
                      {
                        validator (rule, value, callback) {
                          if (value && !/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
                            .test(value)) {
                            /* eslint-disable */
                               callback('手机号码格式不正确')
                            /* eslint-enable */
                          } else {
                            callback()
                          }
                        }
                      }
                    ]
                  })(<Input autoComplete='off' />)
                }
              </FormItem>
            </Col>
          </Row>

          {
            menuKey === 'ENTERPRISE'
              ? <>
                  <Row gutter={180}>
                    <Col span={10} >
                      <Row className={`${styles.searchLand}`}>
                        <FormItem label='公司名称' colon={false} >
                          {
                            getFieldDecorator('name', {
                              initialValue: euEnterprise.name || '',
                              rules: [
                                { required: true, message: '公司名称必填' }
                              ]
                            })(
                              <AutoComplete placeholder='公司名称' autoComplete='off'
                                onSearch={(value) => this.getFuzzySearchCompanyList(value)}
                                onSelect={(value) => this.handleSelectedCompany(value)}
                              >
                                {
                                  uncompanyList.length > 0 && uncompanyList.map((comp) => (
                                    <AutoCompleteOption key={comp.name}>{comp.name}</AutoCompleteOption>
                                  ))
                                }
                              </AutoComplete>
                            )
                          }
                        </FormItem>
                      </Row>
                    </Col>

                    <Col span={10}>
                      <FormItem label='法人姓名' colon={false} >
                        {
                          getFieldDecorator('accountName', {
                            initialValue: euEnterprise && euEnterprise.accountName ? euEnterprise.accountName : ''
                          })(<Input autoComplete='off' />)
                        }
                      </FormItem>
                    </Col>
                  </Row>

                  <Row gutter={180}>
                    <Col span={10}>
                      <FormItem label='地区' colon={false} >
                        {
                          getFieldDecorator('citys', {
                            initialValue: this.renderCascaderDefaultValue()
                          })(
                            <Cascader placeholder='请选择省市区'
                              fieldNames={{ value: 'code', label: 'name', children: 'childList' }}
                              options={cityOptions}
                            />
                          )
                        }
                      </FormItem>
                    </Col>

                    <Col span={10}>
                      <FormItem label='统一社会信用代码' colon={false} >
                        {
                          getFieldDecorator('taxId', {
                            initialValue: euEnterprise && euEnterprise.taxId ? euEnterprise.taxId : ''
                          })(<Input autoComplete='off' />)
                        }
                      </FormItem>
                    </Col>
                  </Row>

                  <Row gutter={180}>
                    <Col span={10}>
                      <FormItem label='注册地址' colon={false} >
                        {
                          getFieldDecorator('address', {
                            initialValue: euEnterprise && euEnterprise.address ? euEnterprise.address : ''

                          })(<Input autoComplete='off' />)
                        }
                      </FormItem>
                    </Col>
                    <Col span={10}>
                      <FormItem label='开户银行' colon={false} >
                        {
                          getFieldDecorator('bank', {
                            initialValue: euEnterprise && euEnterprise.bank ? euEnterprise.bank : ''
                          })(<Input autoComplete='off' />)
                        }
                      </FormItem>
                    </Col>
                  </Row>

                  <Row gutter={180}>
                    <Col span={10}>
                      <FormItem label='开票电话' colon={false} >
                        {
                          getFieldDecorator('telephone', {
                            initialValue: euEnterprise && euEnterprise.telephone ? euEnterprise.telephone : ''
                          })(<Input autoComplete='off' />)
                        }
                      </FormItem>
                    </Col>

                    <Col span={10}>
                      <FormItem label='银行账号' colon={false} >
                        {
                          getFieldDecorator('bankAccount', {
                            initialValue: euEnterprise && euEnterprise.bankAccount ? euEnterprise.bankAccount : ''
                          })(<Input autoComplete='off' />)
                        }
                      </FormItem>
                    </Col>
                  </Row>

                  <Row gutter={180}>
                    <Col span={10}>
                      <FormItem label='营业执照电子版' style={{ display: 'none' }}>
                        {
                          getFieldDecorator('businessLicenseImg', {
                            initialValue: euEnterprise.businessLicenseImg || ''
                          })(
                            <Input disabled autoComplete='off'/>
                          )
                        }
                      </FormItem>
                      <FormItem label='营业执照电子版' style={{ display: 'inline-block', width: 'calc(100% - 50px)' }}>
                        {
                          getFieldValue('businessLicenseImg') !== undefined && getFieldValue('businessLicenseImg') !== ''
                            ? <UploadThumbnail
                                imageUrl={getFieldValue('businessLicenseImg')}
                                onDelete={() => this.removeUploadLicense()}
                              />
                            : <Upload name='file' listType='picture-card'
                                accept='image/png, image/jpg, image/jpeg'
                                showUploadList={false}
                                action={uploadOrdinaryImageUrl}
                                headers={{ Token: getStore('token') }}
                                beforeUpload={this.beforeUploadLicense}
                                onChange={(info) => this.onChangeBusinessLicenseImg(info)}
                                onRemove={this.removeUploadLicense}
                              >
                                <div>
                                  <Icon type='plus' />
                                  <div>点击上传</div>
                                </div>
                              </Upload>
                        }
                      </FormItem>
                    </Col>
                  </Row>
                </>
              : null
            }
          </Form>

          <div className={styles.handleOk}>
            <Button type='primary' onClick={() => { this.handleSumit() }}>保存</Button>
          </div>
      </>
    )
  }
}

const PersonalForm = Form.create()(PersonalUser)
export default withRouter(PersonalForm)
