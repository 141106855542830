import React, { useEffect, useState } from 'react'
import { Card, Carousel, Row, Col } from 'antd'
import moment from 'moment'

import Api from '@/common/api/index'
import { renderSkuGoodsTypeLabel } from '@/common/utils/mUtils'
import consts from '@/common/utils/consts'

import styles from './index.module.scss'

const { MAX_PAGESIZE } = consts

export default function TradedTable () {
  const [tableData, setTableData] = useState([])

  const getEnquireTradedGoodsList = async () => {
    const res = await Api.getEnquireTradedGoodsList({ pageNo: 1, pageSize: MAX_PAGESIZE })
    const { code, data } = res
    if (+code === 10000) {
      setTableData(data.list || [])
    }
  }

  useEffect(() => {
    getEnquireTradedGoodsList()
  }, [])

  return (
    <>
      <Card title='今日成交' bodyStyle={{ padding: '15px' }}>
        <Row className={`${styles.header}`}>
          <Col span={3}><div className={`${styles.itemContent}`}>品牌</div></Col>
          <Col span={3}><div className={`${styles.itemContent}`}>系列</div></Col>
          <Col span={4}><div className={`${styles.itemContent}`}>型号</div></Col>
          <Col span={4}><div className={`${styles.itemContent}`}>订货号</div></Col>
          <Col span={4}><div className={`${styles.itemContent}`}>产品名称</div></Col>
          <Col span={3}><div className={`${styles.itemContent}`}>产品类型</div></Col>
          <Col span={3}><div className={`${styles.itemContent}`}>成交时间</div></Col>
        </Row>

        <div className={`${styles.carouselWrap}`}>
          {
            tableData.length > 0
              ? <Carousel dotPosition='right' autoplay dots={false}>
                {
                  tableData.map((record, index) => (
                    <Row key={`${record.commoditySkuId}-${record.skuGoodsType}-${index}`} className={`${styles.listItem}`}>
                      <Col span={3}>
                        <div className={`${styles.itemContent}`}>
                          { record.brandName }{ record.englishBrandName ? <>/{ record.englishBrandName }</> : null }
                        </div>
                      </Col>
                      <Col span={3}>
                        <div className={`${styles.itemContent}`}>{ record.series }</div>
                      </Col>
                      <Col span={4}>
                        <div className={`${styles.itemContent}`}>{ record.model }</div>
                      </Col>
                      <Col span={4}>
                        <div className={`${styles.itemContent}`}>{ record.itemNumber }</div>
                      </Col>
                      <Col span={4}>
                        <div className={`${styles.itemContent}`}>{ record.name }</div>
                      </Col>
                      <Col span={3}>
                        <div className={`${styles.itemContent}`}>{ renderSkuGoodsTypeLabel(record.skuGoodsType) }</div>
                      </Col>
                      <Col span={3}>
                        <div className={`${styles.itemContent}`}>{ record.orderTime ? moment(record.orderTime).format('YYYY-MM-DD') : null }</div>
                      </Col>
                    </Row>
                  ))
                }
              </Carousel>
              : null
          }

        </div>
      </Card>
    </>
  )
}
