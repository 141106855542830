import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Table, Row, Button, message, Upload, Icon } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/tool-form/index'
import AddressModal from '@/components/addressModal/index'

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'
import UploadApi from '@/common/api/uploadApi'
import filter from '@/common/utils/filter'
import { getStore, removeStore } from '@/common/utils/mUtils'
import consts from '@/common/utils/consts'
import { saveEuKeyMap } from '@/components/addressList/datas'

import layoutStyles from '@/components/basicLayout/index.module.scss'

const { beforeuploadEndUser } = UploadApi
const { downloadUploadEndUserTemplate, downloadEndUserList } = DownloadApi
const { pageSizeOptions } = filter
const { SERIALWIDTH } = consts
const customerType = [
  { type: '', label: '全部' },
  { type: '2', label: '企业用户' },
  { type: '1', label: '个人用户' }
]
const changeEnterParamsKey = (params) => {
  const obj = {}

  $lodash.forEach(params, (value, key) => {
    obj[saveEuKeyMap[key]] = value
  })

  return obj
}

export default class CustomerList extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  state = {
    searchData: [
      {
        name: 'company',
        formType: 'Input',
        itemParams: {
          label: '公司名称',
          extra: ''
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'realName',
        formType: 'Input',
        itemParams: {
          label: '真实名称',
          extra: ''
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'times',
        formType: 'RangePicker',
        itemParams: {
          label: '注册时间'
        },
        cptParams: {

        }
      },
      {
        name: 'userType',
        formType: 'Select',
        itemParams: {
          label: '客户类型'
        },
        cptParams: {

        },
        options: customerType,
        optionValue: ['type', 'label'],
        rules: {
          initialValue: ''
        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    type: 1, // 1-新增 2-编辑
    query: {},
    tableData: [],
    rowDetail: {}, // 当前选择的行数据
    salerType: 0,
    getaddadress: false
  }

  columns = [
    {
      title: '序号',
      key: 'customerCol-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '公司名称',
      width: 240,
      key: 'customerCol-1',
      dataIndex: 'company',
      render: (text, record, index) => (
        <>{ +record.enterpriseId > 0 ? record.company : '----' }</>
      )
    },
    {
      title: '真实姓名',
      key: 'customerCol-2',
      dataIndex: 'realname',
      render: (text, record, index) => (
        <>{ record.endUser && record.endUser.realname ? record.endUser.realname : null }</>
      )
    },
    {
      title: '手机号码',
      key: 'customerCol-3',
      dataIndex: 'phoneNumber',
      render: (text, record, index) => (
        <>{ record.endUser && record.endUser.phoneNumber ? <span>{record.endUser.phoneNumber}</span> : null }</>
      )
    },
    {
      title: '客户类型',
      key: 'customerCol-4',
      dataIndex: 'enterpriseId',
      render: (text, record, index) => (
        <>{ +record.enterpriseId > 0 ? '企业用户' : '个人用户' }</>
      )
    },
    {
      title: '注册时间',
      key: 'customerCol-5',
      sorter: (a, b) => a.createTime - b.createTime,
      render: (text, record, index) => (
        <>{ record.createTime ? moment(record.createTime).format('YYYY-MM-DD hh:mm:ss') : '----' }</>
      )
    },
    {
      title: '操作',
      key: 'customerCol-6',
      width: 250,
      fixed: 'right',
      render: (text, record, index) => (
        <>
          <Button type='link' size='small' onClick={() => this.handleJumpToEnduserDetail(record, 2)}>编辑</Button>

          <Button type='link' size='small' onClick={() => this.getEndUserReceivedAddressList(record, 2)}>查看地址</Button>

          <Button type='link' size='small' onClick={() => this.getAddEnduserDetail(record, 1)}>添加地址</Button>
        </>
      )
    }
  ]

  componentDidMount () {
    this.getGuestListBySeller()
  }

  // 获取客户列表
  getGuestListBySeller = async () => {
    const { pages, query } = this.state

    const res = await Api.getGuestListBySeller({ ...query, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    }
  }

  // 保存新增收件地址
  saveAddEndUserReceiveAddress = (params) => {
    Api.saveAddEndUserReceiveAddress(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
      } else {
        message.error(res.message)
      }
    })
  }

  // 点击添加地址
  getAddEnduserDetail = (record, type) => {
    this.setState({
      getaddadress: true,
      rowDetail: record,
      type: type
    })
  }

  // 导入客户
  changeUploadEndUser = (info) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        this.props.history.push({ pathname: '/customer/customerUploadMatch', state: { data: data } })
      } else if (code === 'SUPPLIER_ERROR_00001') {
        removeStore('token')
        window.location.href = 'http://' + window.location.host + '/login'
      } else {
        message.error(info.file.response.message)
      }
    } else if (info.file.status === 'error') {
      message.error(info.file.response.message)
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    if (search.times) {
      search.beginDate = search.times.length === 2 ? search.times[0].format('YYYY-MM-DD') : undefined
      search.endDate = search.times.length === 2 ? search.times[1].format('YYYY-MM-DD') : undefined
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search || {}
    }, () => {
      this.getGuestListBySeller()
    })
  }

  // 跳转到详情
  handleJumpToEnduserDetail = (record, type = 1) => {
    this.props.history.push({ pathname: '/customer/enduserDetail', state: { ...record, type: type } })
  }

  // 下载模板
  handleDownloadTemplate = () => {
    downloadUploadEndUserTemplate({})
  }

  // 导出客户列表
  downloadCustomer = () => {
    const { query } = this.state
    downloadEndUserList(query)
  }

  // 确定保存地址
  handleConfirmSaveAddress = (values) => {
    const params = changeEnterParamsKey(values)

    params.category = values.status === 'ENABLE' ? 2 : 0

    this.saveAddEndUserReceiveAddress(params)
  }

  // 关闭弹窗
  closeModal = () => {
    this.setState({
      allocatModal: false,
      type: 1,
      getaddadress: false,
      rowDetail: {}
    })
  }

  // 查看地址
  getEndUserReceivedAddressList = (record) => {
    this.props.history.push({ pathname: '/customer/customerAddress', state: { record } })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getGuestListBySeller()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getGuestListBySeller()
    })
  }

  render () {
    const { searchData, pages, tableData, type = 1, getaddadress = false, rowDetail } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div className={`${layoutStyles.themeSearch}`} style={{ marginBottom: '20px' }}>
          <SearchForm {...this.props}
            hasBackground
            dataSource={searchData}
            handleSubmit={(obj) => this.handleSearch(obj)}
          />
        </div>

        <Card>
          <Row style={{ marginBottom: '20px' }}>
            <Button type='primary' onClick={() => this.handleJumpToEnduserDetail({}, 1)}><Icon type="plus" />新增客户</Button>

            <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => this.handleDownloadTemplate()}>
              <Icon type="download" />下载模板
            </Button>

            <Upload name='file' showUploadList={false}
              headers={{
                Token: getStore('token')
              }}
              accept='.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              action={beforeuploadEndUser}
              onChange={this.changeUploadEndUser}
            >
              <Button type='primary' style={{ marginLeft: '10px' }}><Icon type="upload" />导入客户</Button>
            </Upload>

            <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => this.downloadCustomer()}>
              <Icon type="download" />导出客户
            </Button>
          </Row>

          <Table rowKey='euId'
            columns={this.columns}
            dataSource={tableData}
            pagination={pagination}
            scroll={{ x: 1500 }}
          />
        </Card>

        {
          getaddadress
            ? <AddressModal
                type={type}
                endUserId={rowDetail.endUser.id || 0 }
                detailInfo={rowDetail}
                onCancel={() => this.closeModal()}
                onConfirm={(params) => this.handleConfirmSaveAddress(params)}
              />
            : null
        }
      </>
    )
  }
}
