import React, { useState } from 'react'
import { Card } from 'antd'

import SupplySaledOrder from '../supplySaledOrder/index'
import PurchaseSaled from '../purchaseSaled'

import styles from './index.module.scss'

const menuMaps = [
  { id: 'SUPPLY_SALED', name: '云供应售后申请' },
  { id: 'PURCHASE_SALED', name: '云采购售后申请' }
]

export default function SaledOrder () {
  const [activeKey, setActiveKey] = useState('SUPPLY_SALED')

  return (
    <Card bodyStyle={{ padding: '30px' }}>
      <div className={`${styles.topMenus}`} style={{ marginBottom: '30px' }}>
        {
          menuMaps.map((item) => (
            <div className={`${styles.menuItem} ${item.id === activeKey ? styles.active : ''}`} key={item.id} onClick={() => setActiveKey(item.id)}>{ item.name }</div>
          ))
        }
      </div>

      {
        activeKey === 'SUPPLY_SALED'
          ? <SupplySaledOrder />
          : null
      }

      {
        activeKey === 'PURCHASE_SALED'
          ? <PurchaseSaled />
          : null
      }
    </Card>
  )
}
