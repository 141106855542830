import React from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Col } from 'antd'

export default function MatchExplain (props) {
  const { totalNum = 0, successNum = 0, failNum = 0 } = props

  return (
    <>
      <div style={{ marginBottom: '20px' }}>
        <Card bodyStyle={{ padding: '15px' }}>
          <Row gutter={12}>
            <Col span={8} style={{ color: 'rgba(0, 0, 0, 0.8)', textAlign: 'center' }}>
              <div style={{ fontWeight: '600', marginBottom: '4px' }}>总共</div>
              <div style={{ fontSize: '18px' }}>{totalNum}</div>
            </Col>
            <Col span={8} style={{ color: '#52C41A', textAlign: 'center' }}>
                <div style={{ fontWeight: '600', marginBottom: '4px' }}>匹配成功</div>
                <div style={{ fontSize: '18px' }}>{successNum}</div>
            </Col>
            <Col span={8} style={{ color: '#F5222D', textAlign: 'center' }}>
              <div style={{ fontWeight: '600', marginBottom: '4px' }}>需要修正</div>
              <div style={{ fontSize: '18px' }}>{failNum}</div>
            </Col>
          </Row>
        </Card>
      </div>
    </>
  )
}

MatchExplain.propTypes = {
  totalNum: PropTypes.number,
  successNum: PropTypes.number,
  failNum: PropTypes.number
}
