import PurchaseQuickOrder from '@/pages/purchase/quickOrder/index'
import PurchaseCart from '@/pages/purchase/cart/index'
import OrderSubmit from '@/pages/purchase/orderSubmit/index'
import OrderPay from '@/pages/purchase/orderPay/index' // 购物车支付
import OrderTransferDetails from '@/pages/purchase/orderTransferDetails/index' // 下单成功
import OrderSuccess from '@/pages/purchase/orderSuccess/index' // 支付成功
import ReceivingAddress from '@/pages/purchase/receivingAddress/index' // 收货地址

import CloudLibrary from '@/pages/purchase/cloudLibrary/index' // 云库产品列表

export default [
  {
    path: '/purchase/quickOrder',
    name: '快速下单',
    component: PurchaseQuickOrder
  },
  {
    path: '/purchase/cart',
    name: '我的购物车',
    component: PurchaseCart
  },
  {
    path: '/purchase/orderSubmit',
    name: '结算',
    component: OrderSubmit
  },
  {
    path: '/purchase/orderPay',
    name: '支付订单',
    component: OrderPay
  },
  {
    path: '/purchase/orderTransferDetails',
    name: '下单成功',
    component: OrderTransferDetails
  },
  {
    path: '/purchase/orderSuccess',
    name: '下单成功',
    component: OrderSuccess
  },
  {
    path: '/purchase/receivingAddress',
    name: '收货地址',
    component: ReceivingAddress
  },
  {
    path: '/purchase/cloudLibrary',
    name: '云库产品列表',
    component: CloudLibrary
  }
]
