import React, { useState, useEffect } from 'react'
import { Card, Tabs } from 'antd'

import SetPaywordForm from './setPaywordForm/index'
import EditPaywordForm from './editPaywordForm/index'

import Api from '@/common/api/index'

import styles from './index.module.scss'

const { TabPane } = Tabs
const totalPaneMaps = [
  { id: 'ADD', name: '设置支付密码', hased: false },
  { id: 'EDIT', name: '修改支付密码', hased: true },
  { id: 'RESET', name: '重置支付密码', hased: true }
]

function SetPayword (props) {
  const [tabPaneMaps, setTabPaneMaps] = useState([])
  const [activeKey, setActiveKey] = useState('')

  // 查询是否设置支付密码
  const getHasPayPassword = async () => {
    const res = await Api.getHasPayPassword({})
    const { code, data = false } = res
    if (+code === 10000) {
      const newArr = totalPaneMaps.filter((o) => { return o.hased === data })

      setTabPaneMaps(newArr)
      setActiveKey(newArr[0].id)
    }
  }

  useEffect(() => {
    getHasPayPassword()
  }, [])

  return (
    <>
      <Card bodyStyle={{ padding: '20px' }}>
        <div className={`${styles.tabsLand}`}>
          <Tabs onChange={(key) => setActiveKey(key)}>
          {
            tabPaneMaps.map((item, i) => (
              <TabPane tab={item.name} key={item.id} />
            ))
          }
          </Tabs>
        </div>

        {
          ['ADD', 'RESET'].includes(activeKey)
            ? <SetPaywordForm
              activeKey={activeKey}
              onSuccess={() => getHasPayPassword()}
          />
            : null
        }

        {
          activeKey === 'EDIT'
            ? <EditPaywordForm activeKey={activeKey} />
            : null
        }
      </Card>

    </>
  )
}

export default SetPayword
