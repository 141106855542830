import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Col, Progress } from 'antd'
import * as echarts from 'echarts'

import MatchSteps from '../component/matchSteps/index'

import Api from '@/common/api/index'
import styles from './index.module.scss'

export default class MatchReport extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  state = {
    importId: null,
    completeNum: 0,
    successNum: 0,
    failNum: 0,
    totalNum: 0
  }

  componentDidMount () {
    const { importId } = this.props.history.location.state

    this.setState({
      importId: importId
    }, () => {
      this.getExcelAnalysisMatchResult()
    })
  }

  // 获取EXCEL解析数据匹配结果
  getExcelAnalysisMatchResult = async () => {
    const { importId } = this.state
    const res = await Api.getExcelAnalysisMatchResult({ importId: importId, matchType: 'MATCH', pageNo: 1, pageSize: 99999 })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        completeNum: data.completeNum || 0,
        failNum: data.failNum || 0,
        successNum: data.successNum || 0,
        totalNum: data.totalNum || 0
      }, () => {
        if (+data.completeNum < +data.totalNum) {
          setTimeout(() => {
            this.getExcelAnalysisMatchResult()
          }, 3000)
        } else {
          this.initMatchProgressChart()
        }
      })
    }
  }

  // 匹配进度
  initMatchProgressChart = () => {
    const { successNum, failNum } = this.state
    const progressChart = echarts.init(document.getElementById('progressEchart'))

    const options = {
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)'
      },
      color: ['#52c41a', '#f5222d'], // 环形颜色
      series: [
        {
          name: '完全匹配率',
          type: 'pie',
          radius: ['60%', '70%'],
          avoidLabelOverlap: false,
          hoverAnimation: false,
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '30',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: [
            { value: successNum, name: '匹配正确' },
            { value: failNum, name: '匹配错误' }
          ]
        }
      ]
    }

    // 绘制图表
    progressChart.setOption(options)
  }

  // 点击下一步 跳转到详情
  handleSubmit = () => {
    const { importId, totalNum, successNum, failNum } = this.state
    this.props.history.push({ pathname: '/enquire/enquireMatchDetails', state: { importId: importId, totalNum: totalNum, successNum: successNum, failNum: failNum } })
  }

  render () {
    const { totalNum, successNum, failNum, completeNum } = this.state

    return (
      <>
        <Card bodyStyle={{ padding: '12px' }} bordered={false}>
          <MatchSteps activeStep={1} onConfirm={() => this.handleSubmit()} />

          <div className={`${styles.reportContents}`}>
            <div className={`${styles.progressWrap}`}>
              <div className={`${styles.progressAntd}`}>
                {
                  +completeNum < +totalNum
                    ? <>
                      <Progress type='circle'
                        width={200}
                        strokeWidth={7}
                        strokeColor={'#52c41a'}
                        percent={+((+completeNum / +totalNum) * 100).toFixed(2)}
                      />
                      <div className={`${styles.progressTitle}`}>处理进度</div>
                    </>
                    : <>
                      <div id='progressEchart' className={`${styles.progressChart}`} style={{ width: '320px', height: '320px' }} />
                      <div className={`${styles.progressText}`}>
                        <div className={`${styles.progressLabel}`}>匹配型号</div>
                        <div className={`${styles.progressValue}`}>
                          { +completeNum > 0 ? (+successNum / +completeNum * 100).toFixed(2) : 0.00 }%
                        </div>
                      </div>
                    </>
                }
              </div>
            </div>
            <div className={styles.explainWrap}>
              <Row gutter={16} style={{ marginTop: '70px' }}>
                <Col span={6}>
                  <div className={`${styles.num}`}>{completeNum}</div>
                  <p className={`${styles.part}`}>个型号已上传</p>
                </Col>
                <Col span={6}>
                  <div className={`${styles.num}`}>{successNum}</div>
                  <p className={`${styles.part}`}>个型号完全匹配</p>
                </Col>
                <Col span={8}>
                  <div className={`${styles.num} ${styles.redColor}`}>{failNum}</div>
                  <p className={`${styles.part}`}>个型号需要修改</p>
                </Col>
              </Row>
            </div>
          </div>
        </Card>
      </>
    )
  }
}
