import React, { Component } from 'react'
import { Card } from 'antd'

import BillDb from '../transportBill-DB/index'
import styles from './index.module.scss'

const menuMaps = [
  { id: 'SAAS_BILL_DBL', name: '运费账单（德邦）' }
]

export default class FreightBills extends Component {
  state = {
    activeKey: 'SAAS_BILL_DBL'
  }

  // 点击切换tab
  handleChangeMenu = (key) => {
    this.setState({
      activeKey: key
    })
  }

  render () {
    const { activeKey = 'SAAS_BILL_DBL' } = this.state

    return (
      <Card bodyStyle={{ padding: '30px' }}>
        <div className={`${styles.topMenus}`} style={{ marginBottom: '30px' }}>
          {
            menuMaps.map((item) => (
              <div className={`${styles.menuItem} ${item.id === activeKey ? styles.active : ''}`} key={item.id} onClick={() => this.handleChangeMenu(item.id)}>{ item.name }</div>
            ))
          }
        </div>

        {
          activeKey === 'SAAS_BILL_DBL'
            ? <BillDb></BillDb>
            : null
        }
      </Card>
    )
  }
}
