import CloudCredit from '@/pages/finance/cloudCredit/index' // 云平台信用管理
import SupplyInvoice from '@/pages/finance/supplyInvoice/index'// 云供应开票
import PurchaseInvoice from '@/pages/finance/purchaseInvoice/index' // 云采购开票
import ExpressBills from '@/pages/finance/expressBills/index' // 运费账单
import PlatceditDetail from '@/pages/finance/platcredit-detail/index' // 云平台信用管理详情
import SetPayword from '@/pages/account/setPayword/index' // 设置支付密码
import Balance from '@/pages/finance/balance/index' // 账户余额
import InvoiceAddress from '@/pages/finance/invoiceAddress/index' // 收票地址

export default [
  {
    path: '/finance/cloudCredit',
    name: '云平台信用管理',
    component: CloudCredit
  },
  {
    path: '/finance/cloudInvoice',
    name: '云供应开票管理',
    component: SupplyInvoice
  },
  {
    path: '/finance/purchaseInvoice',
    name: '云采购开票管理',
    component: PurchaseInvoice
  },
  {
    path: '/finance/invoiceAddress',
    name: '收票地址',
    component: InvoiceAddress
  },
  {
    path: '/finance/expressBills',
    name: '运费账单',
    component: ExpressBills
  },
  {
    path: '/finance/platcreditDetail',
    name: '信用详情',
    component: PlatceditDetail
  },
  {
    path: '/finance/setPayword',
    name: '信用详情',
    component: SetPayword
  },
  {
    path: '/finance/balance',
    name: '账户余额',
    component: Balance
  }
]
