import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Form, Row, Col, Input, Button, message } from 'antd'
import JSEncrypt from 'jsencrypt'
import PublickeyJson from '@/assets/json/publicKey.json'

import VerifyModal from '@/components/verifyModal/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import { hideMobile } from '@/common/utils/mUtils'

const FormItem = Form.Item
const { paywordReg } = filter
let timer = null

function SetPaywordForm (props) {
  const userInfo = useSelector(state => state.userInfo)
  const { activeKey, onSuccess } = props
  const { getFieldDecorator, resetFields, validateFields } = props.form
  const [verifyModal, setVerifyModal] = useState(false)
  const [time, setTime] = useState(60)
  const [btnDisabled, setBtndisabled] = useState(false)

  const sendSmsCaptchaAndValidate = async (params) => {
    params.sendSmsCaptchaEnum = 'SMS_CAPTCHA_TO_ADMIN'
    const res = await Api.sendSmsCaptchaAndValidate(params)
    const { code } = res
    if (+code === 10000) {
      setSatrtInterval()
    }
  }

  // 更新支付密码
  const updatePayPassword = async (params) => {
    const res = await Api.updatePayPassword(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      stopInterval()
      handleCancel()
      onSuccess()
    }
  }

  // 点击保存
  const handleSubmit = () => {
    validateFields((err, values) => {
      if (err) { return false }
      const { oldPassword, newPassword } = values
      const jsencrypt = new JSEncrypt()
      jsencrypt.setPublicKey(PublickeyJson[PATH_ENV])

      values.newPassword = jsencrypt.encrypt(newPassword)
      if (activeKey === 'EDIT') {
        values.oldPassword = jsencrypt.encrypt(oldPassword)
      }

      updatePayPassword(values)
    })
  }

  // 图片验证成功
  const handleVerifySlideCheckSuccess = (values) => {
    setVerifyModal(false)
    sendSmsCaptchaAndValidate(values)
  }

  // 点击取消
  const handleCancel = () => {
    resetFields()
  }

  // 倒计时
  const countDown = () => {
    if (time === 1) {
      stopInterval()
    } else {
      setBtndisabled(true)
      setTime(t => --t)
    }
  }

  // 开始定时器
  const setSatrtInterval = () => {
    timer = setInterval(() => { countDown() }, 1000)
  }

  // 清除定时器
  const stopInterval = () => {
    clearInterval(timer)
    setBtndisabled(false)
    setTime(60)
  }

  return (
    <>
      <Form>
        <Row gutter={24}>
          <Col span={8}>
            <FormItem label={activeKey === 'RESET' ? '新支付密码' : '支付密码'}>
            {
              getFieldDecorator('newPassword', {
                getValueFromEvent: event => event.target.value.replace(/\s+/g, ''),
                validate: [
                  {
                    trigger: 'onBlur',
                    rules: [
                      {
                        validator (rule, value, callback) {
                          if (value && !paywordReg.test(value)) {
                          /* eslint-disable */
                          callback('支付密码格式不正确')
                          /* eslint-enable */
                          } else {
                            callback()
                          }
                        }
                      }
                    ]
                  },
                  {
                    trigger: ['onChange', 'onBlur'],
                    rules: [{ required: true, message: '请您填写6位数字支付密码' }]
                  }
                ]
              })(
                <Input.Password />
              )
            }
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label='短信验证码' help={userInfo.loginUserPhone && userInfo.loginUserPhone !== '' ? hideMobile(userInfo.loginUserPhone) : null}>
              <div style={{ display: 'inline-block', marginRight: '15px', width: 'calc(100% - 145px)' }}>
              {
                getFieldDecorator('randomCode', {
                  rules: [
                    { required: true, message: '请填写手机验证码' }
                  ]
                })(
                  <Input placeholder='请输入...' autoComplete='off' style={{ width: '100%' }} />
                )
              }
              </div>
              <Button style={{ width: '130px' }} disabled={btnDisabled} onClick={() => setVerifyModal(true)}>
                { !btnDisabled ? '获取验证码' : `${time} s 后重新发送` }
              </Button>
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24} style={{ marginTop: '24px' }}>
          <Col span={8}></Col>
          <Col span={8}>
            <FormItem>
              <Button type='primary' onClick={() => handleSubmit()}>保存</Button>
              <Button style={{ marginLeft: '24px' }} onClick={() => handleCancel()}>取消</Button>
            </FormItem>
          </Col>
        </Row>
      </Form>

      {
        verifyModal
          ? <VerifyModal
            onConfirm={(params) => handleVerifySlideCheckSuccess(params)}
            onCancel={() => setVerifyModal(false)}
          />
          : null
      }
    </>
  )
}

SetPaywordForm.propTypes = {
  form: PropTypes.object,
  activeKey: PropTypes.string,
  onSuccess: PropTypes.func
}

const SetPayword = Form.create()(SetPaywordForm)

export default SetPayword
