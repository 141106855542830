import Ajax from '../utils/ajax'

export default {
  // 获取登录用户endUserId
  getPurchaserEndUserId (params) {
    return Ajax.get('/api/guest/get/purchaserEndUser', { params })
  },

  // 获取菜单
  getMenus (params) {
    return Ajax.get('/api/menu/getMenuTree', { params })
  },

  // 修改登录密码
  saveUpdatePassword (params) {
    return Ajax.post('/api/home/page/update/account/passwordByPhone', params)
  },

  // 获取商户基本信息
  getMerchantBaseInfo (params) {
    return Ajax.get('/api/mall/manage/get/platformMerchantInfo', { params })
  },

  // 企业账户余额
  getEnterpriseBalance (params) {
    return Ajax.get('/api/finance/get/distributor/balance', { params })
  },

  // 查询是否设置了支付密码
  getHasPayPassword (params) {
    return Ajax.get('/api/finance/pay/hasPayPassword', { params })
  },

  // 更新支付密码
  updatePayPassword (params) {
    return Ajax.post('/api/finance/pay/updatePayPassword', params)
  }
}
