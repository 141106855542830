import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card } from 'antd'

import SupplySpotProducts from './supplySpotProducts/index' // 现货云供应
import SupplyFuturesProducts from './supplyFuturesProducts/index' // 期货云供应
import styles from './index.module.scss'

const menuMaps = [
  { id: 'CLOUD_SPOT', name: '现货云供应' },
  { id: 'CLOUD_FUTURES', name: '期货云供应' }
]

export default class CloudSupplyProducts extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  state = {
    activeTabKey: 'CLOUD_SPOT'
  }

  componentDidMount () {
    const { state = {} } = this.props.history.location

    this.setState({
      activeTabKey: state.activeTabKey && state.activeTabKey !== '' ? state.activeTabKey : 'CLOUD_SPOT'
    })
  }

  // 切换Tab
  handleChangeMenu = (key) => {
    this.setState({
      activeTabKey: key
    })
  }

  render () {
    const { activeTabKey = 'CLOUD_SPOT' } = this.state

    return (
      <Card bodyStyle={{ padding: '30px' }}>
        <div className={`${styles.topMenus}`} style={{ marginBottom: '30px' }}>
          {
            menuMaps.map((item) => (
              <div className={`${styles.menuItem} ${item.id === activeTabKey ? styles.active : ''}`} key={item.id} onClick={() => this.handleChangeMenu(item.id)}>{ item.name }</div>
            ))
          }
        </div>

        {
          activeTabKey === 'CLOUD_SPOT'
            ? <SupplySpotProducts history={this.props.history} />
            : null
        }

        {
          activeTabKey === 'CLOUD_FUTURES'
            ? <SupplyFuturesProducts history={this.props.history} />
            : null
        }
      </Card>
    )
  }
}
