import Ajax from '../utils/ajax'

export default {
  // 新建询价单-搜索产品
  getEnquireSearchProductList (params) {
    return Ajax.post('/product/index/search', params)
  },

  // 询价单-保存新增询价单
  saveAddEnquireOrder (params) {
    return Ajax.post('/enquiry/order/manage/center/add', params)
  },

  // 询价单-更新询价单
  updateEnquireOrder (params) {
    return Ajax.post('/enquiry/order/manage/center/update', params)
  },

  // 询价单-获取询价单列表
  getEnquireOrderList (params) {
    return Ajax.post('/enquiry/order/manage/center/listPage', params)
  },

  // 询价单-询价详情修正结果查询
  getCorrectEnquireOrderDetails (params) {
    return Ajax.get('/enquiry/order/manage/center/detail/correct/query', { params })
  },

  // 询价单-询价详情修正结果更新
  updateCorrectEnquireOrderDetails (params) {
    return Ajax.post('/enquiry/order/manage/center/detail/correct/update', params)
  },

  // 询价单-保存询价详情
  saveCorrectEnquireOrderDetails (params) {
    return Ajax.post('/enquiry/order/manage/center/detail/correct/add', params)
  },

  // 询价单-获取询价过程详情
  getEnquireProcessDetails (params) {
    return Ajax.get('/enquiry/order/manage/center/query/enquiry/process', { params })
  },

  // 询价单-修正产品》获取待选产品列表
  getProductListInCorrectEnquireDetails (params) {
    return Ajax.get('/product/index/singleSearch', { params })
  },

  // 询价单-我的询价-导出文件&查看报价单
  getEnquireOrderDownloadUrl (params) {
    return Ajax.get('/enquiry/order/manage/center/get/pdf/url', { params })
  },

  // 询价单-询价过程-更新询价状态 (拒绝报价、沟通报价、同意报价)
  updateQuotationOrderState (params) {
    return Ajax.post('/enquiry/order/manage/center/updateStatus', params)
  },

  // 询价单- 获取我的预报价单列表
  getPrepareQuotationsOrderList (params) {
    return Ajax.post('/api/predict/quotation/order/management/listPage', params)
  },

  // 询价单- 获取预报价单详情
  getPrepareQuotationsDetails (params) {
    return Ajax.post('/api/predict/quotation/order/management/detail', params)
  },

  // 每日交易信息- 获取成交产品列表
  getEnquireTradedGoodsList (params) {
    return Ajax.post('/enquiry/order/manage/center/listPageByCloudOrderGoods', params)
  },

  // 每日交易信息- 获取今日询价信息列表
  getPublishedEnquireInformations (params) {
    return Ajax.post('/enquiry/order/manage/center/listPageEnquiryNotice', params)
  },

  // 询价单- 预报价单- 获取预报价单PDF路径
  getPrepareQuotationOrderPdfUrl (params) {
    return Ajax.post('/api/predict/quotation/order/management/findPdf', params)
  },

  // 查看任务进度
  getTaskProgressDetail (params) {
    return Ajax.post('/api/product/listLatestTask', params)
  },

  // 询价单-预报价单- 修正产品- 获取待选产品列表
  getProductListInCorrectPrepareEnquireDetails (params) {
    return Ajax.get('/sms/merchant/product/index/singleSearch', { params })
  },

  // 询价单-预报价单- 询价详情修正结果更新
  updateCorrectPrepareEnquireOrderDetails (params) {
    return Ajax.post('/api/predict/quotation/order/management/correctResultEdit', params)
  }
}
