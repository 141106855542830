import Ajax from '../utils/ajax'

export default {
  // 云困产品列表- 产品联想
  // 文本框搜索结果搜索
  tipSearch (params) {
    return Ajax.get('/api/search/tipSearch', { params })
  },

  // 获取云采购分类列表
  getCloudCategoryList (params) {
    return Ajax.get('/api/cloud/procurement/listCloudClassification', { params })
  },

  // 获取云采购品牌列表
  getCloudBrandList (params) {
    return Ajax.get('/api/cloud/procurement/listCloudBrand', { params })
  },

  // 获取系列
  getSeriesList (params) {
    return Ajax.post('/api/cascade/series', params)
  },

  // 云库产品列表
  getCloudLibraryProductList (params) {
    return Ajax.post('/api/cloud/procurement/keyWordSearch', params)
  }
}
