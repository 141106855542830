import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Button, message } from 'antd'
import JSEncrypt from 'jsencrypt'
import PublickeyJson from '@/assets/json/publicKey.json'
import { withRouter } from 'react-router-dom'

import VerifyModal from '@/components/verifyModal/index'

import Api from '@/common/api/index'
import { hideMobile, removeStore } from '@/common/utils/mUtils'
import filter from '@/common/utils/filter'

import styles from './index.module.scss'

const { passReg } = filter
const FormItem = Form.Item

class SetAccountForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    history: PropTypes.object
  }

  state={
    faly: 1,
    rowDetail: {},
    btnDisabled: false,
    yztime: 59,
    verifyModal: false
  }

  componentDidMount () {
    this.getUserInfo()
  }

  // 组件卸载时 关闭定时器
  componentWillUnmount () {
    this.clearInterval()
  }

  // 获取用户信息
  getUserInfo = () => {
    Api.getLoginUserInfo({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          rowDetail: data
        })
      }
    })
  }

  // 修改密码
  saveUpdatePassword = (params) => {
    Api.saveUpdatePassword(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('保存成功')
        removeStore('token')
        this.props.history.push({ pathname: '/login' })
      } else {
        message.warning(res.message)
      }
    })
  }

  // 发送短信验证码
  sendSmsCaptchaAndValidate = (params) => {
    Api.sendSmsCaptchaAndValidate(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        this.setInterval()
      }
    })
  }

  // 点击提交
  handleSumit= () => {
    this.props.form.validateFields((err, values) => {
      const { rowDetail } = this.state
      if (!err) {
        if (values.confirmPassword !== values.password) {
          return message.warning('两次输入密码不一致！')
        }

        const { password } = values
        const jsencrypt = new JSEncrypt()
        jsencrypt.setPublicKey(PublickeyJson[PATH_ENV])
        values.newPassword = jsencrypt.encrypt(password)
        values.phoneNumber = rowDetail.loginUserPhone

        this.saveUpdatePassword(values)
      }
    })
  }

  // 点击取消
  handleBack = () => {
    this.setState({
      faly: 1
    }, () => {
      this.props.form.resetFields()
    })
  }

  // 点击修改密码
  handlePassword = () => {
    this.setState({
      faly: 2
    })
  }

  // 点击发送验证码
  handleSendCode = () => {
    this.setState({
      verifyModal: true
    })
  }

  // 开始定时器
  setInterval = () => {
    this.timer = setInterval(this.countDown, 1000)
  }

  // 倒计时
  countDown = () => {
    const { yztime = 59 } = this.state
    if (yztime === 1) {
      this.clearInterval()
    } else {
      this.setState({ btnDisabled: true, yztime: yztime - 1 })
    }
  }

  // 清除定时器
  clearInterval = () => {
    this.setState({
      btnDisabled: false,
      yztime: 59
    }, () => {
      clearInterval(this.timer)
    })
  }

  // 滑块验证码验证成功
  handleVerifySlideCheckSuccess = (params) => {
    this.setState({
      verifyModal: false
    }, () => {
      const { rowDetail = {} } = this.state
      this.sendSmsCaptchaAndValidate({ ...params, ...rowDetail, sendSmsCaptchaEnum: 'SMS_CAPTCHA_TO_ADMIN' })
    })
  }

  closeModal = () => {
    this.setState({
      msgModal: false,
      verifyModal: false
    }, () => {
      this.clearInterval()
    })
  }

  render () {
    const { getFieldDecorator } = this.props.form
    const { faly, rowDetail = {}, btnDisabled = 59, yztime, verifyModal = false } = this.state

    return (
      <>
        <Form className={`${styles.settingForms}`}>
          {
            faly === 1
              ? <>
                  <FormItem label='登录名' style={{ marginBottom: '16px', borderBottom: '1px solid #F9F9F9' }}>
                    <div className={styles.txt}>{rowDetail.username}</div>
                  </FormItem>

                  <FormItem label='已绑定手机号' style={{ marginBottom: '16px', borderBottom: '1px solid #F9F9F9' }}>
                    <div className={styles.txt}>{rowDetail.username}</div>
                  </FormItem>

                  <FormItem label='账户密码' style={{ marginBottom: '16px', borderBottom: '1px solid #F9F9F9' }}>
                    <div className={`${styles.flexbox}`}>
                      <div className={styles.txt}>******</div>
                      <Button type='link' size='small' onClick={() => this.handlePassword()}>修改密码</Button>
                    </div>
                  </FormItem>
                </>
              : <>
                  <div className={`${styles.flexbox}`} style={{ marginBottom: '16px' }}>
                    <div className={`${styles.flexContent}`}>手机号码为<span className={`${styles.mobile}`}>{ hideMobile(rowDetail.loginUserPhone) || '----' }</span></div>
                    <Button type='link' disabled={btnDisabled} onClick={() => this.handleSendCode()}>
                      { !btnDisabled ? '获取验证码' : `${yztime}s后重新发送` }
                    </Button>
                  </div>

                  <FormItem label='输入验证码' >
                    {
                      getFieldDecorator('randomCode', {
                        initialValue: '',
                        rules: [
                          { required: true, message: '验证码必填' }
                        ]
                      })(<Input autoComplete='off' />)
                    }
                  </FormItem>

                  <FormItem label='输入新密码'>
                    {
                      getFieldDecorator('password', {
                        initialValue: '',
                        rules: [
                          { required: true, message: '密码必填' },
                          {
                            validator (rule, value, callback) {
                              if (value && !passReg.test(value)) {
                                /* eslint-disable */
                                  callback('登录密码格式不正确')
                                /* eslint-enable */
                              } else {
                                callback()
                              }
                            }
                          }
                        ]
                      })(<Input.Password placeholder='密码长度为6-20位，英文字母或数字' autoComplete='off' />)
                    }
                  </FormItem>

                  <FormItem label='确认新密码'>
                    {
                      getFieldDecorator('confirmPassword', {
                        initialValue: '',
                        rules: [
                          { required: true, message: '密码必填' }
                        ]
                      })(<Input.Password placeholder='请确认密码' autoComplete='off'/>)
                    }
                  </FormItem>

                  <FormItem>
                    <Button type='primary' onClick={() => this.handleSumit()}>保存</Button>
                    <Button style={{ marginLeft: '16px' }} onClick={() => this.handleBack()}>取消</Button>
                  </FormItem>
            </>
          }
        </Form>

        {
          verifyModal
            ? <VerifyModal
              onConfirm={(params) => this.handleVerifySlideCheckSuccess(params)}
              onCancel={this.closeModal}
            />
            : null
        }
      </>
    )
  }
}

const SetAccount = Form.create()(SetAccountForm)
export default withRouter(SetAccount)
