import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Card, Button, Steps, Icon, Progress } from 'antd'
import * as echarts from 'echarts'

import Api from '@/common/api/index'

import styles from './index.module.scss'

const { Step } = Steps

const stepMaps = [
  { title: '确认数据', code: '1' },
  { title: '匹配数据', code: '2' },
  { title: '生成列表', code: '3' }
]

export default class MatchResult extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  state = {
    importId: 0, // 匹配流水号
    config: {}, // 参数配置
    info: {}, // 匹配信息
    failList: [] // 匹配失败数据
  }

  componentDidMount () {
    const { state = {} } = this.props.history.location

    this.setState({
      importId: state.importId,
      config: state.config || {}
    }, () => {
      this.getExcelAnalysisMatchResult()
    })
  }

  // 获取匹配进度信息
  getExcelAnalysisMatchResult = () => {
    const { importId } = this.state
    const params = {
      importId: importId,
      matchType: 'MATCH',
      pageNo: 1,
      pageSize: 999
    }

    Api.getExcelAnalysisMatchResult(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          info: data,
          failList: data.uploadInfoList || []
        }, () => {
          if (+data.completeNum < +data.totalNum) {
            setTimeout(() => {
              this.getExcelAnalysisMatchResult()
            }, 3000)
          } else {
            this.initMatchProgressChart()
          }
        })
      }
    })
  }

  // 匹配进度
  initMatchProgressChart = () => {
    const { info = {} } = this.state
    const progressChart = echarts.init(document.getElementById('progressEchart'))

    const options = {
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)'
      },
      color: ['#52c41a', '#f5222d'], // 环形颜色
      series: [
        {
          name: '完全匹配率',
          type: 'pie',
          radius: ['60%', '70%'],
          avoidLabelOverlap: false,
          hoverAnimation: false,
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '30',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: [
            { value: info.successNum, name: '匹配正确' },
            { value: info.failNum, name: '匹配错误' }
          ]
        }
      ]
    }

    // 绘制图表
    progressChart.setOption(options)
  }

  // 跳转到列表详情页
  handleLinkToMatchResult = () => {
    const { importId, info, config = {} } = this.state
    const detailInfo = {
      importId: importId,
      totalNum: info.totalNum,
      completeNum: info.completeNum,
      failNum: info.failNum,
      successNum: info.successNum
    }
    this.props.history.push({ pathname: '/matchs/matchDetail', state: { detailInfo: detailInfo, config: config } })
  }

  render () {
    const { info = {} } = this.state

    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '15px' }}>
            <Row>
              <Col span={8}>
                <Steps size='small' current={1}>
                  {
                    stepMaps.map((item, i) => (
                      <Step title={item.title} key={`step-${i}`} />
                    ))
                  }
                </Steps>
              </Col>

              <Col span={8} offset={8} style={{ textAlign: 'right' }}>
                <Button type='primary' onClick={() => this.handleLinkToMatchResult()}>
                  下一步<Icon type='arrow-right' />
                </Button>
              </Col>
            </Row>
          </Card>
        </Row>

        <Card bodyStyle={{ padding: '15px' }}>
          <Row style={{ display: 'flex' }}>
            {/* 匹配图表 */}
            {
              +info.completeNum < +info.totalNum
                ? <div className={`${styles.progressWrap}`}>
                    <div className={`${styles.progressAntd}`}>
                      <Progress type='circle'
                        width={200}
                        strokeWidth={7}
                        strokeColor={'#52c41a'}
                        percent={+((+info.completeNum / +info.totalNum) * 100).toFixed(2)}
                      />
                    </div>
                    <div className={`${styles.progressTitle}`}>处理进度</div>
                  </div>
                : <div className={`${styles.progressWrap}`}>
                    <div id='progressEchart' className={`${styles.progressChart}`} style={{ width: '320px', height: '320px' }} />
                    <div className={`${styles.progressText}`}>
                      <div className={`${styles.progressLabel}`}>匹配型号</div>
                      <div className={`${styles.progressValue}`}>
                        {
                          +info.completeNum > 0
                            ? (+info.successNum / +info.completeNum * 100).toFixed(2)
                            : 0.00
                        }%
                      </div>
                    </div>
                  </div>
            }

            {/* 匹配数据说明 */}
            <div className={`${styles.matchInfo}`}>
              <Row gutter={12} style={{ marginTop: '70px' }}>
                <Col span={6}>
                  <div className={`${styles.num}`}>{info.completeNum}</div>

                  <div className={`${styles.partLabel}`}>个型号已上传</div>
                </Col>

                <Col span={6}>
                  <div className={`${styles.num}`}>{info.successNum}</div>

                  <div className={`${styles.partLabel}`}>个型号完全匹配</div>
                </Col>

                <Col span={8}>
                  <div className={`${styles.num} ${styles.redColor}`}>{info.failNum}</div>
                  <div className={`${styles.partLabel}`}>个型号需要修改</div>
                </Col>
              </Row>
            </div>
          </Row>
        </Card>
      </>
    )
  }
}
