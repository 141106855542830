/* 常量集合 */
export default {
  SERIALWIDTH: 56, // 列表序号宽度
  ADDRESS_MAX_NUM: 10, // 添加地址最大数
  REQUEST_TIME_OUT: 1500, // 延时请求
  MAX_NUMBER: 2147483647,
  MAX_PAGESIZE: 999,
  REMARKS_MAX_NUM: 200, // 备注字数
  IMG_LT2M: 2, // 上传图片最大尺寸限制
  ENQUIRE_TYPE: 'MERCHANT' // 询价提交标识
}
