import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Modal, message } from 'antd'

import MatchSteps from '../component/matchSteps/index'
import SheetZone from './sheetZone/index'
import SheetTable from './sheetTable/index'

import Api from '@/common/api/index'

const { confirm } = Modal

export default class MatchSheet extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  state = {
    pages: { total: 100, pageNo: 1, pageSize: 20, options: ['10', '20', '50', '100', '200'] },
    configs: {},
    importId: null,
    excelName: '', // EXCEL名称
    sheetName: null,
    tableData: [], // 源数据
    activeSheetIndex: 0, // 当前选择的sheet
    activeSheetdatas: [] // 当前选择的sheet源数据
  }

  componentDidMount () {
    const { configs, importId } = this.props.history.location.state

    this.setState({
      importId: importId,
      configs: configs || {}
    }, () => {
      this.getUploadExcelAnalysisDatas()
    })
  }

  matchSheetDescriptMap = [
    [
      (name) => name === 'ENQUIRY',
      () => { return { importType: 'ENQUIRY', brandNameColNum: 0, modelColNum: 1, matchNoColNum: 2, itemNoColNum: 2, quantityColNum: 3 } },
      (values) => this.saveUploadExcelAnalysisMatch(values),
      () => { return '/enquire/createEnquireOrder' }
    ],
    [
      (name) => name === 'PREDICT_QUOTATION',
      () => { return { importType: 'PREDICT_QUOTATION', matchNoColNum: 0, brandNameColNum: 0, itemNoColNum: 1, quantityColNum: 2 } },
      (values) => this.saveUploadPrepareQuotaionExcelAnalysisMatch(values),
      () => { return '/enquire/prepareQuotations' }
    ]
  ]

  // 获取上传的EXCEL数据
  getUploadExcelAnalysisDatas = async () => {
    const { importId, pages } = this.state
    const res = await Api.getUploadExcelAnalysisDatas({ importId: importId, pageNo: pages.pageNo, pageSize: 999 })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        excelName: data.data.excelName || '',
        sheetName: data.data.sheetDTOList && data.data.sheetDTOList.length > 0 ? data.data.sheetDTOList[0].sheetName : null,
        tableData: data.data.sheetDTOList || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      }, () => {
        const { tableData = [] } = this.state
        if (tableData.length > 0) {
          this.handleChangeSheetIndex(0)
        }
      })
    }
  }

  // EXCEL解析数据匹配
  saveUploadExcelAnalysisMatch = async (params) => {
    const res = await Api.saveUploadExcelAnalysisMatch(params)
    const { code } = res
    if (+code === 10000) {
      const { importId } = this.state
      this.props.history.push({ pathname: '/enquire/enquireMatchReport', state: { importId: importId } })
    }
  }

  // EXCEL解析预报价数据匹配
  saveUploadPrepareQuotaionExcelAnalysisMatch = async (params) => {
    const res = await Api.saveUploadPrepareQuotaionExcelAnalysisMatch(params)
    const { code } = res
    if (+code === 10000) {
      this.countDown()
    }
  }

  // 点击开始匹配按钮
  handleSubmit = () => {
    const { importId, configs = {} } = this.state
    const getDescribe = this.matchSheetDescriptMap.find((item) => { return item[0](configs.sheetSourch) })

    if (!getDescribe) {
      return message.warning('页面参数sheetSourch错误')
    }

    const params = {
      importId: importId,
      ...getDescribe[1]()
    }

    getDescribe[2](params)
  }

  // 点击取消匹配按钮
  handleGoBack = () => {
    confirm({
      title: '确定取消？',
      content: '',
      onOk: () => {
        const { configs = {} } = this.state
        const getDescribe = this.matchSheetDescriptMap.find((item) => { return item[0](configs.sheetSourch) })
        if (!getDescribe) {
          return message.warning('页面参数sheetSourch错误')
        }

        const url = getDescribe[3]()

        this.props.history.push({ pathname: url })
      }
    })
  }

  // 监听选择sheet列表
  handleChangeSheetIndex = (index) => {
    const { tableData = [] } = this.state

    this.setState({
      activeSheetIndex: index,
      activeSheetdatas: tableData[index].columnDTOList || []
    })
  }

  // 倒计时
  countDown = () => {
    const { history } = this.props
    let secondsToGo = 5

    const modal = Modal.success({
      title: '执行成功',
      content: <>
        <div>若长时间未报价成功，请到列表页报价进度查看</div>
        <div>{secondsToGo}s后将返回列表页</div>
      </>,
      onOk: () => {
        clearInterval(timer)
        clearTimeout(outTimer)
        modal.destroy()

        history.push({ pathname: '/enquire/prepareQuotations' })
      }
    })

    const timer = setInterval(() => {
      secondsToGo -= 1
      modal.update({
        content: <>
        <div>若长时间未报价成功，请到列表页报价进度查看</div>
        <div>{secondsToGo}s后将返回列表页</div>
      </>
      })
    }, 1000)

    const outTimer = setTimeout(() => {
      clearInterval(timer)
      modal.destroy()
      history.push({ pathname: '/enquire/prepareQuotations' })
    }, secondsToGo * 1000)
  }

  render () {
    const { excelName = '', sheetName = null, activeSheetdatas = [] } = this.state

    return (
      <>
        <Card bodyStyle={{ padding: '16px' }} bordered={false}>
          <MatchSteps
            activeStep={0}
            onCancel={() => this.handleGoBack()}
            onConfirm={() => this.handleSubmit()}
          />

          <SheetZone
            name={excelName}
            sheetName={sheetName}
          />

          <SheetTable list={activeSheetdatas} />
        </Card>
      </>
    )
  }
}
