import React, { } from 'react'
import PropTypes from 'prop-types'
import styles from './index.module.scss'

function Supper (props) {
  const { info = {} } = props

  return (
    <ul className={styles.UsInfo}>
      <li>
        <div>现货金额</div>
        <p>&yen;{(+info.spotAmount * 100 / 100).toFixed(2)}</p>
      </li>
      <li>
        <div>期货定金</div>
        <p>&yen;{(+info.futuresFrontMoney * 100 / 100).toFixed(2)}</p>
      </li>

      <li>
        <div>期货尾款</div>
        <p>&yen;{(+info.futuresTailMoney * 100 / 100).toFixed(2)}</p>
      </li>

      <li>
        <div>本次应付</div>
        <p className={styles.UsInfoCRed}>
          &yen;{((info.spotAmount + info.futuresFrontMoney) * 100 / 100).toFixed(2)}
        </p>
      </li>

      <li>
        <div>剩余应付</div>
        <p>&yen;{(+info.futuresTailMoney * 100 / 100).toFixed(2)}</p>
      </li>

      <li>
        <div>税额</div>
        <p>&yen;{ (+info.tax * 100 / 100).toFixed(2) }</p>
      </li>

      <li>
        <div>商品含税总额</div>
        <p>&yen;{ (+info.totalAmount * 100 / 100).toFixed(2) }</p>
      </li>
    </ul>
  )
}

Supper.propTypes =
{
  info: PropTypes.object
}
export default Supper
