import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Table, Button } from 'antd'
import $lodash from 'lodash'

import { renderSkuGoodsTypeLabel } from '@/common/utils/mUtils'
import consts from '@/common/utils/consts'

const { SERIALWIDTH } = consts

export default class DetailModal extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    getDetail: PropTypes.func
  }

  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: []
  }

  columns = [
    {
      title: '序号',
      key: 'supplydetailCol-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '品牌',
      key: 'supplydetailCol-1',
      dataIndex: 'brandName',
      ellipsis: true
    },
    {
      title: '型号',
      key: 'supplydetailCol-2',
      width: 130,
      dataIndex: 'model',
      ellipsis: true
    },
    {
      title: '订货号',
      key: 'supplydetailCol-3',
      width: 130,
      dataIndex: 'itemNumber',
      ellipsis: true
    },
    {
      title: '产品名称',
      key: 'supplydetailCol-4',
      width: 150,
      ellipsis: true,
      dataIndex: 'name'
    },
    {
      title: '单位',
      key: 'supplydetailCol-5',
      width: 80,
      dataIndex: 'unit',
      ellipsis: true
    },
    {
      title: '产品类型',
      key: 'supplydetailCol-6',
      width: 100,
      dataIndex: 'skuGoodsType',
      render: (text, record, index) => (
        <>{ renderSkuGoodsTypeLabel(record.skuGoodsType) }</>
      )
    },
    {
      title: '数量',
      key: 'supplydetailCol-7',
      width: 80,
      dataIndex: 'quantity'
    },
    {
      title: '货期',
      key: 'supplydetailCol-8',
      width: 80,
      dataIndex: 'deliveryPeriodDays',
      render: (text, record, index) => (
        <>
          {
            ['FUTURES', 'CLOUD_FUTURES'].includes(record.skuGoodsType)
              ? <>{ record.deliveryPeriodDays ? `${record.deliveryPeriodDays}个工作日` : '----' }</>
              : '当天发货'
          }
        </>
      )
    },
    {
      title: '预付比例',
      key: 'supplydetailCol-9',
      dataIndex: 'prepaymentRatio',
      render: (text, record, index) => (
        <>
          {
            ['FUTURES', 'CLOUD_FUTURES'].includes(record.skuGoodsType)
              ? <>{ (+record.prepaymentRatio * 100 / 100).toFixed(2) }%</>
              : '----'
          }
        </>
      )
    },
    {
      title: '未税单价',
      key: 'supplydetailCol-10',
      dataIndex: 'excludingTaxUnitPrice',
      render: (text, record, index) => (
        <>&yen;{+record.excludingTaxUnitPrice}</>
      )
    },
    {
      title: '含税单价',
      key: 'supplydetailCol-11',
      dataIndex: 'taxUnitPrice',
      render: (text, record, index) => (
        <>&yen;{+record.taxUnitPrice}</>
      )
    },
    {
      title: '含税单品小计',
      key: 'supplydetailCol-12',
      render: (text, record, index) => (
        <>&yen;{ ($lodash.round(+record.totalTaxUnitPrice, 2) * 100 / 100).toFixed(2)}</>
      )
    }
  ]

  componentDidMount () {
    this.getOrderDetailById()
  }

  // 获取订单明细
  getOrderDetailById = () => {
    const { getDetail, detailInfo } = this.props
    const { pages } = this.state
    const params = {
      purchaseOrderId: detailInfo.purchaseOrderId,
      pageNo: 1,
      pageSize: 99999
    }

    getDetail(params).then(res => {
      const { code, data } = res

      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getOrderDetailById()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getOrderDetailById()
    })
  }

  render () {
    const { tableData } = this.state

    // const pagination = {
    //   showSizeChanger: true,
    //   showQuickJumper: true,
    //   total: pages.total,
    //   showTotal: (total) => { return `共 ${total} 条` },
    //   current: pages.pageNo,
    //   pageSize: pages.pageSize,
    //   pageSizeOptions: pages.options,
    //   onChange: this.pageChange,
    //   onShowSizeChange: this.pageSizeChange
    // }

    return (
      <>
        <Modal title='订单明细' width={1000} bodyStyle={{ padding: '16px' }}
          visible
          maskClosable={false}
          onCancel={() => this.props.onCancel()}
          footer={[<Button key={'btns-cancel'} onClick={() => this.props.onCancel()}>关闭</Button>]}
        >
          <Table rowKey={(record, index) => `${record.commoditySkuId}-${index}`}
            columns={this.columns}
            dataSource={tableData}
            rowClassName={(record, index) => (+record.quantity < 0) || (+record.quantity === 0) ? 'redColor' : ''}
            pagination={false}
            scroll={{ x: 1400, y: 400 }}
          />
        </Modal>
      </>
    )
  }
}
