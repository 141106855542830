import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Input } from 'antd'
import JSEncrypt from 'jsencrypt'
import PublickeyJson from '@/assets/json/publicKey.json'

import filter from '@/common/utils/filter'

const { paywordReg } = filter
const FormItem = Form.Item

class PaywordForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }

  state = {}

  componentDidMount () {}

  handleConfirm = () => {
    const { form, onConfirm } = this.props

    form.validateFields((err, values) => {
      if (!err) {
        const { password } = values
        const jsencrypt = new JSEncrypt()
        jsencrypt.setPublicKey(PublickeyJson[PATH_ENV])
        values.password = jsencrypt.encrypt(password)
        onConfirm(values)
      }
    })
  }

  render () {
    const { form, onCancel } = this.props
    const { getFieldDecorator } = form

    return (
      <>
        <Modal title='支付密码'
          visible
          maskClosable={false}
          onCancel={() => onCancel()}
          onOk={this.handleConfirm}
        >
          <Form>
            <FormItem>
              {
                getFieldDecorator('password', {
                  getValueFromEvent: event => event.target.value.replace(/\s+/g, ''),
                  validate: [
                    {
                      trigger: 'onBlur',
                      rules: [
                        {
                          validator (rule, value, callback) {
                            if (value && !paywordReg.test(value)) {
                              /* eslint-disable */
                                callback('支付密码格式不正确')
                                /* eslint-enable */
                            } else {
                              callback()
                            }
                          }
                        }
                      ]
                    },
                    {
                      trigger: ['onChange', 'onBlur'],
                      rules: [{ required: true, message: '请您填写6位数字支付密码' }]
                    }
                  ]
                })(
                  <Input.Password placeholder='请输入6位数字支付密码' autoComplete='off' />
                )
              }
            </FormItem>
          </Form>
        </Modal>
      </>
    )
  }
}

const PaywordModal = Form.create()(PaywordForm)

export default PaywordModal
