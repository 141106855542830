import React, { Component } from 'react'
import { Form, Row, Col } from 'antd'

import Api from '@/common/api/index'

import { renderAddressLabel } from '@/common/utils/mUtils'

import styles from './index.module.scss'

export default class AccountInformation extends Component {
  state={
    data: {} // 基本信息
  }

  componentDidMount () {
    this.getMerchantBaseInfo()
  }

  // 获取基本信息
  getMerchantBaseInfo = () => {
    Api.getMerchantBaseInfo({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          data: data || {}
        })
      }
    })
  }

  render () {
    const { data = {} } = this.state

    return (
      <>
        <Form>
          <Row className={`${styles.descriptionsRow}`}>
            <Col span={8}>
              <Form.Item label="公司名称(发票抬头)">
                <div className={`${styles.content}`}>{data.merchantName}</div>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="统一社会信用代码">
                <div className={`${styles.content}`}>{data.merchantCreditNo}</div>
              </Form.Item>
            </Col>
          </Row>

          <Row className={`${styles.descriptionsRow}`}>
            <Col span={8}>
              <Form.Item label="所在地区">
                <div className={`${styles.content}`}>{ renderAddressLabel(data.province, data.city, data.county) }</div>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="注册地址">
                <div className={`${styles.content}`}>{ data.address }</div>
              </Form.Item>
            </Col>
          </Row>

          <Row className={`${styles.descriptionsRow}`}>
            <Col span={8}>
              <Form.Item label="开票电话">
                <div className={`${styles.content}`}>{data.mobile}</div>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="开户银行">
                <div className={`${styles.content}`}>{ data.accountBank }</div>
              </Form.Item>
            </Col>
          </Row>

          <Row className={`${styles.descriptionsRow}`}>
            <Col span={8}>
              <Form.Item label="银行账号">
                <div className={`${styles.content}`}>{ data.account }</div>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="银行联行号">
                <div className={`${styles.content}`}>{ data.bankNo }</div>
              </Form.Item>
            </Col>
          </Row>

          <Row className={`${styles.descriptionsRow}`}>
            <Col span={8}>
              <Form.Item label="商户权限">
                <div className={`${styles.content}`}>工聚宝</div>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="商户管理员">
                <div className={`${styles.content}`}>{ data.adminUser }</div>
              </Form.Item>
            </Col>
          </Row>

          <Row className={`${styles.descriptionsRow}`}>
            <Col span={8}>
              <Form.Item label="管理员联系方式">
                <div className={`${styles.content}`}>{ data.adminMobile }</div>
              </Form.Item>
            </Col>
          </Row>

          <Row className={`${styles.descriptionsRow}`}>
            <Col span={8}>
              <Form.Item label="营业执照电子版">
                <div className={styles.thumbWrap}>
                  <div className={styles.thumbInfo}>
                    <a style={{ display: 'block' }} href={data.businessLicenseUrl} target='_blank' rel='noopener noreferrer'>
                      <img src={data.businessLicenseUrl} />
                    </a>
                  </div>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>
    )
  }
}
