import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Row, Col, Card, Button, Icon } from 'antd'
import * as echarts from 'echarts'

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'

import { configMaps } from './localdatas'
import styles from './index.module.scss'

const { downMatchResult } = DownloadApi

class SettingResult extends Component {
  static propTypes = {
    history: PropTypes.object,
    callbackQuery: PropTypes.object
  }

  state = {
    importId: -1, // 匹配流水号
    config: {}, // 配置信息
    info: {}, // 匹配信息
    failList: [], // 匹配失败数据
    configRows: {}
  }

  componentDidMount () {
    const { state = {} } = this.props.history.location

    this.setState({
      importId: state.importId,
      config: state.config,
      configRows: this.findConfigRows(state.config.sheetSourch)
    }, () => {
      this.getExcelAnalysisMatchResult()
    })
  }

  // 获取匹配进度信息
  getExcelAnalysisMatchResult = () => {
    const { importId } = this.state
    const params = {
      importId: importId,
      matchType: 'IMPORT',
      pageNo: 1,
      pageSize: 99999
    }

    Api.getExcelAnalysisMatchResult(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          info: data,
          failList: data.uploadInfoList || []
        }, () => {
          this.initMatchProgressChart()

          if (+data.completeNum < +data.totalNum) {
            setTimeout(() => {
              this.getExcelAnalysisMatchResult()
            }, 3000)
          }
        })
      }
    })
  }

  // 匹配进度
  initMatchProgressChart = () => {
    const { info = {} } = this.state
    const progressChart = echarts.init(document.getElementById('progressEchart'))

    const options = {
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)'
      },
      color: ['#52c41a', '#f5222d'], // 环形颜色
      series: [
        {
          name: '完全匹配率',
          type: 'pie',
          radius: ['60%', '70%'],
          avoidLabelOverlap: false,
          hoverAnimation: false,
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '30',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: [
            { value: info.successNum, name: '匹配正确' },
            { value: info.failNum, name: '匹配错误' }
          ]
        }
      ]
    }

    // 绘制图表
    progressChart.setOption(options)
  }

  // 点击导出
  handleDownloadMatchResult = () => {
    const { importId } = this.state
    downMatchResult({ importId: importId })
  }

  // 跳转到价格设置
  handleJumpToLink = (configRows) => {
    const { callbackQuery = {} } = this.props
    this.props.history.push({ pathname: configRows.linkUrl, state: { ...callbackQuery, activeTabKey: configRows.activeTabKey || '' } })
  }

  findConfigRows = (type) => {
    const row = configMaps.find(item => { return item.code === type })
    const obj = row !== undefined ? row : {}

    return obj
  }

  render () {
    const { configRows = {}, info = {} } = this.state

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '15px' }}>
            <Row>
              <Col span={8} offset={16} style={{ textAlign: 'right' }}>
                <Button type='primary'
                  style={{ marginRight: '12px' }}
                  onClick={() => this.handleDownloadMatchResult()}
                >
                  <Icon type='download' />导出结果
                </Button>

                <Button type='primary' onClick={() => this.handleJumpToLink(configRows)}>
                  返回{configRows.hintText}<Icon type='arrow-right' />
                </Button>
              </Col>
            </Row>
          </Card>
        </div>

        <Row>
          <Card bodyStyle={{ padding: '15px' }}>
            <Row style={{ display: 'flex' }}>
              {/* 匹配图表 */}
              <div className={`${styles.progressWrap}`}>
                <div id='progressEchart' className={`${styles.progressChart}`}
                  style={{ width: '320px', height: '320px' }}
                />
                <div className={`${styles.progressText}`}>
                  <div className={`${styles.progressLabel}`}>完全匹配率</div>
                  <div className={`${styles.progressValue}`}>
                    {(+info.successNum / +info.completeNum * 100).toFixed(2)}%
                  </div>
                </div>
              </div>

              {/* 匹配数据说明 */}
              <div className={`${styles.matchInfo}`}>
                <Row gutter={12} style={{ marginTop: '70px' }}>
                  <Col span={6}>
                    <div className={`${styles.num}`}>{info.successNum}</div>
                    <div className={`${styles.partLabel}`}>个型号{configRows.hintText}成功</div>
                  </Col>

                  <Col span={8}>
                    <div className={`${styles.num} ${styles.redColor}`}>{info.failNum}</div>
                    <div className={`${styles.partLabel}`}>个型号{configRows.hintText}失败</div>
                  </Col>
                </Row>
              </div>
            </Row>
          </Card>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    callbackQuery: state.callbackQuery || {}
  }
}

export default connect(mapStateToProps)(SettingResult)
