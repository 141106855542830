import config from '@/common/utils/config'

const { host } = config

// 上传图片- 不需要水印的图片
const uploadOrdinaryImageUrl = `${host}/api/image/uploadByOthers`

// 上传图片- 需要水印的图片
const uploadNeedLogoImageUrl = `${host}/api/image/uploadByProduct`

// 图文识别- 上传图片
const uploadIntelDiscern = host + '/api/image/text/parse'

// 库存导入
const uploadStockFile = host + '/api/excel/import/importCommodityStock '

// 客户预导入
const beforeuploadEndUser = host + '/api/guest/supplier/endUser/preImport'

// 云供应价格导入
const supplyPriceUpload = host + '/api/excel/import/cloudSupplyPrice'

// 快速下单- 上传产品
const uploadManualSearchSku = host + '/api/just/purchase/search/excelImport'

// EXCEL上传智能匹配sheet
const uploadMatchExcelUrl = host + '/api/excel/import/getSheetNames'

// 订单管理-云采购订单- 上传订单附件
const uploadPurchaseOrderEnclosure = host + '/api/cloud/procurementOrder/file/upload'

// 订单管理-云供应订单- 上传订单附件
const uploadSupplyOrderEnclosure = host + '/api/cloud/supply/order/file/upload'

export default {
  uploadOrdinaryImageUrl,
  uploadNeedLogoImageUrl,
  uploadIntelDiscern,
  uploadStockFile,
  beforeuploadEndUser,
  supplyPriceUpload,
  uploadManualSearchSku,
  uploadMatchExcelUrl,
  uploadPurchaseOrderEnclosure,
  uploadSupplyOrderEnclosure
}
