import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Card, Form, message } from 'antd'
import $lodash from 'lodash'

import CartSteps from '../component/cartSteps/index'
import AddressModal from '@/components/addressModal/index'
import Adress from './address/index' // 收货地址
import InviceHeader from './invoiceHeader' // 发票抬头
import GoodsInfo from './goodsInfo' // 商品信息
import Supper from './supperInfo' // 付款信息
import PayMoeny from './payMoeny' // 实付款

import Api from '@/common/api/index'
import { saveEuKeyMap } from '@/components/addressList/datas'
import styles from './index.module.scss'

const changeEnterParamsKey = (params) => {
  const obj = {}

  $lodash.forEach(params, (value, key) => {
    obj[saveEuKeyMap[key]] = value
  })

  return obj
}

class OrderSubmit extends Component {
  static propTypes = {
    history: PropTypes.object,
    form: PropTypes.object
  }

  state = {
    orderInfo: {}, // 订单信息
    tableDate: {},
    cloudFuturesInfo: {}, // 云期货信息
    endUserId: 0,
    addressList: [],
    addressModal: false,
    addressType: 1,
    selectedAddress: {}, // 选择的收货地址
    bankInfo: {}// 开票信息
  }

  componentDidMount () {
    const { state = {} } = this.props.history.location
    const { data = {} } = state
    const { cloudSpotInfo = {}, cloudFuturesInfo = {} } = data

    cloudSpotInfo.goodsCount = 0
    cloudFuturesInfo.goodsCount = 0

    if (cloudSpotInfo.productList && cloudSpotInfo.productList.length > 0) {
      for (const record of cloudSpotInfo.productList) {
        const { supplyInfoList = [] } = record
        cloudSpotInfo.goodsCount += $lodash.sumBy(supplyInfoList, 'quantity')
      }
    }

    if (cloudFuturesInfo.productList && cloudFuturesInfo.productList.length > 0) {
      for (const record of cloudFuturesInfo.productList) {
        const { supplyInfoList = [] } = record
        cloudFuturesInfo.goodsCount += $lodash.sumBy(supplyInfoList, 'quantity')
      }
    }

    this.setState({
      orderInfo: data,
      tableDate: cloudSpotInfo || {},
      cloudFuturesInfo: cloudFuturesInfo || {}
    }, () => {
      this.getPurchaserEndUserId()
      this.getMerchantBaseInfo()
    })
  }

  // 获取登录人信息
  getPurchaserEndUserId = () => {
    Api.getPurchaserEndUserId({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          endUserId: data.id
        }, () => {
          this.getEndUserReceivedAddressList()
        })
      }
    })
  }

  // 获取收货地址
  getEndUserReceivedAddressList = () => {
    const { endUserId } = this.state
    Api.getEndUserReceivedAddressList({ endUserId: endUserId }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let addressList = []
        if (data && data.length > 0) {
          addressList = $lodash.map(data, (item) => {
            return {
              ...item,
              name: item.receiver,
              mobile: item.phoneNumber,
              status: +item.category === 2 ? 'ENABLE' : 'DISABLE'
            }
          })
        }
        this.setState({
          addressList: addressList,
          selectedAddress: addressList.length > 0 ? addressList[0] : {}
        }, () => {
          setTimeout(() => {
            this.props.form.setFieldsValue({ shippingAddressId: this.state.selectedAddress.id || undefined })
          }, 20)
        })
      }
    })
  }

  // 新增地址
  saveAddEndUserReceiveAddress = (param) => {
    Api.saveAddEndUserReceiveAddress(param).then(res => {
      const { code } = res
      if (+code === 10000) {
        this.getEndUserReceivedAddressList()
        this.onCancel()
      }
    })
  }

  // 更新收货地址
  saveEditEndUserReceiveAddress = async (params) => {
    const res = await Api.saveEditEndUserReceiveAddress(params)
    const { code } = res
    if (+code === 10000) {
      this.getEndUserReceivedAddressList()
      this.onCancel()
    }
  }

  // 获取开票信息
  getMerchantBaseInfo = () => {
    Api.getMerchantBaseInfo({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          bankInfo: data
        })
      }
    })
  }

  // 提交订单
  submitGenerateQuickOrder = async (params) => {
    const res = await Api.submitGenerateQuickOrder(params)
    const { code, data } = res
    if (+code === 10000) {
      const { orderInfo = {} } = this.state
      if (orderInfo.spotAmount + orderInfo.futuresFrontMoney > 0) {
        this.props.history.replace({ pathname: '/purchase/orderPay', state: data })
      } else {
        this.props.history.replace({
          pathname: '/purchase/orderSuccess',
          state: {
            orderId: data.orderId,
            backLinkUrl: '/purchase/quickOrder'
          }
        })
      }
    }
  }

  // 取消订单
  handleGoBack = () => {
    this.props.history.goBack()
  }

  // 打开添加地址
  showAddressModal = (type) => {
    this.setState({
      addressType: type,
      addressModal: true
    })
  }

  // 取消事件
  onCancel =() => {
    this.setState({
      addressModal: false
    })
  }

  // 确定保存地址
  handleConfirmSaveAddress = (values) => {
    const { addressType } = this.state
    const params = changeEnterParamsKey(values)

    params.category = values.status === 'ENABLE' ? 2 : 0

    if (addressType === 1) {
      this.saveAddEndUserReceiveAddress(params)
    } else {
      this.saveEditEndUserReceiveAddress(params)
    }
  }

  // 地址选择
  handleSelectAddress = (id) => {
    const { addressList } = this.state
    const obj = $lodash.find(addressList, item => { return +item.id === +id })

    this.setState({
      selectedAddress: obj || {}
    })
  }

  // 支付订单
  handleCostOrder = () => {
    const { selectedAddress = {}, tableDate = {}, cloudFuturesInfo = {} } = this.state
    if (selectedAddress === undefined || !selectedAddress.id) {
      return message.warning('请填写收货地址')
    }

    const params = {
      shippingAddressId: selectedAddress.id,
      totalAmount: tableDate.totalAmount
    }

    const goodsInfoList = []

    if (tableDate.productList && tableDate.productList.length > 0) {
      tableDate.productList.forEach(item => {
        const paramObj = {
          commoditySkuId: item.commoditySkuId,
          skuGoodsType: item.skuGoodsType,
          quantityInfoList: this.Getitem(item.supplyInfoList)
        }

        goodsInfoList.push(paramObj)
      })
    }

    if (cloudFuturesInfo.productList && cloudFuturesInfo.productList.length > 0) {
      cloudFuturesInfo.productList.forEach((item) => {
        const paramObj = {
          commoditySkuId: item.commoditySkuId,
          skuGoodsType: item.skuGoodsType,
          quantityInfoList: this.Getitem(item.supplyInfoList)
        }

        goodsInfoList.push(paramObj)
      })
    }

    params.goodsInfoList = goodsInfoList
    this.submitGenerateQuickOrder(params)
  }

  Getitem = (supplyInfoList) => {
    const list = []
    supplyInfoList.forEach(item => {
      const obj = {
        cartId: item.cartId,
        supplierId: item.supplierId,
        quantity: item.quantity
      }
      list.push(obj)
    })
    return list
  }

  render () {
    const { orderInfo = {}, tableDate, cloudFuturesInfo = {}, addressType, addressList = [], addressModal = false, endUserId, selectedAddress = {}, bankInfo = {} } = this.state

    return (
      <Card bodyStyle={{ padding: '16px' }} bordered={false}>
        <CartSteps type={2} />

        <div style={{ marginTop: '24px' }}>
          <Adress {...this.props}
            addressList={addressList}
            obj={selectedAddress}
            handleGetOption={(value) => { this.handleSelectAddress(value) }}
            handleGetAddaress={(type) => { this.showAddressModal(type) }}
          />
        </div>

        <InviceHeader bankInfo={bankInfo} />

        <div style={{ marginTop: '24px' }}>
          <h3 className={`${styles.title}`}>商品信息</h3>

          {
            tableDate.productList && tableDate.productList.length > 0
              ? <div style={{ marginBottom: '24px' }}>
                <GoodsInfo orderType='ORDER_CLOUD_STOCK'
                  list={tableDate.productList}
                  goodsCount={tableDate.goodsCount || 0}
                  goodsTotalAmount={tableDate.totalAmount}
                  payAmount={tableDate.totalAmount}
                />
              </div>
              : null
          }

          {
            cloudFuturesInfo.productList && cloudFuturesInfo.productList.length > 0
              ? <div style={{ marginBottom: '24px' }}>
                <GoodsInfo orderType='ORDER_CLOUD_FUTURES'
                  list={cloudFuturesInfo.productList}
                  goodsCount={cloudFuturesInfo.goodsCount || 0}
                  goodsTotalAmount={cloudFuturesInfo.totalAmount}
                  payAmount={cloudFuturesInfo.frontMoney}
                />
              </div>
              : null
          }

          <Supper info={orderInfo} />

          <PayMoeny amount={orderInfo.spotAmount + orderInfo.futuresFrontMoney} obj={selectedAddress} />

          <div className={styles.footBtn}>
            <Button onClick={() => { this.handleGoBack() }}>取消订单</Button>
            <Button style={{ marginLeft: '20px' }} type='danger' onClick={() => { this.handleCostOrder() }}>支付订单</Button>
          </div>

        </div>

        {
          addressModal
            ? <AddressModal
              type={addressType}
              detailInfo={addressType === 2 ? { ...selectedAddress } : {}}
              onCancel={() => { this.onCancel() }}
              onConfirm={(values) => { this.handleConfirmSaveAddress(values) }}
              endUserId={endUserId}
            />
            : null
        }
      </Card>
    )
  }
}

export default Form.create()(OrderSubmit)
