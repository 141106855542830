import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Menu, Dropdown, Breadcrumb, Icon } from 'antd'

import Api from '@/common/api/index'
import { setUserInfo } from '@/reducers/action'
import { removeStore } from '@/common/utils/mUtils'

import { routerMap } from '@/routes/index.js'
import LOGO from '@/assets/images/fixed_header_logo.png'
import styles from './index.module.scss'

function BasicHeader (props) {
  const userInfo = useSelector(state => state.userInfo)
  const dispatch = useDispatch()
  const location = useLocation()

  // 获取登录用户个人信息
  const getLoginUserInfo = async () => {
    const res = await Api.getLoginUserInfo({})
    const { code, data } = res
    if (+code === 10000) {
      dispatch(setUserInfo({ userInfo: data || {} }))
    }
  }

  // 退出登录
  const handleLoginOut = () => {
    dispatch(setUserInfo({ userInfo: {} }))
    removeStore('token')
    props.history.push({ pathname: '/login' })
  }

  const menu = (
    <Menu>
      <Menu.Item>
        <Link to={{ pathname: '/account/basic' }}><Icon type="user" /><span style={{ marginLeft: '8px' }}>个人中心</span></Link>
      </Menu.Item>
      <Menu.Item>
        <a onClick={() => handleLoginOut()}><Icon type="logout" /><span style={{ marginLeft: '8px' }}>退出登录</span></a>
      </Menu.Item>
    </Menu>
  )

  useEffect(() => {
    getLoginUserInfo()
  }, [])

  return (
    <div className={`${styles.globalHeader}`}>
      <div className={`${styles.headerLogo}`}>
        <img className={`${styles.logoPic}`} src={LOGO} /><span>工聚宝</span>
      </div>
      <div className={`${styles.headerContent}`}>
        <div className={`${styles.breadcrumbBox}`}>
          <Breadcrumb separator={<span style={{ color: '#FFFFFF' }}>/</span>}>
            <Breadcrumb.Item>
              <Link to='/home' style={{ color: '#FFFFFF' }}>首页</Link>
            </Breadcrumb.Item>
            {
              routerMap.map((route, i) => {
                return route.path && location.pathname === route.path ? (<Breadcrumb.Item style={{ color: '#FFFFFF' }} key={i}>{route.name}</Breadcrumb.Item>) : null
              })
            }
          </Breadcrumb>
        </div>
      </div>
      <div className={`${styles.headerSpace}`}>
        <Dropdown overlay={menu}>
          <a type='link' className={`${styles.spaceItem}`}>
            <span>{ userInfo && userInfo.username }</span><Icon type="down" style={{ marginLeft: '6px' }} />
          </a>
        </Dropdown>
      </div>
    </div>
  )
}

BasicHeader.propTypes = {
  history: PropTypes.object
}

export default BasicHeader
