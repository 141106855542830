import Ajax from '../utils/ajax'

export default {
  // 快速下单
  getPurChaseBatchSearch (params) {
    return Ajax.post('/api/just/purchase/search', params)
  },

  // 产品纠错
  getPurChaseSearchCloudSkuCorrect (params) {
    return Ajax.post('/api/just/purchase/search/correct', params)
  },

  // 获取云产品详情信息
  getCloudProductDetail (params) {
    return Ajax.get('/api/cloud/procurement/skuPage', { params })
  },

  // 添加到购物车
  saveAddToCart (params) {
    return Ajax.post('/api/just/purchase/shopping/cart/add', params)
  },

  // 获取购物车列表
  getCartSkuList (params) {
    return Ajax.post('/api/just/purchase/shopping/cart/list', params)
  },

  // 购物车删除产品
  saveRemoveSkuToCart (params) {
    return Ajax.post('/api/just/purchase/shopping/cart/remove', params)
  },

  // 订单结算
  getPurchaseOrderPreviewDatas (params) {
    return Ajax.post('/api/just/purchase/order/preview', params)
  },

  // 提交订单
  submitGenerateQuickOrder (params) {
    return Ajax.post('/api/just/purchase/order/generate', params)
  },

  // 使用余额支付
  balancePayPurchOrder (params) {
    return Ajax.post('/api/finance/pay/balancePay', params)
  },

  // 获取线下支付信息
  getOfflinePayInfo (params) {
    return Ajax.get('/api/finance/pay/offlinePayInfo', { params })
  }
}
