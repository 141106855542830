import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Card, Button, Select } from 'antd'

import { renderAddressLabel } from '@/common/utils/mUtils'

import styles from './index.module.scss'
const Option = Select.Option

class AddressForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    addressList: PropTypes.array,
    obj: PropTypes.object,
    handleGetOption: PropTypes.func,
    handleGetAddaress: PropTypes.func
  }

  handleGetOption = (e) => {
    this.props.handleGetOption(e)
  }

  handleGetAddaress =(obj) => {
    this.props.handleGetAddaress(obj)
  }

  render () {
    const { form, addressList = [], obj = {} } = this.props
    const { getFieldDecorator } = form

    return (
      <Card title='地址信息' headStyle={{ padding: '0px', borderBottom: 'none' }} bodyStyle={{ padding: '0px' }} bordered={false}>
        <Form className={styles.adressInfo}>
          <Form.Item className={styles.formSelect} label='收货地址' style={{ marginBottom: '0px' }}>
            {
              getFieldDecorator('shippingAddressId', {
                rules: [
                  { required: true, message: '请选择收货地址!' }
                ]
              })(
                <Select onChange={(value) => { this.handleGetOption(value) }} style={{ minWidth: '500px' }}>
                  {
                    addressList.map((item, i) => (
                      <Option key={`addresOpt-${item.id}-${i}`} value={item.id}>
                        {renderAddressLabel(item.province, item.city, item.county, item.address)} {item.receiver} {item.phoneNumber}
                      </Option>
                    ))
                  }
                </Select>
              )
            }
          </Form.Item>

          <Form.Item style={{ marginBottom: '0px' }}>
            {
              obj && obj.id
                ? <Button style={{ marginLeft: '10px' }} type='primary' onClick={() => { this.handleGetAddaress(2) }}>修改收货地址</Button>
                : null
            }

            <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => { this.handleGetAddaress(1) }}>新增收货地址</Button>
          </Form.Item>
        </Form>
      </Card>
    )
  }
}
const Address = Form.create()(AddressForm)
export default Address
