import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Input, Divider, Button, Tag } from 'antd'
import $lodash from 'lodash'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

import styles from './index.module.scss'

const { skuGoodsTypeMaps } = filter

const correctTitleDescribeMaps = {
  ERROR: '您输入的产品订货号/型号可能有误',
  CHANGE_CHANNEL: '您输入的产品订货号/型号有其他渠道'
}
const skuGoodsTypeClassMaps = {
  SPOT: styles.spotTag,
  FUTURES: styles.futuresTag,
  CLOUD_SPOT: styles.cloudTag,
  CLOUD_FUTURES: styles.cloudTag
}

export default class CorrectModal extends Component {
  constructor (props) {
    super(props)
    this.handleChangeInputSearch = $lodash.debounce(this.handleChangeInputSearch, 300)
  }

  static propTypes = {
    searchResultEnum: PropTypes.string,
    keyword: PropTypes.string,
    correctSource: PropTypes.string, // 纠错来源 ENQUIRE_CORRECT:询价纠错, ORDER_CORRECT:下单纠错
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }

  state = {
    tableData: []
  }

  componentDidMount () {
    const { keyword } = this.props
    this.handleChangeInputSearch(keyword)
  }

  // 下单纠错- 获取可添加的产品列表
  getPurChaseSearchCloudSkuCorrect = async (value) => {
    const res = await Api.getPurChaseSearchCloudSkuCorrect({ keyword: value, pageNo: 1, pageSize: 10 })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.productList || []
      })
    }
  }

  // 获取可添加的产品列表
  getProductListInCorrectEnquireDetails = async (value) => {
    const res = await Api.getProductListInCorrectEnquireDetails({ model: value })
    const { code, data } = res

    if (+code === 10000) {
      this.setState({
        tableData: data || []
      })
    }
  }

  // 监听输入框输入
  handleChangeInputSearch = (value) => {
    const { correctSource } = this.props
    if (correctSource === 'ENQUIRE_CORRECT') {
      this.getProductListInCorrectEnquireDetails(value)
    } else {
      this.getPurChaseSearchCloudSkuCorrect(value)
    }
  }

  // 渲染商品类型
  renderSkuGoodsTypeName = (skuGoodsType) => {
    const obj = skuGoodsTypeMaps.find((item) => { return item.id === skuGoodsType })
    const label = obj !== undefined ? obj.aliasName : ''

    return label
  }

  render () {
    const { searchResultEnum, keyword, correctSource, onCancel, onConfirm } = this.props
    const { tableData = [] } = this.state

    return (
      <>
        <Modal title={correctTitleDescribeMaps[searchResultEnum]} bodyStyle={{ padding: '16px' }}
          width={560}
          visible
          onCancel={() => onCancel()}
          footer={null}
        >
          {
            searchResultEnum === 'ERROR'
              ? <div className={`${styles.searchLand}`}>
                  <Input placeholder='请输入...' autoComplete='off' defaultValue={keyword}
                    onChange={(e) => this.handleChangeInputSearch(e.target.value)}
                  />
                </div>
              : null
          }

          <div className={`${styles.resultLand}`}>
            <ul style={{ marginBottom: '0px', padding: '0px' }}>
              {
                tableData.map((sku, i) => (
                  <li className={`${styles.resultRow}`} key={`waitSelected-${i}`}>
                    <div className={`${styles.resultContainer}`}>
                      <span className={`${styles.brandTag}`}>{sku.brandName}</span>

                      <span className={`${styles.tag} ${skuGoodsTypeClassMaps[sku.skuGoodsType]}`}>{this.renderSkuGoodsTypeName(sku.skuGoodsType)}</span>

                      <span dangerouslySetInnerHTML={{ __html: correctSource === 'ENQUIRE_CORRECT' ? sku.itemNumberHighlight : sku.itemNumber }} />

                      <Divider type='vertical' style={{ backgroundColor: '#999999' }} />

                      <span dangerouslySetInnerHTML={{ __html: correctSource === 'ENQUIRE_CORRECT' ? sku.modelHighlight : sku.model }} />
                    </div>
                    <div className={`${styles.resultBtns}`}>
                      { sku.modelExactMatch || sku.itemNumberExactMatch ? <Tag color='#01AA50'>完全匹配</Tag> : null }
                      <Button type='danger' size='small' onClick={() => onConfirm(sku)}>确定</Button>
                    </div>
                  </li>
                ))
              }
            </ul>
          </div>
        </Modal>
      </>
    )
  }
}
