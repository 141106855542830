import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Button } from 'antd'

import PersonalUesr from '../UesrInfo/index'

import styles from './index.module.scss'

const menuMaps = [
  { id: 'PERSONAL', name: '个人用户' },
  { id: 'ENTERPRISE', name: '企业用户' }
]

export default class CustomerInfo extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  state = {
    activeMenuKey: this.props.history.location.state && +this.props.history.location.state.enterpriseId > 0 ? 'ENTERPRISE' : 'PERSONAL'
  }

  handleChangeMenu = (key) => {
    if (+this.props.history.location.state.type === 1) {
      this.setState({
        activeMenuKey: key
      })
    }
  }

  // 返回客户列表
  handleBack = () => {
    this.props.history.goBack()
  }

  render () {
    const { activeMenuKey } = this.state

    return (
      <>
        <Card bodyStyle={{ padding: '30px' }}>
          <div className={`${styles.topHeader}`}>
            <div className={`${styles.topMenus}`}>
              {
                menuMaps.map((item) => (
                  <div className={`${styles.menuItem} ${item.id === activeMenuKey ? styles.active : ''}`} key={item.id} onClick={() => this.handleChangeMenu(item.id)}>{ item.name }</div>
                ))
              }
            </div>

            <Button type='primary' onClick={() => this.handleBack()}>返回列表</Button>
          </div>

          <PersonalUesr menuKey={activeMenuKey} />
        </Card>
      </>
    )
  }
}
