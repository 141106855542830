import React, { Component } from 'react'
import { Card, Table, Row, message, Button } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/tool-form/index'
import FuturesInModal from './futuresInModal/index'
import PrepareSendModal from './prepareSendModal/index'
import DelayModal from '../component/delaySendModal/index'

import Api from '@/common/api'
import filter from '@/common/utils/filter'
import consts from '@/common/utils/consts'
import { renderOrderPaymentStatusLabel, renderOrderArriverStatusLabel, renderOrderTypeLabel } from '@/common/utils/mUtils'

import layoutStyles from '@/components/basicLayout/index.module.scss'
import styles from './index.module.scss'

const { pageSizeOptions, shipmentUtilMap, orderPaymentStatusMaps, orderArriverStatusMaps } = filter
const { SERIALWIDTH } = consts

// 列表-行className
const rowClassNamesMaps = [
  { key: 'LEVEL_1', value: styles.LEVEL_1 },
  { key: 'LEVEL_2', value: styles.LEVEL_2 }
]

export default class FuturesIn extends Component {
  static propTypes = {}
  state = {
    futuresModal: false,
    delayModal: false,
    prepareModal: false, // 分批发货弹窗
    modaltype: 1,
    pages: { total: 100, pageNo: 1, pageSize: 20 },
    searchData: [
      {
        name: 'orderTime',
        formType: 'RangePicker',
        itemParams: {
          label: '订单日期'
        }
      },
      {
        name: 'orderId',
        formType: 'Input',
        itemParams: {
          label: '订单编号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'stockStatus',
        formType: 'Select',
        itemParams: {
          label: '订货状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...orderArriverStatusMaps
        ]
      },
      {
        name: 'paymentStatus',
        formType: 'Select',
        itemParams: {
          label: '付款状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...orderPaymentStatusMaps
        ]
      }
    ],
    query: {},
    tableData: [],
    expandedRowKeys: [], // 控制展开的行
    rowDetail: {} // 当前选择的行数据
  }

  columns = [
    {
      title: '序号',
      key: 'futuresInCol-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '订单类型',
      key: 'futuresInCol-1',
      render: (text, record, index) => (
        <>{ renderOrderTypeLabel(record.orderType)}</>
      )
    },
    {
      title: '订单日期',
      key: 'futuresInCol-2',
      render: (text, record, index) => (
        <>
          { record.orderTime ? moment(record.orderTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </>
      )
    },
    {
      title: '订单编号',
      key: 'futuresInCol-3',
      dataIndex: 'orderId'
    },
    {
      title: '客户名称',
      key: 'futuresInCol-4',
      dataIndex: 'customerName'
    },
    {
      title: '订单金额',
      key: 'futuresInCol-5',
      render: (text, record, index) => (
        <>&yen;{(+record.payableAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '已付金额',
      key: 'futuresInCol-6',
      dataIndex: 'paidAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.paidAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '未付金额',
      key: 'futuresInCol-7',
      render: (text, record, index) => (
        <>
          &yen;{((+record.payableAmount) - (record.paidAmount) * 100 / 100).toFixed(2)}
        </>
      )
    },
    {
      title: '付款状态',
      key: 'futuresInCol-8',
      render: (text, record, index) => (
        <>{ renderOrderPaymentStatusLabel(record.paymentStatus) }</>
      )
    },
    {
      title: '订货状态',
      key: 'futuresInCol-9',
      render: (text, record, index) => (
        <>{ renderOrderArriverStatusLabel(record.stockStatus) }</>
      )
    },
    {
      title: '操作',
      key: 'futuresInCol-10',
      render: (text, record, index) => (
        <>
          {
            record.stockStatus === 'ARRIVED'
              ? null
              : <>
                <Button type='primary' size='small' onClick={() => this.showFuturesInModal(record)}>入库</Button>

                <Button type='primary' size='small' style={{ marginLeft: '8px' }} onClick={() => this.showPrepareSendModal(record)}>分批发货</Button>
              </>
          }

        </>
      )
    }
  ]

  componentDidMount () {
    // this.getGuesteuUserLike()
    this.getStockFuturesOut()
  }

  // 获取云期货订单列表
  getStockFuturesOut = () => {
    const { pages, query } = this.state

    const params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getStockFuturesOut(params).then(res => {
      const { code, data } = res

      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          expandedRowKeys: [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 获取云期货供应订单产品详情
  getCloudfuturesOrderProductDetails = async (expanded, record) => {
    if (!expanded) { return false }

    const res = await Api.getCloudfuturesOrderProductDetails({ orderId: record.orderId, purchaseOrderId: record.outboundOrderNo, pageNo: 1, pageSize: 999 })
    if (+res.code === 10000) {
      const { tableData } = this.state
      const i = tableData.findIndex((item) => { return item.orderId === record.orderId })
      tableData[i].batchList = res.data.list || []

      this.setState({
        tableData
      })
    }
  }

  // 保存云期货订单产品入库
  saveCloudfuturesOrderProductInStock = async (params) => {
    const res = await Api.saveCloudfuturesOrderProductInStock(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
      this.getStockFuturesOut()
    }
  }

  // 保存云期货延期交货
  saveCloudfuturesDelaybackOrder = async (params) => {
    const res = await Api.saveCloudfuturesDelaybackOrder(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
      this.getStockFuturesOut()
    }
  }

  // 保存期货分批发货
  saveFuturesProductPrepareBatchSend = async (params) => {
    const res = await Api.saveFuturesProductPrepareBatchSend(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
      this.getStockFuturesOut()
    }
  }

  // 模糊搜索客户
  getGuesteuUserLike = (val = '') => {
    Api.getGuesteuUserLike({ searchName: val, pageNo: 1, pageSize: 999 }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        const { searchData } = this.state
        searchData[3].options = data.list || []

        this.setState({
          searchData
        })
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    if (search.orderTime) {
      search.beginDate = search.orderTime.length > 0 ? search.orderTime[0].format('YYYY-MM-DD') : ''
      search.endDate = search.orderTime.length > 0 ? search.orderTime[1].format('YYYY-MM-DD') : ''

      delete search.orderTime
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search || {}
    }, () => this.getStockFuturesOut())
  }

  showFuturesInModal = (record = {}) => {
    this.setState({
      futuresModal: true,
      rowDetail: record
    })
  }

  // 点击分批发货
  showPrepareSendModal = (record = {}) => {
    this.setState({
      rowDetail: record,
      prepareModal: true
    })
  }

  closeModal = () => {
    this.setState({
      futuresModal: false,
      prepareModal: false,
      delayModal: false,
      rowDetail: {}
    })
  }

  // 设置行属性(字列表)
  rowClassName = (record, index) => {
    const obj = rowClassNamesMaps.find((item) => { return item.key === record.alarmLevel })
    const classname = obj !== undefined ? obj.value : ''

    return classname
  }

  // 点击展开、收起子列表
  handleChangeExpand = (expanded, record) => {
    const { expandedRowKeys = [] } = this.state

    this.setState({
      expandedRowKeys: expanded ? [...expandedRowKeys, record.orderId] : $lodash.pull(expandedRowKeys, record.orderId)
    }, () => {
      this.getCloudfuturesOrderProductDetails(expanded, record)
    })
  }

  // 渲染展开子节点内容
  renderExpandRow = (row) => {
    const rowColumns = [
      {
        title: '序号',
        key: `${row.orderId}-expandfuturesInCol-0`,
        width: 40,
        align: 'center',
        render: (text, record, index) => (`${index + 1}`)
      },
      {
        title: '品牌',
        key: `${row.orderId}-expandfuturesInCol-1`,
        dataIndex: 'brandName'
      },
      {
        title: '型号',
        key: `${row.orderId}-expandfuturesInCol-2`,
        dataIndex: 'model'
      },
      {
        title: '订货号',
        key: `${row.orderId}-expandfuturesInCol-3`,
        dataIndex: 'itemNumber'
      },
      {
        title: '产品名称',
        key: `${row.orderId}-expandfuturesInCol-4`,
        dataIndex: 'name'
      },
      {
        title: '订单总数量',
        key: `${row.orderId}-expandfuturesInCol-6`,
        dataIndex: 'quantity'
      },
      {
        title: '已订数量',
        key: `${row.orderId}-expandfuturesInCol-7`,
        dataIndex: 'arrivalQuantity'
      },
      {
        title: '待订数量',
        key: `${row.orderId}-expandfuturesInCol-8`,
        dataIndex: 'arrivalQuantity',
        render: (text, record, index) => (
          <>{record.quantity - record.arrivalQuantity }</>
        )
      },
      {
        title: '货期',
        key: `${row.orderId}-expandfuturesInCol-9`,
        dataIndex: 'deliveryPeriodDays',
        render: (text, record, index) => (
          <>{ record.deliveryPeriodDays}{shipmentUtilMap.CLOUD_FUTURES }</>
        )
      },

      {
        title: '交货日期',
        key: `${row.orderId}-expandfuturesInCol-10`,
        dataIndex: 'deliveryPeriodTime',
        render: (text, record, index) => (
          <>
            { record.deliveryPeriodTime ? moment(record.deliveryPeriodTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
          </>
        )
      },
      {
        title: '实际到货日期',
        key: `${row.orderId}-expandfuturesInCol-11`,
        dataIndex: 'actualDeliveryPeriodTime',
        render: (text, record, index) => {
          return (
            record.actualDeliveryPeriodTime && (record.quantity - record.arrivalQuantity) === 0 ? moment(record.actualDeliveryPeriodTime).format('YYYY-MM-DD HH:mm:ss') : '----'
          )
        }
      },
      {
        title: '预计到货日期',
        key: `${row.orderId}-expandfuturesInCol-12`,
        dataIndex: 'expectDeliveryPeriodTime',
        render: (text, record, index) => {
          return (
            record.expectDeliveryPeriodTime ? moment(record.expectDeliveryPeriodTime).format('YYYY-MM-DD HH:mm:ss') : '----'
          )
        }
      },
      {
        title: '操作',
        key: `${row.orderId}-expandfuturesInCol-13`,
        render: (text, record, index) => {
          return (
            <>
              {
                (record.quantity - record.arrivalQuantity) === 0
                  ? ''
                  : <Button onClick={() => { this.handledelayModal({ orderId: row.orderId, orderType: row.orderType, outboundOrderNo: row.outboundOrderNo, ...record }) }} type='primary' size='small'>延期交货</Button>
              }
            </>
          )
        }
      }
    ]

    return (
      <Row style={{ padding: '2px 5px' }}>
        <Table rowKey='commoditySkuId'
          rowClassName={this.rowClassName}
          columns={rowColumns}
          dataSource={row.batchList}
          pagination={false}
        />
      </Row>
    )
  }

  handledelayModal=(record) => {
    this.setState({
      delayModal: true,
      rowDetail: record
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getStockFuturesOut()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getStockFuturesOut()
    })
  }

  render () {
    const { tableData, expandedRowKeys = [], delayModal, pages, searchData, futuresModal = false, prepareModal = false, rowDetail = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div className={`${layoutStyles.themeSearch}`} style={{ marginBottom: '20px' }}>
          <SearchForm {...this.props}
            hasBackground
            dataSource={searchData}
            handleSubmit={(obj) => this.handleSearch(obj)}
          />
        </div>

        <Card bodyStyle={{ padding: '30px 50px' }}>
          <div className={styles.show}>
            <div className={`${styles.wh} ${styles.bgIn}`} />
            临近超期商品（7天）
            <div className={`${styles.wh} ${styles.bgOut}`} />
            已超期商品
          </div>

          <Table rowKey='orderId'
            columns={this.columns}
            dataSource={tableData}
            pagination={pagination}
            rowClassName={this.rowClassName}
            expandedRowKeys={expandedRowKeys}
            onExpand={(expanded, record) => this.handleChangeExpand(expanded, record)}
            expandedRowRender={(record) => this.renderExpandRow(record)}
          />
        </Card>

        {
          futuresModal
            ? <FuturesInModal
              detailInfo={rowDetail}
              onConfirm={(params) => this.saveCloudfuturesOrderProductInStock(params)}
              onCancel={this.closeModal}
            />
            : null
        }
        {
          delayModal
            ? <DelayModal
              detailInfo={rowDetail}
              onCancel={this.closeModal}
              onConfirm={(params) => this.saveCloudfuturesDelaybackOrder(params)}
            />
            : null
        }

        {
          prepareModal
            ? <PrepareSendModal
              detailInfo={rowDetail}
              onConfirm={(params) => this.saveFuturesProductPrepareBatchSend(params)}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}
