import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Card } from 'antd'

import SupplyOrder from '../supplyOrder/index'
import PurchaseOrder from '../purchaseOrder/index'

import styles from './index.module.scss'

const menuMaps = [
  { id: 'SUPPLY_ORDER', name: '云供应订单' },
  { id: 'PURCHASE_ORDER', name: '云采购订单' }
]

export default function OrderList (props) {
  const { history } = props
  const [activeKey, setActiveKey] = useState('SUPPLY_ORDER')

  return (
    <Card bodyStyle={{ padding: '30px' }}>
      <div className={`${styles.topMenus}`} style={{ marginBottom: '30px' }}>
        {
          menuMaps.map((item) => (
            <div key={item.id} className={`${styles.menuItem} ${item.id === activeKey ? styles.active : ''}`} onClick={() => setActiveKey(item.id)}>
              { item.name }
            </div>
          ))
        }
      </div>

      {
        activeKey === 'SUPPLY_ORDER'
          ? <SupplyOrder history={history} />
          : null
      }

      {
        activeKey === 'PURCHASE_ORDER'
          ? <PurchaseOrder history={history} />
          : null
      }
    </Card>
  )
}

OrderList.propTypes = {
  history: PropTypes.object
}
