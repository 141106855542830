import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'antd'

export default function DetailModal (props) {
  const { onCancel, content = '' } = props

  return (
    <Modal title='询价内容'
      visible
      maskClosable={false}
      onCancel={() => onCancel()}
      footer={[
        <Button key='cancel-btn' onClick={() => onCancel()}>关闭</Button>
      ]}
    >
      <p dangerouslySetInnerHTML={{ __html: content }} />
    </Modal>
  )
}

DetailModal.propTypes = {
  onCancel: PropTypes.func,
  content: PropTypes.string
}
