import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Row, Col, Tooltip, Button } from 'antd'
import $lodash from 'lodash'

import InvoiceAccount from '@/components/invoiceAccount/index'
import InvoiceProduct from '@/components/invoiceProduct/index'

import Api from '@/common/api/index'
import { renderInvoiceTypeLabel, renderAddressLabel } from '@/common/utils/mUtils'
import styles from '../../unopenTable/invoiceModal/index.module.scss'

const FormItem = Form.Item

class RecordsModal extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func
  }

  state = {
    invoiceInfo: {}, // 发票抬头信息
    tableData: [], // 发票商品信息
    totalAmount: 0, // 发票税价合计
    receivingAddress: {}, // 收票地址信息
    invoiceVoucherInfo: {} // 发票凭证信息
  }

  componentDidMount () {
    this.getPurchaseOrderInvoiceOpenedDetails()
  }

  // 获取开票完成详情信息
  getPurchaseOrderInvoiceOpenedDetails = async () => {
    const { detailInfo = {} } = this.props
    const res = await Api.getPurchaseOrderInvoiceOpenedDetails({ id: detailInfo.invoiceRecordId })
    const { code, data = {} } = res

    if (+code === 10000) {
      this.setState({
        tableData: data.orderGoodsListMap || [],
        totalAmount: data.orderGoodsListMap && data.orderGoodsListMap.length > 0 ? $lodash.sumBy(data.orderGoodsListMap, 'totalPrice') : 0,
        invoiceInfo: data.invoiceRecordInfo || {},
        receivingAddress: data.invoiceRecordReceiverAddress || {},
        invoiceVoucherInfo: data.merchantInvoiceRecord || {}
      })
    }
  }

  render () {
    const { detailInfo = {}, onCancel } = this.props
    const { invoiceInfo = {}, invoiceVoucherInfo = {}, tableData = [], totalAmount = 0, receivingAddress = {} } = this.state

    return (
      <>
        <Modal title='查看详情' bodyStyle={{ padding: '15px' }} width={1000}
          visible
          maskClosable={false}
          onCancel={() => onCancel()}
          footer={[<Button key='recordsBtn-0' onClick={() => onCancel()}>关闭</Button>]}
        >
          <Form>
            <Row gutter={24}>
              <Col span={24}>
                <div className={`${styles.formitemRow}`}>
                  <FormItem label='发票类型' style={{ marginBottom: '0px' }}>
                    { detailInfo.invoiceType && detailInfo.invoiceType !== '' ? renderInvoiceTypeLabel(detailInfo.invoiceType) : '----' }
                  </FormItem>
                </div>
              </Col>
              <Col span={12}>
                <div className={`${styles.formitemRow}`}>
                  <FormItem label='发票号码' style={{ marginBottom: '0px' }}>{ invoiceVoucherInfo.invoiceCode }</FormItem>
                </div>
              </Col>
              <Col span={12}>
                <div style={{ height: '40px', lineHeight: '40px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  <label className='label'>发票图片</label>
                  {
                      invoiceVoucherInfo.invoiceImageUrl && invoiceVoucherInfo.invoiceImageUrl !== ''
                        ? <Tooltip placement='topLeft' title='点击可查看详情'>
                            <a href={invoiceVoucherInfo.invoiceImageUrl} target='_blank' rel="noopener noreferer noreferrer">{invoiceVoucherInfo.invoiceImageUrl}</a>
                          </Tooltip>
                        : '----'
                    }
                </div>
              </Col>
            </Row>

            <div style={{ marginTop: '16px' }}>
              <InvoiceAccount
                title={invoiceInfo.invoiceTitle}
                creditNo={invoiceInfo.creditNo}
                tel={invoiceInfo.tel}
                bankName={invoiceInfo.bankName}
                bankCard={invoiceInfo.bankCard}
                province={invoiceInfo.province}
                city={invoiceInfo.city}
                county={invoiceInfo.county}
                address={invoiceInfo.address}
              />
            </div>

            <div style={{ marginTop: '16px' }}>
              <InvoiceProduct
                list={tableData}
                totalAmount={totalAmount}
              />
            </div>

            <div className={`${styles.formitemRow}`} style={{ marginTop: '16px' }}>
              <FormItem label='收件地址'>{renderAddressLabel(receivingAddress.province, receivingAddress.city, receivingAddress.county, receivingAddress.address)} {receivingAddress.name} {receivingAddress.mobile}</FormItem>
            </div>
          </Form>
        </Modal>
      </>
    )
  }
}

export default RecordsModal
