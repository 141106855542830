import React, { Component } from 'react'
import { Table, Button } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/tool-form/index'
import PurchaseDrawer from './purchaseDrawer/index'
import SaledProgressModal from './saledProgressModal/index'
import LogisticsModal from '@/components/logisticsModal/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import consts from '@/common/utils/consts'
import { renderSaledTypeLabel, renderSaledStatusLabel } from '@/common/utils/mUtils'

const { SERIALWIDTH } = consts
const { pageSizeOptions, saledTypeMaps, saledStatusMaps } = filter

export default class PurchaseSaled extends Component {
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'times',
        formType: 'RangePicker',
        itemParams: {
          label: '申请时间'
        },
        cptParams: {}
      },
      {
        name: 'id',
        formType: 'Input',
        itemParams: {
          label: '售后单号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'afterSaleOrderType',
        formType: 'Select',
        itemParams: {
          label: '售后类型'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...saledTypeMaps
        ]
      },
      {
        name: 'orderId',
        formType: 'Input',
        itemParams: {
          label: '订单编号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'afterSaleOrderStatus',
        formType: 'Select',
        itemParams: {
          label: '售后状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...saledStatusMaps
        ]
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: [],
    rowDetail: {},
    rowCourierNums: [],
    detailsModal: false, // 详情弹窗
    logisticsModal: false, // 物流弹窗
    progressModal: false // 售后进度弹窗
  }

  columns = [
    {
      title: '序号',
      key: 'purchaseSaledCol-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '申请时间',
      key: 'purchaseSaledCol-1',
      dataIndex: 'createTime',
      render: (text, record, index) => (
        <>
          { record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </>
      )
    },
    {
      title: '售后单号',
      key: 'purchaseSaledCol-2',
      dataIndex: 'id'
    },
    {
      title: '售后类型',
      key: 'purchaseSaledCol-3',
      dataIndex: 'type',
      render: (text, record, index) => (
        <>{ renderSaledTypeLabel(record.type) }</>
      )
    },
    {
      title: '订单编号',
      key: 'purchaseSaledCol-4',
      dataIndex: 'orderId'
    },
    {
      title: '售后进度',
      key: 'purchaseSaledCol-5',
      render: (text, record, index) => (
        <a onClick={() => this.showProgressModal(record)}>点击查看</a>
      )
    },
    {
      title: '售后状态',
      key: 'purchaseSaledCol-6',
      dataIndex: 'status',
      render: (text, record, index) => (
        <>{ renderSaledStatusLabel(record.status) }</>
      )
    },
    {
      title: '操作',
      key: 'purchaseSaledCol-7',
      width: 200,
      render: (text, record, index) => (
        <>
          <Button type='link' size='small' onClick={() => this.showDetailsModal(record)}>查看详情</Button>

          {
            ['REFUND_RETURN_PRODUCTS', 'CHANGE_PRODUCTS', 'ADD_DELIVER'].includes(record.type) && record.status !== 'WAITING' && record.status !== 'REJECT'
              ? <Button type='link' size='small' onClick={() => this.showLogisticsModal(record)}>查看物流</Button>
              : null
          }

        </>
      )
    }
  ]

  componentDidMount () {
    this.getPurchaseOrderSaledList()
  }

  // 获取云采购订单售后申请列表
  getPurchaseOrderSaledList = async () => {
    const { pages, query = {} } = this.state
    const res = await Api.getPurchaseOrderSaledList({ ...query, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    if (search.times) {
      search.beginDate = search.times.length === 2 ? search.times[0].format('YYYY-MM-DD') : undefined
      search.endDate = search.times.length === 2 ? search.times[1].format('YYYY-MM-DD') : undefined

      delete search.times
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search
    }, () => {
      this.getPurchaseOrderSaledList()
    })
  }

  // 点击查看详情
  showDetailsModal = (record) => {
    this.setState({
      rowDetail: record,
      detailsModal: true
    })
  }

  // 点击查看进度
  showProgressModal = (record) => {
    this.setState({
      rowDetail: record,
      progressModal: true
    })
  }

  // 点击查看物流
  showLogisticsModal = (record) => {
    const { backCourierCode, backCourierNumber, deliverCourierCode, deliverCourierNumber } = record
    const courierNumberList = []

    if (backCourierNumber && backCourierNumber.length > 0) {
      const backnums = backCourierNumber.split(',')

      $lodash.forEach(backnums, (o) => {
        courierNumberList.push({
          courierCode: backCourierCode,
          courierNumber: o
        })
      })
    }

    if (deliverCourierNumber && deliverCourierNumber.length > 0) {
      const nums = deliverCourierNumber.split(',')

      $lodash.forEach(nums, (o) => {
        courierNumberList.push({
          courierCode: deliverCourierCode,
          courierNumber: o
        })
      })
    }

    this.setState({
      rowCourierNums: courierNumberList,
      logisticsModal: true
    })
  }

  closeModal = () => {
    this.setState({
      detailsModal: false,
      logisticsModal: false,
      progressModal: false,
      rowDetail: {},
      rowCourierNums: []
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getPurchaseOrderSaledList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getPurchaseOrderSaledList()
    })
  }

  render () {
    const { searchData = [], pages, tableData = [], rowDetail = {}, rowCourierNums = [], detailsModal = false, logisticsModal = false, progressModal = false } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '30px' }}>
          <SearchForm
            dataSource={searchData}
            handleSubmit={(obj) => this.handleSearch(obj)}
          />
        </div>

        <Table rowKey='id'
          dataSource={tableData}
          columns={this.columns}
          pagination={pagination}
        />

        <PurchaseDrawer visible={detailsModal} detailInfo={rowDetail} onCancel={() => this.closeModal()} />

        { // 物流轨迹弹窗
          logisticsModal
            ? <LogisticsModal
              courierNumberList={rowCourierNums}
              onCancel={() => this.closeModal()}
            />
            : null
        }

        { // 售后进度弹窗
          progressModal
            ? <SaledProgressModal
              detailInfo={rowDetail}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}
