import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Button, Empty, Card, Icon, Descriptions, message, Modal } from 'antd'
import $lodash from 'lodash'

import AddressModal from '@/components/addressModal/index'

import Api from '@/common/api/index'
import { saveEuKeyMap } from '@/components/addressList/datas'
import { renderAddressLabel } from '@/common/utils/mUtils'
import consts from '@/common/utils/consts'

import styles from './index.module.scss'

const { ADDRESS_MAX_NUM } = consts

const changeEnterParamsKey = (params) => {
  const obj = {}

  $lodash.forEach(params, (value, key) => {
    obj[saveEuKeyMap[key]] = value
  })

  return obj
}

class CustomerAddressDetail extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  state = {
    endUserId: 0,
    tableData: [],
    editModal: false,
    rowDetail: {}
  }

  componentDidMount () {
    const { record = {} } = this.props.history.location.state
    this.setState({
      endUserId: record.endUser.id
    }, () => {
      this.getEndUserReceivedAddressList()
    })
  }

  // 查看地址
  getEndUserReceivedAddressList = () => {
    const { endUserId } = this.state
    Api.getEndUserReceivedAddressList({ endUserId }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data
        })
      } else {
        message.error(res.message)
      }
    })
  }

  // 返回客户列表
  handleBack = () => {
    this.props.history.goBack()
  }

  // 渲染DESC title
  renderCardItemTitle = (record) => {
    return (
      <>
        <span>{record.name}</span>
        {
          record.status && record.category === '2'
            ? <><span>{record.receiver}</span> <span className={`${styles.defaultLabel}`}>默认地址</span></>
            : <span>{record.receiver}</span>
        }
      </>
    )
  }

  // 点击新增/编辑
  handleShowEditModal = (record, type) => {
    this.setState({
      type: type,
      editModal: true,
      rowDetail: { ...record, name: record.receiver, mobile: record.phoneNumber }
    })
  }

  closeModal = () => {
    this.setState({
      type: 1,
      editModal: false,
      rowDetail: {}
    })
  }

  // 确定保存地址
  handleConfirmSaveAddress = (values) => {
    const { type } = this.state

    const params = changeEnterParamsKey(values)
    params.category = values.status === 'ENABLE' ? 2 : 0

    if (type === 1) {
      this.saveAddEndUserReceiveAddress(params)
    } else {
      this.saveEditEndUserReceiveAddress(params)
    }
  }

  // 保存新增收件地址
  saveAddEndUserReceiveAddress = (params) => {
    const { record } = this.props.history.location.state
    Api.saveAddEndUserReceiveAddress(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getEndUserReceivedAddressList(record)
      }
    })
  }

  // 保存编辑收件地址
  saveEditEndUserReceiveAddress = (params) => {
    const { record } = this.props.history.location.state
    Api.saveEditEndUserReceiveAddress(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getEndUserReceivedAddressList(record)
      }
    })
  }

  // 点击删除地址
  handleDeleteAddress = (item) => {
    Modal.confirm({
      title: '确定删除该地址？',
      content: '',
      onOk: () => {
        this.deleteEndUserReceivedAddress({ endUserId: item.endUserId, id: item.id, category: 0 })
      }
    })
  }

  // 删除用户收货地址
  deleteEndUserReceivedAddress = (params) => {
    const { record } = this.state
    Api.deleteEndUserReceivedAddress(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getEndUserReceivedAddressList(record)
      } else {
        message.error(res.message)
      }
    })
  }

  render () {
    const { endUserId = 0, tableData, editModal = false, rowDetail = {}, type = 1 } = this.state
    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Button icon='left' onClick={() => this.handleBack()}>
            返回客户列表
          </Button>
        </Row>

        <Row>
          <Card title='地址详情'>
            <Row style={{ marginBottom: '15px' }}>
              <Button type='primary' disabled={tableData.length >= ADDRESS_MAX_NUM} onClick={() => this.handleShowEditModal({}, 1)}>
                <Icon type='plus' />新增地址
              </Button>
              <span style={{ marginLeft: '20px' }}>
                已创建{tableData.length}个收货地址，最多可创建{ADDRESS_MAX_NUM}个
              </span>
            </Row>
            {tableData && tableData.length > 0
              ? <>
                  {
                    tableData.map((item, index) => (
                      <Row key={`address-${item.id}`} className={ `${styles.addressRow}  ${item.category === '2' ? styles.ischeck : styles.nocheck}`}>
                        <Row>
                          <Descriptions title={this.renderCardItemTitle(item)}>
                            <Descriptions.Item label='收货人'>{item.receiver}</Descriptions.Item>
                            <Descriptions.Item label='手机号码'>
                              {item.phoneNumber}
                            </Descriptions.Item>
                            <Descriptions.Item label='所在地区'>
                              {renderAddressLabel(item.province, item.city, item.county)}
                            </Descriptions.Item>
                            <Descriptions.Item label='详细地址'>
                              {item.address}
                            </Descriptions.Item>
                          </Descriptions>
                          <Row className={`${styles.extraRow}`}>
                            <a className={`${styles.extraItem}`}
                              onClick={() => this.handleShowEditModal(item, 2)}
                            > 编辑</a>

                            <a className={`${styles.extraItem}`}
                              onClick={() => this.handleDeleteAddress(item)}
                            >删除</a>

                          </Row>
                        </Row>
                      </Row>
                    ))
                  }
              </>
              : <Empty />}
          </Card>
        </Row>

        {
          editModal
            ? <AddressModal
              type={type}
              endUserId={endUserId}
              detailInfo={rowDetail}
              onConfirm={(params) => this.handleConfirmSaveAddress(params)}
              onCancel={this.closeModal}
            />
            : null
        }
      </>
    )
  }
}

export default CustomerAddressDetail
