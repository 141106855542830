import homeRouter from './homeRouter'
import accountRouter from './accountRouter'
import customerRouter from './customerRouter'
import financeRouter from './financeRouter'
import supplyRouter from './supplyRouter'
import purchaseRouter from './purchaseRouter'
import warehouseRouter from './warehouseRouter'
import matchsRouter from './matchsRouter'
import orderRouter from './orderRouter'
import enquireRouter from './enquireRouter'

export const routerMap = [
  ...homeRouter,
  ...accountRouter,
  ...customerRouter,
  ...financeRouter,
  ...supplyRouter,
  ...purchaseRouter,
  ...warehouseRouter,
  ...matchsRouter,
  ...orderRouter,
  ...enquireRouter
]
