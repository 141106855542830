import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Table, Button, Modal, message } from 'antd'
import $lodash from 'lodash'

import PreviewTable from './previewTable'
import ProductModal from './productModal'

import Api from '@/common/api/index'
import consts from '@/common/utils/consts'
const { SERIALWIDTH } = consts

const matchingStatusMap = [
  { id: 'MATCH_SUCCESS', name: '成功', color: '#52C41A' },
  { id: 'MATCH_FAILED', name: '失败', color: '#F5222D' },
  { id: 'MATCHING', name: '待询价', color: '#FAAD14' }
]

class PrepareQuotedDetails extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  state = {
    predictQuotationOrderId: null,
    detalisList: [],
    resultList: [],
    successNum: 0,
    failNum: 0,
    unMatchNum: 0,
    customerMobile: null,
    selectedRowKeys: [],
    selectedRows: [],
    productModal: false,
    rowDetail: {}
  }

  columns = [
    {
      title: '序号',
      key: 'quotedTableCol-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'quotedTableCol-1',
      dataIndex: 'brandName'
    },
    {
      title: '型号/订货号',
      key: 'quotedTableCol-2',
      dataIndex: 'itemNumber'
    },
    {
      title: '数量',
      key: 'quotedTableCol-3',
      dataIndex: 'quantity'
    },
    {
      title: '价格',
      key: 'quotedTableCol-4',
      dataIndex: 'price'
    },
    {
      title: '报价结果',
      key: 'quotedTableCol-5',
      dataIndex: 'matchingStatus',
      render: (text, record, index) => (
        <>{ this.renderStatusLabel(record.matchingStatus) }</>
      )
    },
    {
      title: '备注',
      key: 'quotedTableCol-6',
      dataIndex: 'remarks'
    },
    {
      title: '操作',
      key: 'quotedTableCol-7',
      render: (text, record, index) => (
        <>
          <Button type='link' size='small' onClick={() => this.showProductModal(record)}>修正产品</Button>
        </>
      )
    }
  ]

  componentDidMount () {
    const { state = {} } = this.props.history.location
    this.setState({
      predictQuotationOrderId: state.predictQuotationOrderId
    }, () => {
      this.getPrepareQuotationsDetails()
    })
  }

  // 获取预报价单详情
  getPrepareQuotationsDetails = async () => {
    const { predictQuotationOrderId } = this.state
    const res = await Api.getPrepareQuotationsDetails({ predictQuotationOrderId: predictQuotationOrderId })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        detailList: data.detailList || [],
        resultList: data.resultList || [],
        successNum: data.successNum || 0,
        failNum: data.failedNum || 0,
        unMatchNum: data.unMatchNum || 0,
        customerMobile: data.customerMobile || null
      })
    }
  }

  saveAddEnquireOrder = async (params) => {
    const res = await Api.saveAddEnquireOrder(params)
    const { code } = res
    if (+code === 10000) {
      Modal.success({
        title: '操作成功',
        content: '请到我的询价中查看具体进度'
      })
    }
  }

  // 询价单-预报价单- 询价详情修正结果更新
  updateCorrectPrepareEnquireOrderDetails = async (params) => {
    const res = await Api.updateCorrectPrepareEnquireOrderDetails(params)
    const { code } = res
    if (+code === 10000) {
      this.setState({
        productModal: false,
        rowDetail: {}
      }, () => {
        this.countDown()
      })
    }
  }

  // 点击一键询价
  handleEnquiry = () => {
    const { predictQuotationOrderId, selectedRows = [] } = this.state
    if (selectedRows.length === 0) {
      return message.warning('请选择要询价的产品~~')
    }

    const newdatas = $lodash.map(selectedRows, (record) => {
      return {
        ...record,
        expectDeliveryPeriodDays: record.deliveryPeriodDays,
        expectPrice: record.price,
        matchingState: 'MATCHED',
        dataType: 'SEARCH'
      }
    })

    const params = {
      predictQuotationOrderId: predictQuotationOrderId,
      enquiryOrderDetails: newdatas,
      enquiryType: 'MERCHANT_PRE_QUOTATION',
      enquiryState: 'NO',
      quotationNumber: 1
    }

    this.saveAddEnquireOrder(params)
  }

  // 监听列表选择
  handleSelectedChange = (keys, rows) => {
    this.setState({
      selectedRowKeys: keys,
      selectedRows: rows
    })
  }

  // 点击修正产品
  showProductModal = (record) => {
    this.setState({
      rowDetail: record,
      productModal: true
    })
  }

  // 倒计时关闭成功提示弹窗
  countDown = () => {
    let secondsToGo = 3
    const modal = Modal.success({
      title: '操作成功',
      okButtonProps: { disabled: secondsToGo > 0 },
      onOk: () => {
        clearInterval(timer)
        clearTimeout(outTimer)
        modal.destroy()

        this.getPrepareQuotationsDetails()
      }
    })

    const timer = setInterval(() => {
      secondsToGo -= 1

      modal.update({
        okButtonProps: { disabled: secondsToGo > 0 }
      })
    }, 1000)

    const outTimer = setTimeout(() => {
      clearInterval(timer)
      modal.destroy()
      this.getPrepareQuotationsDetails()
    }, secondsToGo * 1000)
  }

  renderStatusLabel = (status) => {
    const obj = matchingStatusMap.find((item) => { return item.id === status })
    const label = obj !== undefined ? <span style={{ color: obj.color }}>{ obj.name }</span> : null

    return label
  }

  closeModal = () => {
    this.setState({
      rowDetail: {},
      productModal: false
    })
  }

  render () {
    const {
      detailList = [], resultList = [], selectedRowKeys = [], successNum = 0, failNum = 0, unMatchNum = 0, customerMobile = null,
      productModal = false, rowDetail = {}
    } = this.state

    const rowSelection = {
      columnWidth: 44,
      selectedRowKeys: selectedRowKeys,
      onChange: this.handleSelectedChange,
      getCheckboxProps: record => ({
        disabled: record.matchingStatus === 'MATCH_FAILED'
      })
    }

    return (
      <>
        <Card bodyStyle={{ padding: '0px 16px 16px 16px' }}>
          <div style={{ marginBottom: '16px' }}>
            <Card title='预报价单预览' headStyle={{ padding: '0px' }} bodyStyle={{ padding: '0px' }} bordered={false}>
              <PreviewTable list={detailList} />
            </Card>
          </div>

          <Card title='报价结果' headStyle={{ padding: '0px', borderBottom: 'none' }} bodyStyle={{ padding: '0px' }} bordered={false}>
            <div style={{ marginBottom: '16px' }}>
              <div style={{ marginBottom: '16px' }}>
                总计为您报价成功&nbsp;{ successNum }&nbsp;个商品，报价失败&nbsp;{ failNum }&nbsp;个商品，请检查型号/订货号数据是否正确。待询价&nbsp;{ unMatchNum }&nbsp;个商品，请联系您的专属客服或拨打{ customerMobile }获取更多报价。
              </div>
              <Button type='primary' onClick={() => this.handleEnquiry()}>一键询价</Button>
            </div>

            <Table rowKey='id' bordered
              columns={this.columns}
              dataSource={resultList}
              rowSelection={rowSelection}
              pagination={false}
            />
          </Card>
        </Card>

        {
          productModal
            ? <ProductModal
                detailInfo={rowDetail}
                onConfirm={(params) => this.updateCorrectPrepareEnquireOrderDetails(params)}
                onCancel={() => this.closeModal()}
              />
            : null
        }
      </>
    )
  }
}

export default PrepareQuotedDetails
