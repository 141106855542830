import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Radio } from 'antd'

import LogisticsTrail from '../logisticsTrail/index'

import Api from '@/common/api/index'
import styles from './index.module.scss'

export default class LogisticsModal extends Component {
  static propTypes = {
    courierNumberList: PropTypes.array, // 运单号集合 数组对象 { courierCode: 'JD', courierNumber: XXXXXXX }
    onCancel: PropTypes.func
  }

  state = {
    courierCode: null,
    courierNumber: null,
    mobile: null,
    logisticsTraces: []
  }

  componentDidMount () {
    const { courierNumberList = [] } = this.props
    if (courierNumberList.length > 0) {
      const record = courierNumberList[0]

      this.handleSelectedCourierNumber(`${record.courierCode}-${record.courierNumber}`)
    }
  }

  // 获取物流轨迹
  getLogisticsTraceByMailNo = async (courierCode, courierNumber, mobile) => {
    const params = {
      ShipperCode: courierCode,
      LogisticCode: courierNumber,
      CustomerName: mobile && mobile !== '' ? mobile.substr(-4) : ''
    }
    const res = await Api.getLogisticsTraceByMailNo(params)
    const { code, data } = res
    if (+code === 10000) {
      const list = data.traces || []

      this.setState({
        logisticsTraces: list.length > 0 ? list.reverse() : []
      })
    }
  }

  // 点击选择运单号
  handleSelectedCourierNumber = (value) => {
    const { courierNumberList = [] } = this.props
    if (courierNumberList.length === 0) { return false }

    const activedatas = courierNumberList.find((record) => { return `${record.courierCode}-${record.courierNumber}` === value })

    this.setState({
      courierCode: activedatas !== undefined ? activedatas.courierCode : null,
      courierNumber: activedatas !== undefined ? activedatas.courierNumber : null,
      mobile: activedatas !== undefined ? activedatas.mobile : null
    }, () => {
      const { courierCode, courierNumber, mobile } = this.state

      this.getLogisticsTraceByMailNo(courierCode, courierNumber, mobile)
    })
  }

  render () {
    const { courierNumberList = [], onCancel } = this.props
    const { courierCode, courierNumber, logisticsTraces = [] } = this.state

    return (
      <Modal title='物流详情' bodyStyle={{ padding: '16px', maxHeight: '500px', overflowY: 'auto' }} width={800}
        visible
        onCancel={() => onCancel()}
        footer={null}
      >
        <div style={{ marginBottom: '16px' }}>
          <div className={`${styles.standardFormRow}`}>
            <div className={`label ${styles.standardFormRowLabel}`}>运单号</div>
            <div className={`${styles.standardFormRowContent}`}>
              <Radio.Group buttonStyle='solid' value={`${courierCode}-${courierNumber}`}
                onChange={(e) => this.handleSelectedCourierNumber(e.target.value)}
              >
                {
                  courierNumberList.map((item, i) => (
                    <Radio.Button value={`${item.courierCode}-${item.courierNumber}`} key={`${item.courierCode}-${item.courierNumber}`}>
                      {item.courierNumber}
                    </Radio.Button>
                  ))
                }
              </Radio.Group>
            </div>
          </div>
        </div>

        <LogisticsTrail logisticsTraces={logisticsTraces} courierCode={courierCode} />
      </Modal>
    )
  }
}
