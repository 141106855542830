import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Input, InputNumber, message } from 'antd'
import JSEncrypt from 'jsencrypt'
import PublickeyJson from '@/assets/json/publicKey.json'

import filter from '@/common/utils/filter'

const { paywordReg } = filter
const FormItem = Form.Item

const formmItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 }
}

// 提现弹窗
function CashoutForm (props) {
  const { form, totalBalance, onCancel, onConfirm } = props
  const { getFieldDecorator, validateFields } = form

  const handleConfirm = () => {
    validateFields((err, values) => {
      if (!err) {
        const { amount = 0, password } = values
        if (+amount === 0) { return message.warning('提现金额不可为0元!') }

        const jsencrypt = new JSEncrypt()
        jsencrypt.setPublicKey(PublickeyJson[PATH_ENV])
        values.password = jsencrypt.encrypt(password)

        onConfirm(values)
      }
    })
  }

  return (
    <Modal title='余额提现'
      visible
      maskClosable={false}
      onCancel={() => onCancel()}
      onOk={() => handleConfirm()}
    >
      <Form>
        <FormItem label='银行名称' {...formmItemLayout}>
          {
            getFieldDecorator('bank', {
              rules: [
                { required: true, message: '请填写收款银行名称' }
              ]
            })(
              <Input placeholder='请输入收款银行名称...' autoComplete='off' />
            )
          }
        </FormItem>

        <FormItem label='银行卡号' {...formmItemLayout}>
          {
            getFieldDecorator('account', {
              rules: [
                { required: true, message: '请填写收款银行卡号' }
              ]
            })(
              <Input placeholder='请输入收款银行卡号...' autoComplete='off' />
            )
          }
        </FormItem>

        <FormItem label='账户名称' {...formmItemLayout}>
          {
            getFieldDecorator('accountName', {
              rules: [
                { required: true, message: '请填写收款银行账户名称' }
              ]
            })(
              <Input placeholder='请输入收款银行账户名称...' autoComplete='off' />
            )
          }
        </FormItem>

        <FormItem label='提现金额' {...formmItemLayout}
          extra={+totalBalance > 0 ? `可提现最大金额 ${+totalBalance} 元` : ''}
        >
          {
            getFieldDecorator('amount', {
              rules: [
                { required: true, message: '请填写要提现的金额' }
              ]
            })(
              <InputNumber min={0} max={+totalBalance}
                placeholder='请输入提现金额...'
                autoComplete='off'
                disabled={+totalBalance === 0}
                style={{ width: '100%' }}
              />
            )
          }
        </FormItem>
        <FormItem label='支付密码' {...formmItemLayout}>
          {
            getFieldDecorator('password', {
              getValueFromEvent: event => event.target.value.replace(/\s+/g, ''),
              validate: [
                {
                  trigger: 'onBlur',
                  rules: [
                    {
                      validator (rule, value, callback) {
                        if (value && !paywordReg.test(value)) {
                          /* eslint-disable */
                          callback('支付密码格式不正确')
                          /* eslint-enable */
                        } else {
                          callback()
                        }
                      }
                    }
                  ]
                },
                {
                  trigger: ['onChange', 'onBlur'],
                  rules: [{ required: true, message: '请您填写6位数字支付密码' }]
                }
              ]
            })(
              <Input.Password placeholder='请输入6位数字支付密码...'
              autoComplete='off'
              style={{ width: '100%' }} />
            )
          }
        </FormItem>
      </Form>
    </Modal>
  )
}

CashoutForm.propTypes = {
  form: PropTypes.object,
  totalBalance: PropTypes.number,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func
}

const CashoutModal = Form.create()(CashoutForm)

export default CashoutModal
