import React, { Component } from 'react'
import { Table, Button, message } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/tool-form/index'
import SupplyDrawer from './supplyDrawer/index'
import LogisticsModal from '@/components/logisticsModal/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import consts from '@/common/utils/consts'
import { renderSaledTypeLabel, renderSelfSaledStatusLabel } from '@/common/utils/mUtils'

const { SERIALWIDTH } = consts
const { pageSizeOptions, saledTypeMaps, selfSaledStatusMaps } = filter

export default class PurchaseSaled extends Component {
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'times',
        formType: 'RangePicker',
        itemParams: {
          label: '申请时间'
        },
        cptParams: {}
      },
      {
        name: 'afterSaleOrderId',
        formType: 'Input',
        itemParams: {
          label: '售后单号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'type',
        formType: 'Select',
        itemParams: {
          label: '售后类型'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...saledTypeMaps
        ]
      },
      {
        name: 'purchaseOrderId',
        formType: 'Input',
        itemParams: {
          label: '订单编号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'status',
        formType: 'Select',
        itemParams: {
          label: '售后状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...selfSaledStatusMaps
        ]
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: [],
    rowDetail: {},
    rowCourierNums: [],
    detailsModal: false, // 售后详情弹窗
    logisticsModal: false // 物流弹窗
  }

  columns = [
    {
      title: '序号',
      key: 'supplySaledCol-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '申请时间',
      key: 'supplySaledCol-1',
      dataIndex: 'createTime',
      render: (text, record, index) => (
        <>
          { record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </>
      )
    },
    {
      title: '申请人',
      key: 'supplySaledCol-2',
      render: (text, record, index) => (
        <span>IIASaaS</span>
      )
    },
    {
      title: '售后单号',
      key: 'supplySaledCol-3',
      dataIndex: 'id'
    },
    {
      title: '售后类型',
      key: 'supplySaledCol-4',
      dataIndex: 'type',
      render: (text, record, index) => (
        <>{ renderSaledTypeLabel(record.type) }</>
      )
    },
    {
      title: '订单编号',
      key: 'supplySaledCol-5',
      dataIndex: 'purchaseOrderId'
    },
    {
      title: '售后状态',
      key: 'supplySaledCol-6',
      dataIndex: 'status',
      render: (text, record, index) => (
        <>{ renderSelfSaledStatusLabel(record.status) }</>
      )
    },
    {
      title: '操作',
      key: 'supplySaledCol-7',
      render: (text, record, index) => (
        <>
          <Button type='primary' size='small' onClick={() => this.showDetailsModal(record)}>查看详情</Button>

          {
            record.type !== 'REFUND' && record.status === 'AUDIT_SUCCESS'
              ? <Button type='primary' size='small' style={{ marginLeft: '6px' }} onClick={() => this.showLogisticsModal(record)}>查看物流</Button>
              : null
          }
        </>
      )
    }
  ]

  componentDidMount () {
    this.getSupplyOrderSaledApplyList()
  }

  // 获取云供应售后申请列表
  getSupplyOrderSaledApplyList = async () => {
    const { pages, query = {} } = this.state
    const res = await Api.getSupplyOrderSaledApplyList({ ...query, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    }
  }

  // 保存云供应订单售后审核
  saveSupplyOrderSaledAudit = async (params) => {
    const res = await Api.saveSupplyOrderSaledAudit(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
      this.getSupplyOrderSaledApplyList()
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    if (search.times) {
      search.applyDateBegin = search.times.length === 2 ? search.times[0].format('YYYY-MM-DD') : undefined
      search.applyDateEnd = search.times.length === 2 ? search.times[1].format('YYYY-MM-DD') : undefined

      delete search.times
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search
    }, () => {
      this.getSupplyOrderSaledApplyList()
    })
  }

  // 点击查看详情
  showDetailsModal = (record) => {
    this.setState({
      rowDetail: record,
      detailsModal: true
    })
  }

  // 点击查看物流
  showLogisticsModal = (record) => {
    const { courierCode, courierNumber } = record
    let courierNumberList = []

    if (courierNumber && courierNumber.length > 0) {
      const nums = courierNumber.split(',')
      courierNumberList = $lodash.map(nums, (o) => {
        return {
          courierCode: courierCode,
          courierNumber: o
        }
      })
    }

    this.setState({
      rowCourierNums: courierNumberList,
      logisticsModal: true
    })
  }

  closeModal = () => {
    this.setState({
      detailsModal: false,
      logisticsModal: false,
      rowDetail: {},
      rowCourierNums: []
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getSupplyOrderSaledApplyList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getSupplyOrderSaledApplyList()
    })
  }

  render () {
    const { searchData = [], pages, tableData = [], rowDetail = {}, rowCourierNums = [], detailsModal = false, logisticsModal = false } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '30px' }}>
          <SearchForm
            dataSource={searchData}
            handleSubmit={(obj) => this.handleSearch(obj)}
          />
        </div>

        <Table rowKey='id'
          dataSource={tableData}
          columns={this.columns}
          pagination={pagination}
        />

        <SupplyDrawer visible={detailsModal}
          detailInfo={rowDetail}
          onCancel={() => this.closeModal()}
          onConfirm={(values) => this.saveSupplyOrderSaledAudit(values)}
        />

        {
          logisticsModal
            ? <LogisticsModal
              courierNumberList={rowCourierNums}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}
