import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Form, Button, Icon, Modal } from 'antd'

import EnquireOrderPreview from '../component/enquirePreviewTable/index'
import QuotationPreviewTable from '../component/quotationPreviewTable/index'
import Remarks from '../component/remarks/index'
import RemarksForm from '../component/formRemarks/index'

import Api from '@/common/api/index'
import styles from './index.module.scss'

const { confirm } = Modal

class EnquireProcessForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    history: PropTypes.object
  }

  state = {
    orderId: null,
    quotationNumber: 1,
    enquiryOrderDetails: [], // 询价单数据
    enquiryQuotationDetails: [], // 报价单数据
    enquireRemarks: ''
  }

  componentDidMount () {
    const { orderId, quotationNumber } = this.props.history.location.state

    this.setState({
      orderId: orderId,
      quotationNumber: quotationNumber
    }, () => {
      this.getEnquireProcessDetails({ orderId: orderId })
    })
  }

  // 获取询价过程详情
  getEnquireProcessDetails = async (params) => {
    const res = await Api.getEnquireProcessDetails(params)
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        enquiryOrderDetails: data.enquiryOrderDetails || [],
        enquiryQuotationDetails: data.enquiryQuotationDetails || [],
        enquireRemarks: data.remark || ''
      })
    }
  }

  // 更新报价状态
  updateQuotationOrderState = async (params, updateState) => {
    const res = await Api.updateQuotationOrderState(params)
    const { code } = res
    if (+code === 10000) {
      this.countDown()
    }
  }

  // 点击操作按钮
  handleChangeAnswerState = (state) => {
    const { orderId, quotationNumber } = this.state

    const params = {
      orderId: orderId,
      enquiryStatus: state,
      quotationNumber: quotationNumber,
      remarks: this.props.form.getFieldValue('remarks') || ''
    }

    if (state === 'REFUSE') {
      confirm({
        title: '确定拒绝？',
        onOk: () => {
          this.updateQuotationOrderState(params, state)
        }
      })
      return false
    }

    this.updateQuotationOrderState(params, state)
  }

  // 倒计时
  countDown = () => {
    let secondsToGo = 5

    const modal = Modal.success({
      title: '操作成功',
      content: `${secondsToGo}s后将返回列表页`,
      onOk: () => {
        clearInterval(timer)
        clearTimeout(outTimer)
        modal.destroy()

        this.props.history.push({ pathname: '/enquire/enquireOrderList' })
      }
    })

    const timer = setInterval(() => {
      secondsToGo -= 1
      modal.update({
        content: `${secondsToGo}s后将返回列表页`
      })
    }, 1000)

    const outTimer = setTimeout(() => {
      clearInterval(timer)
      modal.destroy()
      this.props.history.push({ pathname: '/enquire/enquireOrderList' })
    }, secondsToGo * 1000)
  }

  // 点击返回
  handleBack = () => {
    this.props.history.go(-1)
  }

  render () {
    const { form } = this.props
    const { enquiryOrderDetails = [], enquiryQuotationDetails = [], enquireRemarks = '' } = this.state

    return (
      <>
        <Card bodyStyle={{ padding: '16px' }} bordered={false}>
          <div style={{ marginBottom: '20px' }}>
            <Button onClick={() => this.handleBack()}><Icon type='left' />返回列表</Button>
          </div>

          <div style={{ marginBottom: '24px' }}>
            <div style={{ marginBottom: '15px' }}>
              <EnquireOrderPreview list={enquiryOrderDetails} />
            </div>
            <Remarks title='商户备注' remarks={[{ remarks: enquireRemarks }]} />
          </div>

          <div style={{ marginBottom: '24px', fontSize: '18px', color: '#333333' }}>
            全网1200+供应商正在为您报价，{enquiryQuotationDetails.length > 0 ? '已有' : '暂无'}供应商回复
          </div>

          {
            enquiryQuotationDetails.map((record, index) => (
              <div className={styles.quotationListItem} key={`quotationListItem-${index}`}>
                <div style={{ marginBottom: '15px' }}>
                  <QuotationPreviewTable
                    title={<>第{index + 1}次报价</>}
                    descriptions={record.desc}
                    list={record.enquiryQuotationOrderDetails || []}
                  />
                </div>
                <Remarks title='商户备注' remarks={record.enquiryRemark && record.enquiryRemark !== '' ? [{ remarks: record.enquiryRemark }] : []} />
                <Remarks title='客服备注' remarks={record.quotationRemark && record.quotationRemark !== '' ? [{ remarks: record.quotationRemark }] : []} />
              </div>
            ))
          }

          <div style={{ marginTop: '24px' }}>
            <RemarksForm form={form} />
          </div>

          <div style={{ marginTop: '24px', textAlign: 'center' }}>
            <Button type='danger' size='large'
              onClick={() => this.handleChangeAnswerState('REFUSE')}>拒绝报价</Button>

            <Button type='primary' size='large' style={{ marginLeft: '15px' }}
              onClick={() => this.handleChangeAnswerState('COMMUNICATE')}>再次沟通</Button>

            <Button type='primary' size='large' style={{ marginLeft: '15px' }}
              onClick={() => this.handleChangeAnswerState('RECEIVE')}>同意报价</Button>
          </div>
        </Card>
      </>
    )
  }
}

const EnqireProcess = Form.create()(EnquireProcessForm)

export default EnqireProcess
