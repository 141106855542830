import React, { Component } from 'react'
import { Card } from 'antd'

import AccountInformation from '../account-information/index'
import AccountSettings from '../account-settings/index'

import styles from './index.module.scss'

const menuMaps = [
  { key: 'SETTING', name: '账户设置' },
  { key: 'INFORMATION', name: '基本信息' }
]

export default class AccountPersonal extends Component {
  state = {
    activeKey: 'SETTING'
  }

  componentDidMount () {}

  handleChangeMenu = (key) => {
    this.setState({
      activeKey: key
    })
  }

  render () {
    const { activeKey = 'SETTING' } = this.state

    return (
      <>
        <Card bodyStyle={{ padding: '30px 40px', minHeight: 'calc(100vh - 156px)' }}>
          <div className={`${styles.topMenus}`}>
            {
              menuMaps.map((item) => (
                <div className={`${styles.menuItem} ${item.key === activeKey ? styles.active : ''}`} key={item.key} onClick={() => this.handleChangeMenu(item.key)}>{ item.name }</div>
              ))
            }
          </div>

          {
            activeKey === 'SETTING'
              ? <AccountSettings />
              : null
          }

          {
            activeKey === 'INFORMATION'
              ? <AccountInformation />
              : null
          }
        </Card>
      </>
    )
  }
}
