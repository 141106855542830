import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Button } from 'antd'
import $lodash from 'lodash'

import InvoiceExpress from '@/components/invoiceExpressForm/index' // 发票凭证
import InvoiceAccount from '@/components/invoiceAccount/index' // 发票抬头
import InvoiceProduct from '@/components/invoiceProduct/index' // 发票商品
import InvoiceReceiving from '@/components/invoiceReceiving/index' // 发票地址

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'

class InvoiceModalForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    ids: PropTypes.array,
    openStatus: PropTypes.string,
    isRepeat: PropTypes.bool,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }

  state = {
    invoiceVoucherInfo: {}, // 发票物流&凭证信息
    invoiceInfo: {}, // 发票抬头
    tableData: [], // 发票物料信息
    totalAmount: 0,
    receivingAddress: {}, // 收件信息
    sendAddressList: [], // 发货地址列表
    selectedAddressKey: null, // 默认选中的地址
    count: 0,
    invoiceCodeKeys: [{ id: 0, value: undefined }]
  }

  componentDidMount () {
    this.getSendAddressList()
    this.getSupplyOrderInvoiceOpeningDetails()
  }

  // 获取发货地址列表
  getSendAddressList = async () => {
    const res = await Api.getSendAddressList({})
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        sendAddressList: data || [],
        selectedAddressKey: data && data.length > 0 ? data[0].id : null
      })
    }
  }

  // 云供应发票- 获取开票详情
  getSupplyOrderInvoiceOpeningDetails = async () => {
    const { ids = [] } = this.props
    const res = await Api.getSupplyOrderInvoiceOpeningDetails({ invoiceRecordId: ids[0] })
    const { code, data } = res
    if (+code === 10000) {
      let _count = 0
      let _invoiceCodeKeys = [{ id: 0, value: undefined }]

      if (data && data.invoiceCode && $lodash.trim(data.invoiceCode) !== '') {
        const arr = $lodash.split(data.invoiceCode, ',')
        _invoiceCodeKeys = $lodash.map(arr, (o, i) => { return { id: i, value: o } })
        _count = _invoiceCodeKeys.length - 1
      }

      if (data && data.goodsInfo && data.goodsInfo.length > 0) {
        const { goodsInfo = [] } = data
        data.goodsInfo = $lodash.map(goodsInfo, (record) => {
          return {
            ...record,
            price: record.sellingPrice,
            orderId: record.purchaseOrderId
          }
        })
      }

      this.setState({
        invoiceVoucherInfo: data || {},
        invoiceInfo: data.invoiceInfo || {},
        tableData: data.goodsInfo || [],
        totalAmount: data.totalAmount || 0,
        receivingAddress: data.addressInfo || {},
        invoiceCodeKeys: _invoiceCodeKeys,
        count: _count
      })
    }
  }

  // 点击确定
  handleSubmit = () => {
    const { form, ids = [], isRepeat, onConfirm } = this.props

    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { courierCode, invoiceCodeKeys } = values
        const _invoiceCodeKeys = $lodash.filter(invoiceCodeKeys, (o) => { return o !== undefined })

        values.courierCode = $lodash.includes(courierCode, 'SAAS') ? $lodash.split(courierCode, '-')[1] : courierCode
        values.invoiceCode = _invoiceCodeKeys.length > 0 ? _invoiceCodeKeys.join(',') : undefined
        values.invoiceRecordId = ids[0]

        onConfirm(values, isRepeat ? 'OPENED' : 'OPENING')
      }
    })
  }

  // 点击导出表格
  handleDownload = () => {
    const { ids = [] } = this.props
    DownloadApi.downloadInvoiceTpl({ invoiceRecordId: ids[0] })
  }

  // 点击导出电子发票表格
  handleDownloadElectronic = () => {
    const { ids = [] } = this.props
    DownloadApi.downloadProductsExcelBySupplyInvoiceElectronic({ invoiceRecordId: ids[0] })
  }

  // 新增invoiceCode FormItem
  handleAddInvoiceCodeFormItem = (index) => {
    const { count = 0, invoiceCodeKeys = [{ id: 0, value: undefined }] } = this.state

    invoiceCodeKeys.splice(index + 1, 0, { id: count + 1, value: undefined })

    this.setState({
      invoiceCodeKeys: invoiceCodeKeys,
      count: count + 1
    })
  }

  // 新增invoiceCode FormItem
  handleRemoveInvoiceCodeFormItem = (index) => {
    const { invoiceCodeKeys = [0] } = this.state

    if (invoiceCodeKeys.length === 1) { return false }

    invoiceCodeKeys.splice(index, 1)

    this.setState({
      invoiceCodeKeys: invoiceCodeKeys
    })
  }

  render () {
    const { form, onCancel, openStatus, isRepeat = false } = this.props
    const {
      invoiceVoucherInfo = {}, invoiceInfo = {}, tableData = [], totalAmount = 0, receivingAddress = {}, invoiceCodeKeys = [],
      sendAddressList = [], selectedAddressKey = null
    } = this.state

    return (
      <>
        <Modal title='发票详情' width={1000} visible bodyStyle={{ padding: '16px', height: '500px', overflowY: 'auto' }}
          maskClosable={false}
          onCancel={() => onCancel()}
          footer={[
            <Button key={'supplyInvoiceBtn-0'} onClick={() => onCancel()}>取消</Button>,
            <Button type='primary' key={'supplyInvoiceBtn-3'} onClick={() => this.handleDownloadElectronic()}>导出电子发票表格</Button>,
            <Button type='primary' key={'supplyInvoiceBtn-2'} onClick={() => this.handleDownload()}>导出纸质发票表格</Button>,
            <Button type='primary' key={'supplyInvoiceBtn-1'} onClick={() => this.handleSubmit()}>{ isRepeat ? '重新开票' : '确认开票' }</Button>
          ]}
        >
          <Form>
            <div>
              <InvoiceExpress form={form}
                openStatus={openStatus}
                invoiceType={invoiceVoucherInfo.invoiceType}
                invoiceVoucherInfo={invoiceVoucherInfo}
                invoiceCodeKeys={invoiceCodeKeys}
                addInvoiceCodeFormItem={(index) => this.handleAddInvoiceCodeFormItem(index)}
                removeInvoiceCodeFormItem={(index) => this.handleRemoveInvoiceCodeFormItem(index)}
              />
            </div>

            <InvoiceAccount
              title={invoiceInfo.invoiceTitle}
              creditNo={invoiceInfo.creditNo}
              tel={invoiceInfo.tel}
              bankName={invoiceInfo.bankName}
              bankCard={invoiceInfo.bankCard}
              province={invoiceInfo.province}
              city={invoiceInfo.city}
              county={invoiceInfo.county}
              address={invoiceInfo.address}
            />

            <div style={{ marginTop: '24px' }}>
              <InvoiceProduct list={tableData} totalAmount={totalAmount} />
            </div>

            <InvoiceReceiving isEdit={false} form={form}
              receivingAddress={receivingAddress}
              sendAddressList={sendAddressList}
              selectedAddressKey={selectedAddressKey}
            />
          </Form>
        </Modal>
      </>
    )
  }
}

const InvoiceModal = Form.create()(InvoiceModalForm)

export default InvoiceModal
