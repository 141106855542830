import Ajax from '../utils/ajax'

export default {
  // 获取客户收件地址列表
  getEndUserReceivedAddressList (params) {
    return Ajax.get('/api/guest/supplier/guest/addressList', { params })
  },

  // 保存新增客户收货地址
  saveAddEndUserReceiveAddress (params) {
    return Ajax.post('/api/guest/address/add', params)
  },

  // 保存编辑客户收货地址
  saveEditEndUserReceiveAddress (params) {
    return Ajax.post('/api/guest/address/modify', params)
  },

  // 删除客户收货地址
  deleteEndUserReceivedAddress (params) {
    return Ajax.post('/api/guest/address/remove', params)
  },

  // 商家更新客户订单收货地址
  updateRecevingAddressByOrderId (params) {
    return Ajax.post('/api/warehouse/update/eu/order/address', params)
  },

  /** 发货地址管理START **/
  // 获取发货地址列表
  getSendAddressList (params) {
    return Ajax.post('/api/address/list/sender', params)
  },

  // 新增发货地址
  saveSendAddress (params) {
    return Ajax.post('/api/address/add/sender', params)
  },

  // 编辑发货地址
  saveEditSendAddress (params) {
    return Ajax.post('/api/address/update/sender', params)
  },

  // 删除发货地址
  deleteSendAddress (params) {
    return Ajax.post('/api/address/update/status/sender', params)
  },

  // 获取发货地址详情
  getSendAddressDetail (params) {
    return Ajax.get('/api/mall/manage/get/shipAddress', { params })
  },
  /** 发货地址管理END **/

  /** 发票收件地址管理SATRT **/
  // 获取发票收件地址列表
  getInvoiceReceivingAddressList (params) {
    return Ajax.post('/api/address/list/invoice', params)
  },

  // 获取发票收件地址详情
  getInvoiceReceivingAddressInfo (params) {
    return Ajax.post('/api/address/get/invoice', params)
  },

  // 新增发票收件地址
  saveAddInvoiceReceivingAddress (params) {
    return Ajax.post('/api/address/add/invoice', params)
  },

  // 编辑发票收件地址
  saveEditInvoiceReceivingAddress (params) {
    return Ajax.post('/api/address/update/invoice', params)
  },

  // 删除发票收件地址
  deleteInvoiceReceivingAddress (params) {
    return Ajax.post('/api/address/update/status/invoice', params)
  }
  /** 发票收件地址END **/
}
