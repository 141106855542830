import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Card, message, Modal } from 'antd'
import $lodash from 'lodash'

import CartSteps from '../component/cartSteps/index'
import GoodsTable from './goodsTable/index'
import Footer from './footer/index'

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'

const { warning, confirm } = Modal
const { downloadAddToCartSkuQuoteExcel } = DownloadApi

function Cart (props) {
  const { history } = props
  const [tableData, setTableData] = useState([])
  const [selectedTotalValues, setSelectedTotalValues] = useState({ goods: 0, nums: 0, amount: 0 })
  const [checkedAll, setCheckedAll] = useState(false)

  // 获取购物车列表
  const getCartSkuList = async () => {
    const res = await Api.getCartSkuList({})
    const { code, data = [] } = res
    if (+code === 10000) {
      if (data && data.length > 0) {
        data.forEach(item => {
          item.checked = false
          item.supplyInfoList.forEach(obj => {
            obj.checked = true
          })
        })

        const obj = calcSelectedAmount([...data])
        setSelectedTotalValues(obj)
      }

      setTableData(data || [])
    }
  }

  // 结算- 下单预览
  const getPurchaseOrderPreviewDatas = async (params) => {
    const res = await Api.getPurchaseOrderPreviewDatas(params)
    const { code, data } = res
    if (+code === 10000) {
      history.push({ pathname: '/purchase/orderSubmit', state: { data } })
    } else {
      message.warning(res.message)
    }
  }

  // 购物车- 删除
  const saveRemoveSkuToCart = async (params) => {
    const res = await Api.saveRemoveSkuToCart(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      getCartSkuList()
    }
  }

  /* 点击选择、取消选择
   * index === -1 为全选按钮
  */
  const handleCheckedGoods = (checked, index) => {
    if (index > -1) {
      tableData[index].checked = checked
    } else {
      tableData.forEach((record) => {
        if (record.onSaleStatus !== 'DISABLE') {
          record.checked = checked
        }
      })
    }

    const newdatas = $lodash.filter(tableData, (record) => { return record.onSaleStatus !== 'DISABLE' })

    const n = $lodash.findIndex(newdatas, (record) => { return !record.checked })
    const obj = calcSelectedAmount([...tableData])

    setTableData([...tableData])
    setCheckedAll(n === -1)
    setSelectedTotalValues(obj)
  }

  // 删除SKU项
  const removeSkuItem = (params) => {
    confirm({
      title: '确定删除？',
      onOk: () => {
        saveRemoveSkuToCart(params)
      }
    })
  }

  // 点击删除
  const handleRemoveSku = (type, record) => {
    const cartIdList = []
    if (type === 'ALL') {
      const checkdatas = $lodash.filter(tableData, (record) => { return record.checked })
      if (checkdatas.length === 0) {
        warning({
          title: '至少选中一种哦!'
        })

        return false
      }

      checkdatas.forEach((record, i) => {
        const { supplyInfoList = [] } = record
        $lodash.forEach(supplyInfoList, (row) => {
          cartIdList.push(row.cartId)
        })
      })
    } else {
      const { supplyInfoList = [] } = record
      $lodash.forEach(supplyInfoList, (row) => {
        cartIdList.push(row.cartId)
      })
    }

    removeSkuItem({ cartIdList: cartIdList })
  }

  // 监听数量输入
  const handleChangeQuantity = (value, pricedex, rowdex) => {
    tableData[rowdex].supplyInfoList[pricedex].quantity = value

    const obj = calcSelectedAmount([...tableData])
    setTableData([...tableData])
    setSelectedTotalValues(obj)
  }

  // 监听价格选择
  const handleChangeCheckedPrice = (checked, pricedex, rowdex) => {
    const { supplyInfoList = [] } = tableData[rowdex]

    supplyInfoList[pricedex].checked = checked
    supplyInfoList[pricedex].quantity = +supplyInfoList[pricedex].quantity > 0 ? +supplyInfoList[pricedex].quantity : 1

    tableData[rowdex].supplyInfoList = [...supplyInfoList]

    const obj = calcSelectedAmount([...tableData])

    setTableData([...tableData])
    setSelectedTotalValues(obj)
  }

  // 计算选择的商品数量和金额
  const calcSelectedAmount = (tableData = []) => {
    const obj = { goods: 0, nums: 0, amount: 0 }

    // 过滤未选择的产品
    const datas = $lodash.filter(tableData, (record) => { return record.checked })

    obj.goods = datas.length

    $lodash.forEach(datas, (record) => {
      const { supplyInfoList = [] } = record
      /* 过略掉未选中的价格和没填写数量的数据 */
      const newArr = $lodash.filter(supplyInfoList, (stem) => { return stem.checked && +stem.quantity > 0 })

      if (newArr.length > 0) {
        obj.nums += $lodash.sumBy(newArr, 'quantity')
        obj.amount += $lodash.sumBy(newArr, (atem) => { return $lodash.round(+atem.price * +atem.quantity, 2) })
      }
    })

    return obj
  }

  // 点击结算、导出报价单按钮
  const handleConfirm = (type) => {
    // 过滤掉未选中的产品
    const checkdatas = $lodash.filter(tableData, (record) => { return record.checked })

    if (checkdatas.length === 0) {
      warning({
        title: '至少选中一种商品哦!'
      })

      return false
    }

    for (const record of checkdatas) {
      const { supplyInfoList } = record
      const newArr = $lodash.filter(supplyInfoList, (item) => { return item.checked && item.quantity > 0 })

      if (newArr.length === 0) {
        warning({
          title: <>订货号:&nbsp;{record.itemNumber}&nbsp;请选择产品价格并且填写有效数量</>,
          maskClosable: true
        })

        return false
      }

      for (const row of newArr) {
        if (type === 'SUBMIT' && +row.price === 0) {
          Modal.warning({
            title: <>订货号:&nbsp;{record.itemNumber}&nbsp;为价格面议产品，详情请咨询客服</>,
            maskClosable: true
          })

          return false
        }

        if (record.skuGoodsType === 'CLOUD_SPOT' && +row.quantity > +row.stock) {
          warning({
            title: <>订货号:&nbsp;{record.itemNumber}&nbsp;现货库存不足</>,
            maskClosable: true
          })
          return false
        }

        if (type === 'SUBMIT' && record.skuGoodsType === 'CLOUD_SPOT' && +row.quantity < +row.minOrder) {
          warning({
            title: <>订货号:&nbsp;{record.itemNumber}&nbsp;最少购买{+row.minOrder}件</>,
            maskClosable: true
          })

          return false
        }
      }
    }

    if (type === 'SUBMIT') {
      const goodsInfoList = setSubmitPreviewRequestParams(checkdatas)

      getPurchaseOrderPreviewDatas({ goodsInfoList: goodsInfoList })
    }

    if (type === 'DOWNLOAD') {
      const skuIdQuantityList = setDownloadQuoteRequestParams(checkdatas)

      downloadAddToCartSkuQuoteExcel({ skuIdQuantityList: skuIdQuantityList })
    }
  }

  // 设置报价单参数格式
  const setDownloadQuoteRequestParams = (checkdatas) => {
    const skuIdQuantityList = []
    checkdatas.forEach((record, i) => {
      const { supplyInfoList = [] } = record
      const newdatas = $lodash.filter(supplyInfoList, (o) => { return o.checked })

      $lodash.forEach(newdatas, (item) => {
        const obj = {
          commoditySkuId: record.commoditySkuId,
          skuGoodsType: record.skuGoodsType,
          period: record.skuGoodsType === 'CLOUD_FUTURES' ? `${item.deliveryPeriodDays}工作日` : '当天发货',
          deliveryPeriodDays: record.skuGoodsType === 'CLOUD_FUTURES' ? `${item.deliveryPeriodDays}工作日` : '当天发货',
          quantity: item.quantity,
          price: +item.price || 0,
          supplierId: item.supplierId,
          prepaymentRatio: +item.prepaymentRatio // 预付比例
        }

        skuIdQuantityList.push(obj)
      })
    })

    return skuIdQuantityList
  }

  // 设置结算生成订单参数格式
  const setSubmitPreviewRequestParams = (checkdatas) => {
    const paramlist = []
    checkdatas.forEach(record => {
      const obj = {
        commoditySkuId: record.commoditySkuId,
        skuGoodsType: record.skuGoodsType,
        quantityInfoList: setQuantityInfoList(record.supplyInfoList)
      }
      paramlist.push(obj)
    })

    const goodsInfoList = $lodash.filter(paramlist, item => { return item.quantityInfoList.length !== 0 })
    if (goodsInfoList.length === 0) {
      warning({
        title: '请先选择商品'
      })

      return false
    }

    return paramlist
  }

  // 设置quantityInfoList数据结构
  const setQuantityInfoList = (supplyInfoList) => {
    const list = []
    supplyInfoList.forEach(item => {
      if (item.checked === true) {
        const obj = {
          cartId: item.cartId,
          supplierId: item.supplierId,
          quantity: item.quantity
        }
        list.push(obj)
      }
    })
    return list
  }

  useEffect(() => {
    getCartSkuList()
  }, [])

  return (
    <>
      <Card bodyStyle={{ padding: '15px' }}>
        <div style={{ marginBottom: '24px' }}>
          <CartSteps type={1} />
        </div>

        <GoodsTable
          checkedAll={checkedAll}
          tableData={tableData}
          onSelectedAll={(checked) => handleCheckedGoods(checked, -1)}
          onSelectedGoods={(checked, index) => handleCheckedGoods(checked, index)}
          onRemoveSkuItem={(record) => handleRemoveSku('SINGLE', record)}
          onChangeQuantity={(val, pricedex, rowdex) => handleChangeQuantity(val, pricedex, rowdex)}
          onChangeCheckedPrice={(checked, pricedex, rowdex) => handleChangeCheckedPrice(checked, pricedex, rowdex)}
        />

        <Footer
          checkedAll={checkedAll}
          totalValues={selectedTotalValues}
          onSelectedAll={(checked) => handleCheckedGoods(checked, -1)}
          onRemove={() => handleRemoveSku('ALL')}
          onConfirm={() => handleConfirm('SUBMIT')}
          onDownload={() => handleConfirm('DOWNLOAD')}
        />
      </Card>
    </>
  )
}

Cart.propTypes = {
  history: PropTypes.object
}

export default Cart
