import { downloadFileByGet, downloadFileByPost } from '../utils/downloadFile'

export default {
  // 下载-下载通用模板
  downloadCommonTemplate (params) {
    return downloadFileByGet('/api/excel/template/download/common', params)
  },

  // 客户列表- 客户导入模板下载
  downloadUploadEndUserTemplate (params) {
    return downloadFileByGet('/api/excel/download/endUserSupplierImportTemplate', params)
  },

  // 云供应产品-下载价格模板
  downloadCloudSupplyPriceTemplate (params) {
    return downloadFileByGet('/api/excel/download/justSupplyTemplate', params)
  },

  // 云供应产品-下载期货导入模板
  downloadCloudSupplyFuturesTemplate (params) {
    return downloadFileByGet('/api/excel/download/futures/justSupplyTemplate', params)
  },

  // 客户列表- 导出客户列表
  downloadEndUserList (params) {
    return downloadFileByPost('/api/excel/export/euUserInfo', params)
  },

  // 导出失败详情
  downloadfailList (params) {
    return downloadFileByPost('/api/guest/supplier/endUser/export', params)
  },

  // 导出IIASaaS的德邦快递服务月度账单
  downIIASaaSDepponExpressBillByMonth (params) {
    return downloadFileByGet('/api/excel/data/download/logistics/deppon/fee', params)
  },

  // 开票下载
  downloadInvoiceTpl (params) {
    return downloadFileByPost('/api/platformOrderInvoice/exportInvoiceInfo', params)
  },

  // 云供应开票管理 导出电子发票开票产品表格
  downloadProductsExcelBySupplyInvoiceElectronic (params) {
    return downloadFileByPost('/api/platformOrderInvoice/invoiceElectronicInfoExport', params)
  },

  // 云供应订单- 导出云供应订单
  dowloadSupplyOrder (params) {
    return downloadFileByPost('/api/excel/data/download/cloudSupplyOrderInfo', params)
  },

  // 商户产品导入匹配结果导出
  downMatchResult (params) {
    return downloadFileByGet('/api/excel/export/product/import/result', params)
  },

  // 快递记录导出
  downloadExpressServiceRecords (params) {
    return downloadFileByPost('/api/warehouse/get/distributorPickingInfo/export', params)
  },

  // 快速下单-下载导入模板
  downManualTemp (params) {
    return downloadFileByGet('/api/excel/download/handOrderTemplate', params)
  },

  // 购物车-导出报价单
  downloadAddToCartSkuQuoteExcel (params) {
    return downloadFileByPost('/api/just/purchase/shopping/cart/quotation/export', params)
  },

  // 询价单- 我的询价 > 导出询价单EXCEL
  downloadEnquiryOrderExcel (params) {
    return downloadFileByPost('/enquiry/order/manage/center/exportDetailData', params)
  },

  // 询价单- 预报价单- 下载导入报价模版
  downloadPrepareQuotionExcelTemplate (params) {
    return downloadFileByGet('/api/excel/download/predict/quotationTemplate', params)
  },

  // 询价单- 预报价单- 下载预报价单EXCEL
  downloadPrepareQuotionsExcel (params) {
    return downloadFileByPost('/api/predict/quotation/order/management/predict/order/exportDetailData', params)
  }

}
