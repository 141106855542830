import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { Layout } from 'antd'

import BasicHeader from '../basicHeader' // 顶部栏
import SiderMenu from '@/components/siderMenu/index' // 左侧菜单
import HandleModal from '@/components/handleModal/index' // 处理中loading

import { setShowLoading, setHandleModal } from '@/reducers/action'

import { routerMap } from './../../routes/index.js'
import styles from './index.module.scss'

const { Header, Content } = Layout

class BasicLayout extends Component {
  static propTypes = {
    history: PropTypes.object,
    dispatch: PropTypes.func,
    handleModal: PropTypes.bool
  }

  componentDidMount () {
    this.props.dispatch(setShowLoading({ loading: false }))
    this.props.dispatch(setHandleModal({ handleModal: false }))
  }

  render () {
    const { history, handleModal = false } = this.props

    return (
      <>
        <Layout className={`${styles.basicLayout}`}>
          <SiderMenu history={history} />

          <Layout>
            <div className={`${styles.header}`} />
            <Header className={`${styles.fixedHeader}`}>
              <BasicHeader history={history} />
            </Header>

            <Content className={`${styles.layoutContent}`}>
              <Switch>
                {
                  routerMap.map((route, i) => (
                    <Route
                      exact
                      key={i}
                      name={route.name}
                      path={route.path}
                      render={props => (
                        <route.component {...props} />
                      )}
                    />
                  ))
                }
              </Switch>
            </Content>
          </Layout>
        </Layout>

        {
          handleModal
            ? <HandleModal />
            : null
        }
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    handleModal: state.handleModal
  }
}

export default connect(mapStateToProps)(BasicLayout)
