import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Col, Table, Button, Icon, message } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'
import { connect } from 'react-redux'

import SearchForm from '@/components/tool-form/index'
import UploadModal from '../../purchase/cloudLibrary/uploadModal'
import UploadSheetModal from '@/components/uploadSheetModal/index'
import TaskProgressModal from '@/components/taskProgressModal'

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'
import { setHandleModal } from '@/reducers/action'
import filter from '@/common/utils/filter'
import consts from '@/common/utils/consts'

const { importConfig, pageSizeOptions } = filter
const { SERIALWIDTH } = consts

class PrepareQuotations extends Component {
  static propTypes = {
    history: PropTypes.object,
    dispatch: PropTypes.func
  }

  state = {
    searchData: [
      {
        name: 'predictQuotationOrderId',
        formType: 'Input',
        itemParams: {
          label: '预报价单编号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'times',
        formType: 'RangePicker',
        itemParams: {
          label: '报价时间'
        },
        cptParams: {}
      },
      {
        name: 'predictQuotationStatus',
        formType: 'Select',
        itemParams: {
          label: '报价状态'
        },
        cptParams: {

        },
        options: [
          { id: 'ALL', name: '全部' },
          { id: 'PRE_SUCCESS', name: '已报价' },
          { id: 'PRE_FAILED', name: '报价中' }
        ]
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: [],
    uploadModal: false,
    taskProgressModal: false,
    uploadSheetModal: false,
    uploadSheetDetails: {}
  }

  columns = [
    {
      title: '序号',
      key: 'preQuoteOrderCol-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '预报价单编号',
      key: 'preQuoteOrderCol-1',
      dataIndex: 'predictQuotationOrderId'
    },
    {
      title: '报价时间',
      key: 'preQuoteOrderCol-2',
      dataIndex: 'createTime',
      render: (text, record, index) => (
        <>{ record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '报价状态',
      key: 'preQuoteOrderCol-3',
      dataIndex: 'predictQuotationStatus',
      render: (text, record, index) => (
        <>
          {
            record.predictQuotationStatus === 'PRE_SUCCESS'
              ? <span className='greenColor'>已报价</span>
              : <span className='redColor'>报价中</span>
          }
        </>
      )
    },
    {
      title: '查看预报价',
      key: 'preQuoteOrderCol-4',
      render: (text, record, index) => (
        <>
          {
            record.predictQuotationStatus === 'PRE_SUCCESS'
              ? <Button type='link' size='small' onClick={() => this.getPrepareQuotationOrderPdfUrl({ predictQuotationOrderId: record.predictQuotationOrderId })}>点击查看</Button>
              : null
          }
        </>
      )
    },
    {
      title: '操作',
      key: 'preQuoteOrderCol-5',
      render: (text, record, index) => (
        <>
          {
            record.predictQuotationStatus === 'PRE_SUCCESS'
              ? <>
                <Button type='link' size='small' onClick={() => this.handleLinkToDetail(record)}>查看报价</Button>
                <Button type='link' size='small' onClick={() => this.handleDownloadQuoted(record)}>下载报价</Button>
              </>
              : null
          }
        </>
      )
    }
  ]

  componentDidMount () {
    this.getPrepareQuotationsOrderList()
  }

  // 获取预报价单列表
  getPrepareQuotationsOrderList = async () => {
    const { query = {}, pages } = this.state
    const res = await Api.getPrepareQuotationsOrderList({ ...query, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    }
  }

  // 获取预报价单PDF路径
  getPrepareQuotationOrderPdfUrl = async (params) => {
    const res = await Api.getPrepareQuotationOrderPdfUrl(params)
    const { code, data } = res
    if (+code === 10000) {
      if (data && data !== '') {
        window.open(data, '_blank')
      } else {
        message.warning('暂无数据')
      }
    }
  }

  // 上传EXCEL智能解析
  saveUploadExcelMatchSmartparse = async (params) => {
    const res = await Api.saveUploadExcelMatchSmartparse(params)
    const { code, data } = res
    if (+code === 10000) {
      this.closeModal()
      this.changeUploadSuccess(data)
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    if (search.times && search.times.length === 2) {
      search.beginDate = moment(search.times[0]).format('YYYY-MM-DD')
      search.endDate = moment(search.times[1]).format('YYYY-MM-DD')

      delete search.times
    }

    this.setState({
      query: search,
      pages: {
        ...pages,
        pageNo: 1
      }
    }, () => {
      this.getPrepareQuotationsOrderList()
    })
  }

  // 监听文件上传
  handleChangeUploadExcel = (info) => {
    const { dispatch } = this.props
    dispatch(setHandleModal({ handleModal: true }))

    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        this.closeModal()

        setTimeout(() => {
          dispatch(setHandleModal({ handleModal: false }))
          this.setState({
            uploadSheetDetails: {
              name: data.tableName || '',
              list: data.sheetNames || [],
              file: info.file.originFileObj
            },
            uploadSheetModal: true
          })
        }, 2000)
      } else {
        message.error(info.file.response.message)
        dispatch(setHandleModal({ handleModal: false }))
      }
    } else if (info.file.status === 'error') {
      message.error('上传失败')
      dispatch(setHandleModal({ handleModal: false }))
    }
  }

  // 点击确定选择导入EXCEL的sheet
  handleConfirmUploadSheet = (values) => {
    const formData = new FormData()

    $lodash.forEach(values, (value, key) => {
      formData.append(key, value)
    })

    this.saveUploadExcelMatchSmartparse(formData)
  }

  changeUploadSuccess = (params) => {
    const configs = {
      pageSourch: 'prepareQuotionSetting',
      sheetSourch: importConfig.prepareQuotionSetting.sheetSourch
    }

    this.props.history.push({ pathname: '/matchs/sheetsConfirm', state: { config: configs, data: { importId: params } } })
  }

  // 点击新建预报价单
  showUploadPrepareModal = () => {
    this.setState({
      uploadModal: true
    })
  }

  // 点击下载预报价单模版
  handleDownloadTemplate = () => {
    DownloadApi.downloadPrepareQuotionExcelTemplate({})
  }

  // 点击下载报价- 下载报价单EXCEL
  handleDownloadQuoted = (record) => {
    DownloadApi.downloadPrepareQuotionsExcel({ predictQuotationOrderId: record.predictQuotationOrderId })
  }

  // 点击查看报价- 跳转到预报价详情
  handleLinkToDetail = (record) => {
    this.props.history.push({ pathname: '/enquire/preareQuotedDetails', state: { ...record } })
  }

  // 点击任务进度
  showProgressModal = () => {
    this.setState({
      taskProgressModal: true
    })
  }

  closeModal = () => {
    this.setState({
      uploadModal: false,
      taskProgressModal: false,
      uploadSheetModal: false,
      uploadSheetDetails: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getPrepareQuotationsOrderList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getPrepareQuotationsOrderList()
    })
  }

  render () {
    const { searchData, pages, tableData = [], uploadModal = false, taskProgressModal = false, uploadSheetModal = false, uploadSheetDetails = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Card bodyStyle={{ padding: '16px' }}>
          <div style={{ marginBottom: '20px' }}>
            <SearchForm
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </div>

          <Row gutter={12} style={{ marginBottom: '15px' }}>
            <Col span={16}>
              <Button type='primary' onClick={() => this.showUploadPrepareModal()}><Icon type='plus' />新建预报价单</Button>

              <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => this.handleDownloadTemplate()}><Icon type='download' />下载模版</Button>

              <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => this.showProgressModal()}><Icon type='eye' />报价进度</Button>
            </Col>
            <Col span={8}>
              <p className='redColor' style={{ textAlign: 'right', lineHeight: '32px' }}>此页面仅供预报价使用，下单购买前请再次确认商品信息</p>
            </Col>
          </Row>

          <Table rowKey='predictQuotationOrderId' bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={pagination}
          />
        </Card>

        { // 上传弹窗
          uploadModal
            ? <UploadModal
                onUpload={(info) => this.handleChangeUploadExcel(info)}
                onCancel={() => this.closeModal()}
              />
            : null
        }

        {
          uploadSheetModal
            ? <UploadSheetModal
              name={uploadSheetDetails.name}
              list={uploadSheetDetails.list}
              file={uploadSheetDetails.file}
              onConfirm={(values) => this.handleConfirmUploadSheet(values)}
              onCancel={() => this.closeModal()}
            />
            : null
        }

        { // 任务进度弹窗
          taskProgressModal
            ? <TaskProgressModal
                taskKey='MERCHANT_PREDICT_QUOTATION_ORDER'
                onCancel={() => this.closeModal()}
              />
            : null
        }
      </>
    )
  }
}

export default connect()(PrepareQuotations)
