import CourierJson from '@/assets/json/courier.json'
import AREA from '@/assets/json/area'
import $lodash from 'lodash'
import filter from './filter'

const {
  brandAgencyStatusMaps, skuGoodsTypeMaps, productPutOnMaps, cloudSupplyOrderStatusMaps, orderTypeMaps, orderPaymentStatusMaps, orderDeliveryStatusMaps,
  pickingOrderSendStatusMaps, orderArriverStatusMaps, invoiceTypeMaps, invoiceOpenedStatusMaps, saledTypeMaps, saledStatusMaps, selfSaledStatusMaps, saledReasonTypeMaps, saledRefundTypeMaps,
  cloudStoreLevelMaps, saledBackGoodsTypeMaps
} = filter

/**
 * 存储localStorage
 */
export const setStore = (name, content) => {
  if (!name) return
  if (typeof content !== 'string') {
    content = JSON.stringify(content)
  }
  window.localStorage.setItem(name, content)
}

/**
 * 获取localStorage
 */
export const getStore = (name) => {
  if (!name) return
  return window.localStorage.getItem(name)
}

/**
 * 删除localStorage
 */
export const removeStore = (name) => {
  if (!name) return
  window.localStorage.removeItem(name)
}

// 替换手机号
export const hideMobile = (phone) => {
  return phone.substr(0, 3) + '****' + phone.substr(-4)
}

/**
 * 获取url上的参数
 */
export const getUrlParams = () => {
  const url = location.search // 获取url中"?"符后的字串
  const theRequest = {}
  if (url.indexOf('?') !== -1) {
    const str = url.substr(1)
    const strs = str.split('&')
    for (let i = 0; i < strs.length; i++) {
      theRequest[strs[i].split('=')[0]] = unescape(strs[i].split('=')[1])
    }
  }
  return theRequest
}

// 替换字符串中的换行
export const repLineBreak = (str, sym) => {
  return str.replace(/\n/g, sym)
}

/**
 * 银行卡号每4位 空格分隔
 */
export const formatBankAccount = (value) => {
  return value.replace(/\s/g, '').replace(/(.{4})/g, '$1 ')
}

/**
 * 省市区数据改为多维数组结构
 */
export const addressToTree = () => {
  const newdatas = []

  $lodash.forEach(AREA.provinceList, (provinceValue, provinceKey) => {
    const obj = { code: provinceKey, name: provinceValue }
    const newCityArr = []

    $lodash.forEach(AREA.cityList, (cityValue, cityKey) => {
      if (provinceKey.substring(0, 2) === cityKey.substring(0, 2)) {
        const cityObj = { code: cityKey, name: cityValue }
        const newAreaArr = []

        $lodash.forEach(AREA.countyList, (areaValue, areaKey) => {
          if (cityKey.substring(0, 4) === areaKey.substring(0, 4)) {
            const areaObj = { code: areaKey, name: areaValue }

            newAreaArr.push(areaObj)
            cityObj.childList = newAreaArr
          }
        })

        newCityArr.push(cityObj)
        obj.childList = newCityArr
      }
    })

    newdatas.push(obj)
  })

  return newdatas
}

/**
 * 根据城市code码 获取城市名称
 */
export const getCityName = (provinceCode, cityCode, countyCode) => {
  return [AREA.provinceList[provinceCode] || '', AREA.cityList[cityCode] || '', AREA.countyList[countyCode] || '']
}

// 渲染地址中文
export const renderAddressLabel = (provinceCode, cityCode, countyCode, address = '') => {
  let str = '----'

  if (+provinceCode > 0) {
    const names = getCityName(provinceCode, cityCode, countyCode)

    str = address && address !== '' ? `${names[0]} ${names[1]} ${names[2]} ${address}` : `${names[0]} ${names[1]} ${names[2]}`
  }

  return str
}

/**
 * 根据物流公司code 获取物流公司名称
*/
export const getCourierName = (code = '') => {
  const obj = CourierJson.find(item => { return item.courierCode === code })
  const name = obj !== undefined ? obj.courierName : '----'

  return name
}

/**
 * 渲染产品类型
 * @param { status, key: 'name' || 'aliasName' }
 * @returns str
 */
export const renderSkuGoodsTypeLabel = (status, key = 'name') => {
  const obj = skuGoodsTypeMaps.find((o) => { return o.id === status })
  const label = obj !== undefined ? obj[key] : null

  return label
}

// 渲染产品上架状态
export const renderProductPutOnStatusLabel = (status) => {
  const obj = productPutOnMaps.find((o) => { return o.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

// 渲染代理证状态
export const renderBrandAgencyStatusLabel = (status) => {
  const obj = brandAgencyStatusMaps.find((o) => { return o.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

// 渲染云供应订单状态
export const renderOrderStatusLabel = (status) => {
  const obj = cloudSupplyOrderStatusMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

// 渲染订单付款状态
export const renderOrderPaymentStatusLabel = (status) => {
  const obj = orderPaymentStatusMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

// 渲染订单发货状态
export const renderOrderDeliveryStatusLabel = (status) => {
  const obj = orderDeliveryStatusMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

// 渲染拣货单发货状态
export const renderPickingOrderSendStatusLabel = (status) => {
  const obj = pickingOrderSendStatusMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

// 渲染订单订货状态
export const renderOrderArriverStatusLabel = (status) => {
  const obj = orderArriverStatusMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

// 渲染订单类型
export const renderOrderTypeLabel = (status) => {
  const obj = orderTypeMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

/**
 * 渲染云仓等级
 */
export const renderCloudStoreLevelLabel = (status) => {
  const obj = cloudStoreLevelMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

/**
 * 渲染发票类型
 */
export const renderInvoiceTypeLabel = (status) => {
  const obj = invoiceTypeMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

/**
 * 渲染开票状态
 */
export const renderInvoiceOpenedStatusLabel = (status) => {
  const obj = invoiceOpenedStatusMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

/**
 * 渲染售后类型
 */
export const renderSaledTypeLabel = (status) => {
  const obj = saledTypeMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

/**
 * 渲染云供应订单售后状态
 */
export const renderSelfSaledStatusLabel = (status) => {
  const obj = selfSaledStatusMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

/**
 * 渲染云采购订单售后状态
 */
export const renderSaledStatusLabel = (status) => {
  const obj = saledStatusMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

/**
 * 渲染售后原因
 */
export const renderSaledReasonTypeLabel = (status) => {
  const obj = saledReasonTypeMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

/**
 * 渲染售后退款方式
 */
export const renderSaledRefundTypeLabel = (status) => {
  const obj = saledRefundTypeMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

/**
 * 渲染售后退货方式
 */
export const renderSaledBackGoodsTypeLabel = (status) => {
  const obj = saledBackGoodsTypeMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}
