import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Descriptions, Table } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import UploadThumbnail from '@/components/uploadThumbnail'

import Api from '@/common/api/index'
import { renderAddressLabel, renderSaledTypeLabel, renderSaledReasonTypeLabel, renderSaledRefundTypeLabel, renderSaledBackGoodsTypeLabel } from '@/common/utils/mUtils'
import styles from './index.module.scss'

const FormItem = Form.Item

export default class DrawerContents extends Component {
  static propTypes = {
    detailInfo: PropTypes.object
  }

  state = {
    info: {},
    tableData: []
  }

  columns = [
    {
      title: '序号',
      key: 'purchaseProductCol-0',
      width: 60,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '型号',
      key: 'purchaseProductCol-2',
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'purchaseProductCol-3',
      dataIndex: 'itemNumber'
    },
    {
      title: '产品名称',
      key: 'purchaseProductCol-4',
      dataIndex: 'name'
    },
    {
      title: '单位',
      key: 'purchaseProductCol-5',
      dataIndex: 'unit'
    },
    {
      title: '数量',
      key: 'purchaseProductCol-6',
      dataIndex: 'quantity'
    },
    {
      title: '含税单价',
      key: 'purchaseProductCol-7',
      dataIndex: 'sellingPrice',
      render: (text, record, index) => (
        <>&yen;{+record.sellingPrice}</>
      )
    },
    {
      title: '含税单品小计',
      key: 'purchaseProductCol-8',
      dataIndex: '_totalPrice',
      render: (text, record, index) => (
        <>&yen;{ ($lodash.round(+record.quantity * +record.sellingPrice, 2) * 100 / 100).toFixed(2) }</>
      )
    }
  ]

  componentDidMount () {
    this.getPurchaseOrderSaledDetails()
  }

  // 获取云采购订单售后详情
  getPurchaseOrderSaledDetails = async () => {
    const { detailInfo = {} } = this.props
    const res = await Api.getPurchaseOrderSaledDetails({ id: detailInfo.id })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        info: data || {},
        tableData: data.afterSaleOrderGoods || []
      })
    }
  }

  render () {
    const { info = {}, tableData = [] } = this.state
    const { pickUpSendAddress = {}, pickUpReceiveAddress = {}, imageUrls = [] } = info

    return (
      <>
        <Descriptions>
          <Descriptions.Item label='订单编号'>{ info.orderId }</Descriptions.Item>
          <Descriptions.Item label='订单日期'>{ info.orderTime ? moment(info.orderTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</Descriptions.Item>
        </Descriptions>

        <Table rowKey={(record, index) => `${record.commoditySkuId}-${record.skuGoodsType}-${index}`} bordered
          columns={this.columns}
          dataSource={tableData}
          pagination={false}
        />

        <div style={{ marginTop: '8px' }}>
          <div className={`${styles.formitemRow}`}>
            <FormItem label='售后类型' style={{ marginBottom: '0px' }}>{ renderSaledTypeLabel(info.type) }</FormItem>
          </div>

          <div className={`${styles.formitemRow}`}>
            <FormItem label='售后原因' style={{ marginBottom: '0px' }}>{ renderSaledReasonTypeLabel(info.reasonType) }</FormItem>
          </div>

          <div className={`${styles.formitemRow}`}>
            <FormItem label='详细描述' style={{ marginBottom: '0px' }}>
              <p style={{ marginBottom: '0px', wordBreak: 'break-all' }}>{ info.remark }</p>
            </FormItem>
          </div>

          <FormItem label='可添加图片来帮助售后人员判断(最多9张)' style={{ marginBottom: '0px' }}>
            {
              imageUrls && imageUrls.length > 0
                ? <>
                  {
                    imageUrls.map((item, index) => (
                      <div className={`${styles.pictureWrap}`} key={`purchaseSaledPictureCol-${index}`}>
                        <UploadThumbnail imageUrl={item} />
                      </div>
                    ))
                  }
                </>
                : null
            }
          </FormItem>

          {
            ['REFUND_RETURN_PRODUCTS', 'CHANGE_PRODUCTS'].includes(info.type)
              ? <>
                <div className={`${styles.formitemRow}`}>
                  <FormItem label='退货方式' style={{ marginBottom: '0px' }}>
                    { renderSaledBackGoodsTypeLabel(info.backGoodsType) }
                  </FormItem>
                </div>

                <div className={`${styles.formitemRow}`}>
                  {
                    info.backGoodsType === 'SELF'
                      ? <FormItem label='请退货至' style={{ marginBottom: '0px' }}>上海市 闵行区 江月路1388号伍信禾风广场3号楼255室 19532535667 萨斯云售后组</FormItem>
                      : <FormItem label='取件地址' style={{ marginBottom: '0px' }}>
                        {
                          pickUpSendAddress && pickUpSendAddress.province
                            ? <>{ renderAddressLabel(pickUpSendAddress.province, pickUpSendAddress.city, pickUpSendAddress.county, pickUpSendAddress.address) } { pickUpSendAddress.name } { pickUpSendAddress.mobile } </>
                            : null
                        }
                      </FormItem>
                  }
                </div>
              </>
              : null
          }

          {
            ['ADD_DELIVER'].includes(info.type)
              ? <div className={`${styles.formitemRow}`}>
                <FormItem label='补寄地址' style={{ marginBottom: '0px' }}>
                  {
                    pickUpReceiveAddress && pickUpReceiveAddress.province
                      ? <>{renderAddressLabel(pickUpReceiveAddress.province, pickUpReceiveAddress.city, pickUpReceiveAddress.county, pickUpReceiveAddress.address)} {pickUpReceiveAddress.name} { pickUpReceiveAddress.mobile }</>
                      : null
                  }
                </FormItem>
              </div>
              : null
          }

          {
            ['REFUND_RETURN_PRODUCTS', 'REFUND'].includes(info.type)
              ? <>
                <div className={`${styles.formitemRow}`}>
                  <FormItem label='退款方式' style={{ marginBottom: '0px' }}>{ renderSaledRefundTypeLabel(info.refundType) }</FormItem>
                </div>

                <div className={`${styles.formitemRow}`}>
                  <FormItem label='预估退款金额' style={{ marginBottom: '0px' }}>&yen;{ ($lodash.round(+info.backAmount, 2) * 100 / 100).toFixed(2) }</FormItem>
                </div>
              </>
              : null
          }
        </div>
      </>
    )
  }
}
