import accountApi from './accountApi'
import customerApi from './customerApi'
import supplyProductApi from './supplyProductApi'
import financeApi from './financeApi'
import warehouseApi from './warehouseApi'
import loginApi from './loginApi'
import matchsApi from './matchsApi'
import supplyOrderApi from './supplyOrderApi'
import purchaseOrderApi from './purchaseOrderApi'
import homeApi from './homeApi'
import invoiceApi from './invoiceApi'
import saledApi from './saledApi'
import addressApi from './addressApi'
import purchaseApi from './purchaseApi'
import enquireApi from './enquireApi'
import cloudLibraryApi from './cloudLibraryApi'

export default {
  ...loginApi,
  ...homeApi,
  ...accountApi,
  ...customerApi,

  ...supplyProductApi,
  ...warehouseApi,
  ...financeApi,

  ...matchsApi,
  ...supplyOrderApi,
  ...purchaseOrderApi,

  ...invoiceApi,
  ...saledApi,
  ...addressApi,
  ...purchaseApi,
  ...enquireApi,
  ...cloudLibraryApi
}
