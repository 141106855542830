import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Table } from 'antd'

import Api from '@/common/api/index'
import consts from '@/common/utils/consts'
const { SERIALWIDTH } = consts

export default class DetailModal extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func
  }

  state = {
    tableData: []
  }

  columns = [
    {
      title: '序号',
      key: 'prodDetail-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '型号',
      key: 'prodDetail-1',
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'prodDetail-2',
      dataIndex: 'itemNumber'
    },
    {
      title: '产品',
      key: 'prodDetail-3',
      width: 180,
      ellipsis: true,
      render: (text, record, index) => (
        <div className={'coloneLine'}>
          <span className={'rowBrandName'}>{record.brandName}</span>
          <span>{record.name}</span>
        </div>
      )
    },
    {
      title: '数量',
      key: 'prodDetail-4',
      dataIndex: 'quantity'
    }
  ]

  componentDidMount () {
    this.getStoreOutPickingDetail()
  }

  // 获取产品明细
  getStoreOutPickingDetail = () => {
    const { detailInfo } = this.props
    const params = {
      pickingId: detailInfo.pickingId,
      batchNum: detailInfo.batchNum,
      pageNo: 1,
      pageSize: 999
    }
    Api.getStoreOutPickingDetail(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || []
        })
      }
    })
  }

  render () {
    const { tableData } = this.state

    return (
      <>
        <Modal title='货物明细' width={1000} bodyStyle={{ padding: '15px', minHeight: '300px' }}
          visible
          maskClosable={false}
          onCancel={() => this.props.onCancel()}
          footer={false}
        >
          <Table rowKey='commoditySkuId' bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={false}
            scroll={{ y: 500 }}
          />
        </Modal>
      </>
    )
  }
}
