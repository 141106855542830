import React from 'react'
import PropTypes from 'prop-types'
import { Divider } from 'antd'

import styles from './index.module.scss'

export default function MatchStatistic (props) {
  const { totalNum = 0, successNum = 0, failNum = 0 } = props
  const successW = totalNum > 0 ? successNum / totalNum * 100 : 0
  const failW = totalNum > 0 ? failNum / totalNum * 100 : 0

  return (
    <div className={`${styles.flexbox} ${styles.matchStatistic}`}>
      <div className={`${styles.matchItem} ${styles.total}`}>
        <div className={`${styles.matchContent}`}>
          <div className={`${styles.statisticbox}`}>
            <div className={`${styles.label}`}>总共</div>
            <div className={`${styles.value}`}>{ totalNum }</div>
          </div>
          <Divider type='vertical' style={{ height: '30px' }} />
          <div className={`${styles.slider}`}>
            <div className={`${styles.rail}`} style={{ width: '100%' }}></div>
          </div>
        </div>
      </div>
      <div className={`${styles.matchItem} ${styles.success}`}>
        <div className={`${styles.matchContent}`}>
          <div className={`${styles.statisticbox}`}>
            <div className={`${styles.label}`}>匹配成功</div>
            <div className={`${styles.value}`}>{ successNum }</div>
          </div>
          <Divider type='vertical' style={{ height: '30px' }} />
          <div className={`${styles.slider} ${successW === 100 ? styles.fullRail : ''}`}>
            <div className={`${styles.rail}`} style={{ width: `${successW}%` }}></div>
          </div>
        </div>
      </div>
      <div className={`${styles.matchItem} ${styles.fail}`}>
        <div className={`${styles.matchContent}`}>
          <div className={`${styles.statisticbox}`}>
            <div className={`${styles.label}`}>需要修正</div>
            <div className={`${styles.value}`}>{ failNum }</div>
          </div>
          <Divider type='vertical' style={{ height: '30px' }} />
          <div className={`${styles.slider} ${failW === 0 ? styles.fullRail : ''}`}>
            <div className={`${styles.rail}`} style={{ width: `${failW}%` }}></div>
          </div>
        </div>
      </div>
    </div>
  )
}

MatchStatistic.propTypes = {
  totalNum: PropTypes.number,
  successNum: PropTypes.number,
  failNum: PropTypes.number
}
