import React from 'react'
import PropTypes from 'prop-types'
import { Table, Checkbox, Button } from 'antd'

import GoodsBaseItem from '../../component/goodsBaseItem/index'
import GoodsPriceItem from '../../component/goodsPriceItem/goodsPriceItem'
import GoodsQuantityItem from '../../component/goodsPriceItem/goodsQuantityItem'
import GoodsTotalItem from '../../component/goodsPriceItem/goodsTotalItem'
import GoodsErrorRow from '../../component/goodsErrorRow/index'

import { renderSkuGoodsTypeLabel } from '@/common/utils/mUtils'

export default function GoodsTable (props) {
  const { tableData = [], checkedAll = false, onSelectedAll, onSelectedGoods, onRemoveSkuItem, onChangeQuantity, onChangeCheckedPrice, onCorrect } = props

  const columns = [
    {
      title: <Checkbox disabled={tableData.length === 0} checked={checkedAll} onChange={(e) => onSelectedAll(e.target.checked)} />,
      key: 'productCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (
        <Checkbox checked={record.checked}
          disabled={record.quickOrderSearchResult !== 'SUCCESS'}
          onChange={(e) => onSelectedGoods(e.target.checked, index)}
        />
      )
    },
    {
      title: '商品信息',
      key: 'productCol-1',
      render: (text, record, index) => {
        const obj = {
          children: record.quickOrderSearchResult === 'SUCCESS'
            ? <GoodsBaseItem
              commoditySkuId={record.commoditySkuId}
              skuGoodsType={record.skuGoodsType}
              brandName={record.brandName}
              englishBrandName={record.englishBrandName}
              name={record.name}
              model={record.model}
              itemNumber={record.itemNumber}
              imageUrl={record.imageUrl}
              canLink
              detailsHasCart
            />
            : <div style={{ textAlign: 'center' }}>
              <GoodsErrorRow
                searchResult={record.quickOrderSearchResult}
                itemNumberModel={record.itemNumberModel}
                onConfirm={(skuParams) => onCorrect(skuParams, index)}
              />
            </div>,
          props: { colSpan: record.quickOrderSearchResult === 'SUCCESS' ? 1 : 5 }
        }

        return obj
      }
    },
    {
      title: '产品类型',
      key: 'productCol-2',
      dataIndex: 'skuGoodsType',
      render: (text, record, index) => {
        const obj = {
          children: <>{ renderSkuGoodsTypeLabel(record.skuGoodsType) }</>,
          props: { colSpan: record.quickOrderSearchResult === 'SUCCESS' ? 1 : 0 }
        }

        return obj
      }
    },
    {
      title: '单价',
      key: 'productCol-3',
      render: (text, record, index) => {
        const { supplyInfoList = [], commoditySkuId, skuGoodsType } = record

        const obj = {
          children: <>
            {
              supplyInfoList && supplyInfoList.length > 0
                ? <GoodsPriceItem
                  commoditySkuId={commoditySkuId}
                  skuGoodsType={skuGoodsType}
                  list={supplyInfoList}
                  onChangeChecked={(checked, n) => onChangeCheckedPrice(checked, n, index)}
                />
                : null
            }
          </>,
          props: { colSpan: record.quickOrderSearchResult === 'SUCCESS' ? 1 : 0 }
        }

        return obj
      }
    },
    {
      title: '数量',
      key: 'productCol-4',
      width: 200,
      render: (text, record, index) => {
        const { supplyInfoList = [], commoditySkuId, skuGoodsType } = record

        const obj = {
          children: <>
            {
              supplyInfoList && supplyInfoList.length > 0
                ? <GoodsQuantityItem
                  commoditySkuId={commoditySkuId}
                  skuGoodsType={skuGoodsType}
                  list={supplyInfoList}
                  onChangeInput={(value, n) => onChangeQuantity(value, n, index)}
                />
                : null
            }
          </>,
          props: { colSpan: record.quickOrderSearchResult === 'SUCCESS' ? 1 : 0 }
        }

        return obj
      }
    },
    {
      title: '单品小计',
      key: 'productCol-5',
      render: (text, record, index) => {
        const { commoditySkuId, skuGoodsType, supplyInfoList = [] } = record
        const obj = {
          children: <>
            {
              supplyInfoList && supplyInfoList.length > 0
                ? <GoodsTotalItem
                  commoditySkuId={commoditySkuId}
                  skuGoodsType={skuGoodsType}
                  list={supplyInfoList}
                />
                : null
            }
          </>,
          props: { colSpan: record.quickOrderSearchResult === 'SUCCESS' ? 1 : 0 }
        }

        return obj
      }
    },
    {
      title: '操作',
      key: 'productCol-6',
      render: (text, record, index) => (
        <>
          <Button type='primary' size='small' onClick={() => onRemoveSkuItem(index)}>删除</Button>
        </>
      )
    }
  ]

  return (
    <div style={{ marginBottom: '24px' }}>
      <Table rowKey={(record, index) => `${record.commoditySkuId}-${record.skuGoodsType}-${index}`}
        columns={columns}
        dataSource={tableData}
        pagination={false}
      />
    </div>
  )
}

GoodsTable.propTypes = {
  tableData: PropTypes.array,
  checkedAll: PropTypes.bool,
  onSelectedAll: PropTypes.func,
  onSelectedGoods: PropTypes.func,
  onRemoveSkuItem: PropTypes.func, // 删除产品
  onChangeQuantity: PropTypes.func,
  onChangeCheckedPrice: PropTypes.func,
  onCorrect: PropTypes.func // 确定纠错
}
