import React, { useEffect, useState } from 'react'
import { Card, Table } from 'antd'
import moment from 'moment'
import DetailModal from '../detailModal/index'
import { sendReportByImage } from '@/common/utils/reportFunc'
import Api from '@/common/api/index'
import { repLineBreak, getStore } from '@/common/utils/mUtils'
import consts from '@/common/utils/consts'

const { MAX_PAGESIZE } = consts

export default function EnquireList () {
  const [list, setInformationsList] = useState([])
  const [detailInfo, setDetailInfo] = useState({ detailModal: false, content: '' })
  const { detailModal = false, content } = detailInfo

  const columns = [
    {
      title: '询价内容',
      key: 'enquiryCol-1',
      dataIndex: 'enquiryContent',
      ellipsis: true,
      render: (text, record, index) => (
        <a onClick={() => handleClickContent(record)}>{ record.enquiryContent }</a>
      )
    },
    {
      title: '联系人',
      key: 'enquiryCol-2',
      width: 150,
      dataIndex: 'contactsUserName'
    },
    {
      title: '联系方式',
      key: 'enquiryCol-3',
      width: 160,
      dataIndex: 'contactsMobile'
    }
  ]

  const getPublishedEnquireInformations = async () => {
    const res = await Api.getPublishedEnquireInformations({ pageNo: 1, pageSize: MAX_PAGESIZE })
    const { code, data } = res
    if (+code === 10000) {
      setInformationsList(data.list || [])
    }
  }

  // 点击内容
  const handleClickContent = (record) => {
    setDetailInfo({ detailModal: true, content: record.enquiryContent })
    sendReportByImage({
      eventName: 'click_enquiry_content',
      triggerTime: moment().format('YYYY-MM-DD HH:mm:ss'),
      userId: getStore('token')
    })
  }

  useEffect(() => {
    getPublishedEnquireInformations()
    // 模拟 componentDidMount钩子函数
    const triggerTime = moment().format('YYYY-MM-DD HH:mm:ss')
    // 返回一个函数用于模拟 componentWillUnMount
    return () => {
      sendReportByImage({
        page: 'home',
        triggerTime,
        endTime: moment().format('YYYY-MM-DD HH:mm:ss'),
        userId: getStore('token')
      })
    }
  }, [])

  return (
    <>
      <Card title='今日询价' bodyStyle={{ padding: '15px' }}>
        <Table rowKey={(text, record) => `ENQUIRY_ITEM_${record.id}`} bordered
          columns={columns}
          dataSource={list}
          pagination={false}
        />
      </Card>

      {
        detailModal
          ? <DetailModal
            content={ content && content !== '' ? repLineBreak(content, '<br/>') : '' }
            onCancel={() => setDetailInfo({ detailModal: false, content: '' })}
          />
          : null
      }
    </>
  )
}
