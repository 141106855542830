import Ajax from '../utils/ajax'

export default {
  // 获取EU订单金额汇总
  getTradeOrderAmountSummary (params) {
    return Ajax.post('/api/order/getOrderAmountSum', params)
  },

  // 获取订单导出自定义项-产品信息项
  getOrderDownloadProductFieldOptions (params) {
    return Ajax.get('/api/excel/data/download/getProductFieldMap', { params })
  },

  // 获取订单导出自定义项-订单字段项
  getOrderDownloadOrderFieldOptions (params) {
    return Ajax.get('/api/excel/data/download/getOrderFieldMap', { params })
  },

  // 获取外部订单列表
  getOutsideOrderList (params) {
    return Ajax.post('/api/external/order/list/page', params)
  },

  // 获取外部订单产品明细
  getOutsideSkuList (params) {
    return Ajax.post('/api/external/order/list/page/sku/info', params)
  },

  // 查看订单付款凭证信息
  getCloudOrderPayVoucherDetails (params) {
    return Ajax.post('/api/cloud/supply/order/list/page/history', params)
  },

  // 云供应订单- 获取云供应订单合同附件列表
  getSupplyContractEnclosureList (params) {
    return Ajax.get('/api/cloud/supply/order/order/file/findContractByUserAndType', { params })
  },

  // 云供应订单- 保存云供应订单合同附件删除
  saveDeleteSupplyContractEnclosure (params) {
    return Ajax.get('/api/cloud/supply/order/file/delete', { params })
  }
}
