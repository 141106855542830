import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Button, Tabs, Icon } from 'antd'

import CreditDetails from './creditDetails/index'
import CreditFlowTable from './creditFlowTable/index'
import CreditBillTable from './creditBillTable/index'

const { TabPane } = Tabs

const creditTabs = [
  { key: 'FLOW', name: '信用流水' },
  { key: 'BILL', name: '信用账单' }
]

export default class PlatcreditDetail extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  state = {
    detailInfo: {},
    activeKey: 'FLOW'
  }

  componentDidMount () {
    const { state } = this.props.history.location
    if (state && state.detailInfo) {
      this.setState({
        detailInfo: state.detailInfo,
        activeKey: 'FLOW'
      })
    }
  }

  handleChangeTab = (key) => {
    this.setState({
      activeKey: key
    })
  }

  // 点击返回
  handleJumpToLink = () => {
    this.props.history.push({ pathname: '/finance/cloudCredit' })
  }

  render () {
    const { detailInfo, activeKey = 'FLOW' } = this.state

    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Button onClick={() => this.handleJumpToLink()}><Icon type="left" />返回列表</Button>
        </Row>

        <CreditDetails detailInfo={detailInfo} />

        <Card bodyStyle={{ padding: '15px' }}>
          <Tabs onChange={this.handleChangeTab}>
            {
              creditTabs.map((item) => (
                <TabPane tab={item.name} key={item.key} />
              ))
            }
          </Tabs>

          {
            activeKey === 'FLOW'
              ? <CreditFlowTable detailInfo={detailInfo} />
              : null
          }

          {
            activeKey === 'BILL'
              ? <CreditBillTable
                  detailInfo={detailInfo}
                />
              : null
          }
        </Card>
      </>
    )
  }
}
