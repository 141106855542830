import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Table, Row, message, Tabs, Button } from 'antd'
import { getLodop } from '@/assets/plugin/LodopFuncs'

import StoreTrace from './storeTrace'
import SendProductPrintHtm from '../component/sendProductPrintHtm/index' // 发货-拣货单
import OutboundRemarksModal from '../component/outboundRemarksModal/index'

import Api from '@/common/api/index'
import consts from '@/common/utils/consts'

const { TabPane } = Tabs
const { SERIALWIDTH } = consts
const tabMaps = [
  { code: 'DETAIL', label: '产品明细' },
  { code: 'TRAIL', label: '物流跟踪' }
]

export default class DetailModal extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirmOutboundRemarks: PropTypes.func // 确定保存拣货单备注
  }

  state = {
    tableData: [],
    selectedTabkey: 'DETAIL', // DETAIL-产品明细 TRAIL-物流追踪
    outboundRemarksModal: false // 拣货单备注弹窗
  }

  columns = [
    {
      title: '序号',
      key: 'orderdetail-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '型号',
      key: 'orderdetail-2',
      width: 160,
      dataIndex: 'model',
      ellipsis: true
    },
    {
      title: '订货号',
      key: 'orderdetail-3',
      width: 130,
      dataIndex: 'itemNumber',
      ellipsis: true
    },
    {
      title: '产品',
      key: 'orderdetail-1',
      width: 180,
      dataIndex: 'name',
      ellipsis: true
    },
    {
      title: '数量',
      key: 'orderdetail-4',
      dataIndex: 'quantity'
    },
    {
      title: '当前发货数量',
      key: 'orderdetail-5',
      dataIndex: 'batchQuantity'
    },
    {
      title: '单位',
      key: 'orderdetail-7',
      width: 70,
      dataIndex: 'unit'
    }
  ]

  componentDidMount () {
    this.getStoreOutPickingDetail()
  }

  // 获取订单产品明细
  getStoreOutPickingDetail = () => {
    const { detailInfo } = this.props
    const params = {
      pickingId: detailInfo.pickingId,
      batchNum: detailInfo.batchNum,
      pageNo: 1,
      pageSize: 999
    }

    Api.getStoreOutPickingDetail(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        const _list = data.list || []

        if (_list.length > 0) {
          _list.forEach(item => {
            item.sendNumber = +item.batchQuantity
          })
        }

        this.setState({
          tableData: _list
        })
      }
    })
  }

  // 保存拣货单备注信息
  saveSalesBillRemarks = async (values) => {
    const { detailInfo = {}, onConfirmOutboundRemarks } = this.props
    const res = await Api.saveSalesBillRemarks({ ...values, pickingId: detailInfo.pickingId, batchNum: detailInfo.batchNum })
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')

      this.closeModal()

      onConfirmOutboundRemarks(values.remarks)
    }
  }

  // 点击打印拣货单按钮
  handlePrintSalesBill = () => {
    const { tableData = [] } = this.state
    if (tableData.length === 0) {
      return message.warning('暂无打印信息')
    }

    this.lodopPrintSalesBill()
  }

  // 点击重新生成拣货单
  showOutboundRemarksModal = () => {
    this.setState({
      outboundRemarksModal: true
    })
  }

  // 打印拣货单
  lodopPrintSalesBill = () => {
    const htmlTemplateObj = document.getElementById('salesTable').innerHTML
    const LODOP = getLodop()
    if (!LODOP) {
      return message.error('请下载打印插件')
    }

    LODOP.PRINT_INIT('初始化打印') // 打印初始化
    LODOP.ADD_PRINT_HTM('8mm', '3mm', '195mm', '290mm', htmlTemplateObj)
    LODOP.PREVIEW()
  }

  // 点击切换tab
  handleChangeTab = (key) => {
    this.setState({
      selectedTabkey: key
    })
  }

  // 渲染弹窗底部按钮
  renderFooterInner = (key) => {
    const { onCancel } = this.props
    let arr = [
      <Button key={'cancelBtn'} onClick={() => onCancel()}>取消</Button>,
      <Button type='primary' key={'submitBtn'} onClick={() => this.handlePrintSalesBill()}>查看拣货单</Button>,
      <Button type='primary' key={'refreshBtn'} onClick={() => this.showOutboundRemarksModal()}>重新生成拣货单</Button>
    ]

    if (key === 'TRAIL') {
      arr = [
        <Button key={'cancelBtn'} onClick={() => onCancel()}>关闭</Button>
      ]
    }

    return arr
  }

  closeModal = () => {
    this.setState({
      outboundRemarksModal: false
    })
  }

  render () {
    const { detailInfo = {} } = this.props
    const { courierNumberList = [] } = detailInfo
    const { tableData = [], selectedTabkey = 'DETAIL', outboundRemarksModal = false } = this.state

    return (
      <>
        <Modal title='订单明细' width={1000} bodyStyle={{ padding: '0px 12px 10px 12px', maxHeight: '500px', overflow: 'auto' }}
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          footer={this.renderFooterInner(selectedTabkey)}
        >
          <Row>
            <Tabs onChange={this.handleChangeTab} size='small'>
              {
                tabMaps.map((item, i) => (
                  <TabPane tab={item.label} key={item.code} />
                ))
              }
            </Tabs>
          </Row>

          {
            selectedTabkey === 'DETAIL'
              ? <>
                  <Table rowKey='commoditySkuId'
                    pagination={false}
                    columns={this.columns}
                    dataSource={tableData}
                    scroll={{ y: 500 }}
                  />

                  <SendProductPrintHtm
                  orderInfo={{ ...detailInfo }}
                  orderDetail={tableData}
                />
                </>
              : <StoreTrace detailInfo={detailInfo}
                  courierNumberList={courierNumberList}
                />
          }
        </Modal>

        {
          outboundRemarksModal
            ? <OutboundRemarksModal
              outboundRemarks={detailInfo.remarks}
              onCancel={() => this.closeModal()}
              onConfirm={(values) => this.saveSalesBillRemarks(values)}
            />
            : null
        }
      </>
    )
  }
}
