
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, Table, Modal, InputNumber, Button, Icon, Tooltip } from 'antd'
import $lodash from 'lodash'

import ProductModal from '@/pages/enquireManage/prepareQuotedDetails/productModal'

import { setEditEnquireDetails } from '@/reducers/action'
import filter from '@/common/utils/filter'
import consts from '@/common/utils/consts'
import styles from './index.module.scss'

const { confirm } = Modal
const { enquireMatchStatusMaps } = filter
const { SERIALWIDTH, MAX_NUMBER } = consts

class AutoEditableTable extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    editEnquireDetails: PropTypes.object,
    onShowDeliveryModal: PropTypes.func, // 监听设置货期惯着弹窗
    onCorrectModal: PropTypes.func // 监听纠错按钮
  }

  state = {
    selectedRowKeys: [], // 选中的产品数据
    deliveryModal: false, // 设置货期弹窗
    productModal: false,
    activeIndex: -1,
    rowDetail: {}
  }

  columns = [
    {
      title: '序号',
      key: 'autoEditTableCol-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '品牌',
      key: 'autoEditTableCol-1',
      width: 120,
      ellipsis: true,
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'autoEditTableCol-2',
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'autoEditTableCol-3',
      dataIndex: 'itemNumber'
    },
    {
      title: '产品名称',
      key: 'manualEditTableCol-4',
      width: 140,
      ellipsis: true,
      dataIndex: 'name'
    },
    {
      title: '单位',
      key: 'autoEditTableCol-5',
      width: 60,
      dataIndex: 'unit'
    },
    {
      title: '数量',
      key: 'autoEditTableCol-6',
      width: 120,
      dataIndex: 'quantity',
      render: (text, record, index) => (
        <>
          <InputNumber value={record.quantity} min={0} max={MAX_NUMBER}
            onChange={(value) => this.handleChangeInput(value, index, 'quantity')}
          />
        </>
      )
    },
    {
      title: '期望价格',
      key: 'autoEditTableCol-7',
      width: 130,
      dataIndex: 'expectPrice',
      filterDropdown: (<div />),
      filterIcon: <Tooltip title='此处价格为含税且含运价格'><Icon type='question-circle' style={{ color: '#1890FF' }} /></Tooltip>,
      render: (text, record, index) => (
        <>
          <span style={{ marginRight: '4px' }}>&yen;</span>
          <InputNumber value={record.expectPrice} min={0} max={MAX_NUMBER}
            onChange={(value) => this.handleChangeInput(value, index, 'expectPrice')} />
        </>
      )
    },
    {
      title: '期望折扣',
      key: 'autoEditTableCol-8',
      width: 130,
      dataIndex: 'expectDiscount',
      render: (text, record, index) => (
        <>
          <InputNumber value={record.expectDiscount} min={0} max={100}
            onChange={(value) => this.handleChangeInput(value, index, 'expectDiscount')} />
          <span style={{ marginLeft: '4px' }}>%</span>
        </>
      )
    },
    {
      title: '期望货期',
      key: 'autoEditTableCol-9',
      width: 160,
      dataIndex: 'expectDeliveryPeriodDays',
      filterDropdown: (<div />),
      filterIcon: <Tooltip title='此处货期若填“0”或不填写则是现货'><Icon type='question-circle' style={{ color: '#1890FF' }} /></Tooltip>,
      render: (text, record, index) => (
        <>
          <InputNumber value={record.expectDeliveryPeriodDays} min={0} max={MAX_NUMBER}
            onChange={(value) => this.handleChangeInput(value, index, 'expectDeliveryPeriodDays')} />
          <span style={{ marginLeft: '4px' }}>工作日</span>
        </>
      )
    },
    {
      title: '数据类型',
      key: 'autoEditTableCol-10',
      width: 100,
      render: (text, record, index) => (
        <>{ this.renderMatchingLabel(record.matchingState) }</>
      )
    },
    {
      title: '操作',
      key: 'autoEditTableCol-11',
      width: 120,
      render: (text, record, index) => {
        return (
          <>
            {
              record.searchResultEnum === 'ERROR'
                ? <Button type='link' size='small' onClick={() => this.props.onCorrectModal(record)}>纠错</Button>
                : <Button type='link' size='small' onClick={() => this.showProductModal(record, index)}>更换产品</Button>
            }

            <Button type='link' size='small' onClick={() => this.handleDelete([record.rowKey])}>删除</Button>
          </>
        )
      }
    }
  ]

  componentDidMount () {}

  // 点击删除\批量删除
  handleDelete = (keys) => {
    confirm({
      title: '确定删除？',
      content: '',
      onOk: () => {
        this.deleteAutoEnquireProducts(keys)
      }
    })
  }

  // 删除自动询价单数据
  deleteAutoEnquireProducts = (keys) => {
    const { selectedRowKeys = [] } = this.state
    const { editEnquireDetails } = this.props
    const { autoEnquireProducts = [] } = editEnquireDetails

    const deleteKeys = $lodash.map(keys, (o) => { return { rowKey: o } })
    const newdatas = $lodash.pullAllBy(autoEnquireProducts, deleteKeys, 'rowKey')

    this.setState({
      selectedRowKeys: selectedRowKeys.length > 0 ? $lodash.pullAll(selectedRowKeys, keys) : []
    })

    this.props.dispatch(setEditEnquireDetails({ editEnquireDetails: { ...editEnquireDetails, autoEnquireProducts: [...newdatas] } }))
  }

  // 点击选择
  handleSelectedChange = (keys) => {
    this.setState({
      selectedRowKeys: keys
    })
  }

  // 监听输入框输入
  handleChangeInput = (value, index, dataIndex) => {
    const { editEnquireDetails = {} } = this.props
    const { autoEnquireProducts = [] } = editEnquireDetails

    autoEnquireProducts[index][dataIndex] = value

    this.props.dispatch(setEditEnquireDetails({
      editEnquireDetails: {
        ...editEnquireDetails,
        autoEnquireProducts: [...autoEnquireProducts]
      }
    }))
  }

  // 点击更换产品
  showProductModal = (record = {}, index) => {
    this.setState({
      rowDetail: record,
      activeIndex: index,
      productModal: true
    })
  }

  // 确定更换产品
  handleConfirmProduct = (params) => {
    const { dispatch, editEnquireDetails = {} } = this.props
    const { activeIndex } = this.state
    const { autoEnquireProducts = [] } = editEnquireDetails

    autoEnquireProducts[activeIndex] = { ...params, rowKey: `SEARCH-${params.commoditySkuId}` }

    dispatch(setEditEnquireDetails({ editEnquireDetails: { ...editEnquireDetails, autoEnquireProducts: [...autoEnquireProducts] } }))

    this.closeModal()
  }

  // 渲染数据类型名称
  renderMatchingLabel = (status) => {
    const obj = enquireMatchStatusMaps.find((o) => { return o.id === status })
    const label = obj !== undefined ? obj.name : ''

    return label
  }

  closeModal = () => {
    this.setState({
      rowDetail: {},
      activeIndex: -1,
      productModal: false
    })
  }

  render () {
    const { editEnquireDetails = {}, onShowDeliveryModal } = this.props
    const { autoEnquireProducts = [] } = editEnquireDetails
    const { selectedRowKeys = [], productModal = false, rowDetail = {} } = this.state

    const rowSelection = {
      columnWidth: 44,
      selectedRowKeys: selectedRowKeys,
      onChange: this.handleSelectedChange
    }

    return (
      <>
        <div style={{ marginBottom: '24px' }}>
        <Card title='搜索结果区域' extra={<span style={{ color: '#FF4B4A' }}>温馨提示：与IIASaaS数据库不匹配的数据会显示红色，不匹配也可以提交。</span>}
          headStyle={{ padding: '0px', borderBottom: 'none' }}
          bodyStyle={{ padding: '0px' }} bordered={false}
        >
          <div style={{ marginBottom: '16px' }}>
            <Button type='primary' disabled={selectedRowKeys.length === 0} onClick={() => this.handleDelete(selectedRowKeys)}>批量删除选中</Button>
            <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => onShowDeliveryModal()}>设置货期规则</Button>
          </div>

          <Table rowKey='rowKey' bordered
            dataSource={autoEnquireProducts}
            columns={this.columns}
            rowSelection={rowSelection}
            rowClassName={(record, index) => { return record.searchResultEnum === 'ERROR' ? styles.errorColor : null }}
            pagination={false}
          />
        </Card>
      </div>

      { // 更换产品弹窗
        productModal
          ? <ProductModal
              detailInfo={rowDetail}
              onConfirm={(params) => this.handleConfirmProduct(params)}
              onCancel={() => this.closeModal()}
            />
          : null
      }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    editEnquireDetails: state.editEnquireDetails
  }
}

export default connect(mapStateToProps)(AutoEditableTable)
