import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Button, Table, message, Icon } from 'antd'
import $lodash from 'lodash'

import SearchForm from '@/components/tool-form/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import { repLineBreak } from '@/common/utils/mUtils'
import consts from '@/common/utils/consts'

const { success } = Modal
const { SERIALWIDTH } = consts
const { pageSizeOptions } = filter

export default class addmodal extends Component {
  static propTypes = {
    skuType: PropTypes.string,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }

  state={
    searchData: [
      {
        name: 'brandId',
        formType: 'Select',
        itemParams: {
          label: '品牌'
        },
        cptParams: {
          placeholder: '请选择品牌',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['brandId', 'showLabel']
      },
      {
        name: 'keyword',
        formType: 'Input',
        itemParams: {
          label: '型号/订货号'
        },
        cptParams: {
          placeholder: '请输入型号或订货号',
          autoComplete: 'off'
        }
      },
      {
        name: 'itemNumberOrModelList',
        formType: 'TextArea',
        itemParams: {
          label: '型号/订货号'
        },
        cptParams: {
          placeholder: '请输入型号或订货号',
          rows: 4,
          autoComplete: 'off'
        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: [],
    selectedRowKeys: []
  }

  columns = [
    {
      title: '序号',
      key: 'supplyAddProduct-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'supplyAddProduct-1',
      width: 120,
      dataIndex: 'brandName',
      ellipsis: true
    },
    {
      title: '型号',
      key: 'supplyAddProduct-2',
      dataIndex: 'model',
      ellipsis: true,
      render: (text, record, index) => (
        <span dangerouslySetInnerHTML={{ __html: record.model }} />
      )
    },
    {
      title: '订货号',
      key: 'supplyAddProduct-3',
      dataIndex: 'itemNumber',
      ellipsis: true,
      render: (text, record, index) => (
        <span dangerouslySetInnerHTML={{ __html: record.itemNumber }} />
      )
    },
    {
      title: '产品名称',
      key: 'supplyAddProduct-4',
      dataIndex: 'name',
      ellipsis: true
    },
    {
      title: '操作',
      key: 'supplyAddProduct-5',
      render: (text, record, index) => (
        <>
          {
            record.addStatus
              ? <Button type='link' disabled>已添加</Button>
              : <Button type='link' size='small' onClick={() => this.handleAdd([record.commoditySkuId])}>添加产品</Button>
          }
        </>
      )
    }
  ]

  componentDidMount () {
    this.getBrandList()
    this.getAllowAddToSupplyProductList()
  }

  // 获取检索品牌
  getBrandList = async () => {
    const res = await Api.getBrandList({ brandListType: 'ALL_BRAND' })
    const { code, data = [] } = res
    if (+code === 10000) {
      const { searchData } = this.state

      if (data && data.length > 0) {
        data.forEach(item => {
          item.showLabel = item.englishBrandName && item.englishBrandName !== '' ? `${item.brandName}/${item.englishBrandName}` : item.brandName
        })

        searchData[0].options = $lodash.concat([{ brandId: 0, showLabel: '全部' }], data)
      }

      this.setState({
        searchData: searchData
      })
    } else {
      message.warning(res.message)
    }
  }

  // 获取可添加的产品列表
  getAllowAddToSupplyProductList = async () => {
    const { skuType } = this.props
    const { query = {}, pages } = this.state
    const res = await Api.getAllowAddToSupplyProductList({ ...query, skuType: skuType, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res

    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    } else {
      message.warning(res.message)
    }
  }

  // 保存添加产品到云供应
  saveAddProductToCloudSupply = async (params) => {
    const res = await Api.saveAddProductToCloudSupply(params)
    const { code, data = {} } = res
    if (+code === 10000) {
      success({
        content: <>
          <h4>添加成功</h4>
          <p>已添加{data.successNum}个型号</p>
          { +data.failNum > 0 ? <p>{+data.failNum}个型号已存在，请勿重复添加</p> : null }
        </>,
        onOk: () => {
          this.props.onConfirm()
          this.getAllowAddToSupplyProductList()
        }
      })
    } else {
      message.warning(res.message)
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    const { itemNumberOrModelList = '' } = search

    if (itemNumberOrModelList !== '') {
      search.itemNumberOrModelList = repLineBreak(itemNumberOrModelList, '<br>').split('<br>')
    } else {
      search.itemNumberOrModelList = []
    }

    this.setState({
      query: {
        ...search,
        brandIdList: +search.brandId > 0 ? [search.brandId] : null
      },
      pages: {
        ...pages,
        pageNo: 1
      }
    }, () => {
      this.getAllowAddToSupplyProductList()
    })
  }

  // 选择产品
  onSelectChange = (keys, rows) => {
    this.setState({
      selectedRowKeys: keys
    })
  }

  // 点击添加产品
  handleAdd = (skuIdList = []) => {
    if (skuIdList.length === 0) { return message.warning('请选择要添加的产品') }
    const { skuType } = this.props

    this.saveAddProductToCloudSupply({ commoditySkuIdList: skuIdList, skuType: skuType })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getAllowAddToSupplyProductList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getAllowAddToSupplyProductList()
    })
  }

  render () {
    const { onCancel } = this.props
    const { searchData, tableData = [], pages, selectedRowKeys = [] } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    const rowSelection = {
      columnWidth: 40,
      selectedRowKeys,
      onChange: this.onSelectChange
    }

    return (
      <Modal title="添加产品" bodyStyle={{ padding: '16px 16px 24px 16px', maxHeight: '560px', overflowY: 'scroll' }}
        width={1000}
        visible
        maskClosable={false}
        onCancel={() => onCancel()}
        footer={null}
      >
        <SearchForm {...this.props}
          dataSource={searchData}
          handleSubmit={(obj) => this.handleSearch(obj)}
        />

        <Row style={{ margin: '16px 0px' }}>
          <Button type='primary' disabled={selectedRowKeys.length === 0} onClick={() => this.handleAdd(selectedRowKeys)}>
            <Icon type="select" />批量添加
          </Button>
        </Row>

        <Table rowKey='commoditySkuId'
          columns={this.columns}
          dataSource={tableData}
          pagination={pagination}
          rowSelection={rowSelection}
        />
      </Modal>
    )
  }
}
