import React from 'react'
import { Row } from 'antd'

import TradedTable from './tradedTable/index'
import EnquireList from './enquireList/index'

export default function Home () {
  return (
    <>
      <Row style={{ marginBottom: '16px' }}>
        <TradedTable />
      </Row>

      <EnquireList />
    </>
  )
}
