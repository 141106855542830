import Ajax from '../utils/ajax'

export default {
  // 云采购订单列表
  getCloudPurchaseOrderList (params) {
    return Ajax.post('/api/cloud/procurementOrder/pageQuery', params)
  },

  // 获取云采购订单详情
  getCloudPurchOrderDetail (params) {
    return Ajax.post('/api/cloud/procurementOrder/detailQuery', params)
  },

  // 查询是否有合同生成
  getHasCloudPurchContract (params) {
    return Ajax.get('/api/merchant/contract', { params })
  },

  // 自定义合同
  submitCustractNumber (params) {
    return Ajax.post('/api/merchant/contract/create', params)
  },

  // 拣货单信息
  getlistPickingOrderBatchInfo (params) {
    return Ajax.post('/api/just/purchase/cloud/order/manage/listPickingOrderBatchInfo', params)
  },

  // 确认收货
  savePurchaseOrderConfirmTakeDelivery (params) {
    return Ajax.post('/api/just/purchase/cloud/order/manage/confirm/complete ', params)
  },

  // 云采购订单- 获取云采购订单附件列表
  getPurchaseContractEnclosureList (params) {
    return Ajax.get('/api/cloud/procurementOrder/order/file/findContractByUserAndType', { params })
  },

  // 云采购订单- 云采购订单附件删除
  saveDeletePurchaseContractEnclosure (params) {
    return Ajax.get('/api/cloud/procurementOrder/file/delete', { params })
  }
}
